import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    VStack,
    Text,
    Icon, Flex, FormControl, Divider
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons"; // Import the CheckCircleIcon
import React, {useRef, useState} from "react";
import {formatCurrency} from "../../../../lib/utilities";
import {DirectPaymentDto} from "../../../../generated-client/model/direct-payment-dto";
import {CreateDirectPaymentRequest} from "../../../../generated-client/model/create-direct-payment-request";

const flexPayBankDetails = [
    'Account Name: MAMLAKA HUB & SPOKE LIMITED',
    'Bank account number: 55010160018203',
    "Bank/Branch: UBA Kenya Bank Limited, Westland’s Branch",
    'Swift code: UNAFKENA'];

const mPesaDetails = [
    'Paybill Number: 559900',
    'Account number: 55010160018203'
];

interface SuccessModalProps {
    isOpen: boolean;
    amount: number;
    currency: string;
    paymentMethod: DirectPaymentDto.PaymentMethodEnum;
    onClose: () => void;
}

const PaymentDetailsModal: React.FC<SuccessModalProps> = ({ paymentMethod, onClose, amount, currency, isOpen }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader></ModalHeader>
                <ModalBody>
                    <VStack spacing={4} align="center">
                        <Text textAlign="center">
                            {'Account details'}<br />
                        </Text>
                        <VStack align="start" spacing={4}>
                            <Text>Amount to pay now: <b>{currency} {formatCurrency(amount)}</b></Text>
                            <Text fontWeight="bold">M-Pesa</Text>
                            <Text>
                            {mPesaDetails.map((detail, index) => (
                                <>{detail}<br/></>
                            ))}
                            </Text>
                            <Divider mb={2} />
                            <Text fontWeight="bold">Bank transfer</Text>
                            <Text>
                                {flexPayBankDetails.map((detail, index) => (
                                    <>{detail}<br/></>
                                ))}
                            </Text>
                            <Text pt='0px' fontSize='xs'>We process our payments through our regulated partner Mamlaka Hub & Spoke Limited, a Payment Services Provider authorized by the Central Bank of Kenya.</Text>
                        </VStack>
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <Flex width="100%" justifyContent="center"> {/* Use Flex to center the button */}
                        <Button colorScheme="blue" onClick={onClose}>
                            Close
                        </Button>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default PaymentDetailsModal;
