import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Icon,
  Button,
  Text,
} from "@chakra-ui/react";
import { WarningIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";

interface ConfirmReOrderModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleConfirmReOrder: () => void;
}

const ConfirmReOrderModal = ({
  isOpen,
  onClose,
  handleConfirmReOrder,
}: ConfirmReOrderModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Icon as={WarningIcon} color="yellow.400" mr={2} />
          {t("price_change_title")}
        </ModalHeader>
        <ModalBody>
          <Text>{t("price_change_text")}</Text>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" ml={3} onClick={handleConfirmReOrder}>
            Continue
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmReOrderModal;
