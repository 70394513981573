// Chakra imports
import { Flex, useColorModeValue, Image, Text } from '@chakra-ui/react';

// Custom components
import { HSeparator } from 'components/separator/Separator';

// React Router
import { useLocation } from 'react-router-dom';

import logoImage from 'assets/img/dashboards/logo-nobg-dark.png';

export function SidebarBrand() {
	// Chakra color mode
	let logoColor = useColorModeValue('navy.700', 'white');

	// Get current location
	const location = useLocation();

	// Check if the URL path contains "admin"
	const isAdminPath = location.pathname.includes('admin');

	return (
		<Flex alignItems='center' flexDirection='column'>
			<Image src={logoImage} h='40px' w='152px' my='32px' mb={isAdminPath ? '5px' : '32px' } alt="Logo" />
			{isAdminPath && <Text color={logoColor} fontWeight="bold">Admin</Text>}
			<HSeparator mb='20px' />
		</Flex>
	);
}

export default SidebarBrand;
