import {
    Box,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    Input,
    Icon
} from "@chakra-ui/react";
import Card from "../../../../components/card/Card";
import React, { useState } from "react";
import { ExchangeRateDto } from "../../../../generated-client/model/exchange-rate-dto";
import { CheckIcon } from "@chakra-ui/icons";  // Make sure you have this imported

type EditedRates = {
    [rateId: string]: number;
};

interface TableComponentProps {
    rates: ExchangeRateDto[];
    handleRateChange: (rateId: string, newRate: number) => void;
}

export default function TableComponent({ rates, handleRateChange }: TableComponentProps) {
    const [editedRates, setEditedRates] = useState<EditedRates>({});
    const [savedRateIds, setSavedRateIds] = useState<string[]>([]);

    const handleInputChange = (rateId: string, value: number) => {
        setEditedRates(prevState => ({ ...prevState, [rateId]: value }));
        handleRateChange(rateId, value);  // Save rate on blur
        setSavedRateIds(prevIds => [...prevIds, rateId]);  // Add rateId to savedRateIds to show checkmark
        setTimeout(() => {  // Remove rateId from savedRateIds after 1 second
            setSavedRateIds(prevIds => prevIds.filter(id => id !== rateId));
        }, 3000);
    };

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Card w={{ base: "100%", md: "100%" }} mb={{ base: "0px", xl: "20px" }} minH="365px" pe="20px">
                <Table variant="simple" size="md" fontSize="0.9em">
                    <Thead>
                        <Tr>
                            <Th>From</Th>
                            <Th>To</Th>
                            <Th maxW="70px">Value</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {rates?.map(rate => (
                            <Tr key={rate.id}>
                                <Td>
                                    <Text>{rate.from}</Text>
                                </Td>
                                <Td>
                                    <Text>{rate.to}</Text>
                                </Td>
                                <Td maxW="70px">
                                    {savedRateIds.includes(rate.id) && (
                                        <Icon as={CheckIcon} color="green.500" pl="5px"/>  // Display green checkmark if rate was saved
                                    )}
                                    <Input
                                        maxW={"80%"}
                                        defaultValue={rate.value}
                                        onBlur={e => {
                                            const newValue = e.target.value ? parseFloat(e.target.value) : rate.value;
                                            if (newValue !== rate.value) { // Only save and show checkmark if the value has changed
                                                handleInputChange(rate.id, newValue);
                                            }
                                        }}
                                        type="number"
                                    />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Card>
        </Box>
    );
}
