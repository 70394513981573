import {callGet, callUpdate} from "../../lib/api-private";
import {GetUserResponse} from "../../generated-client/model/get-user-response";
import {GetCompanyResponse} from "../../generated-client/model/get-company-response";


interface CacheEntry {
  timestamp: number | null;
  data: Promise<GetUserResponse> | null;
}

const cache: {
  user: CacheEntry;
} = {
  user: {
    timestamp: null,
    data: null
  }
};

export const getUser = async (accessToken: string) : Promise<GetUserResponse> => {
  const currentTime = Date.now();

  // Check if a valid cache entry exists and if it's within 3 seconds
  if (cache.user.timestamp !== null && (currentTime - cache.user.timestamp < 5000) && cache.user.data !== null) {
    return await cache.user.data;  // Await and return the promise from cache
  }

  // If no valid cache or cache is expired, create a new promise to fetch data
  const dataPromise = callGet(accessToken, "/user");
  cache.user   = {
    timestamp: currentTime,
    data: dataPromise
  };

  // Return the resolved data
  return await dataPromise;
};

export const updateUser = async (accessToken: string, email: string, phoneNumber: string) : Promise<void> => {
  await callUpdate(accessToken, "/user", {
    email,
    phoneNumber,
  });
};
