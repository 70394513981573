import React from "react";
import {
  Box,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";

type Data = {
  title: string;
  value: number | string;
};

interface InformationProps {
  textValueColorPrimary?: string;
  data: Data | Data[];
  [x: string]: any;
}

export default function Information(props: InformationProps) {
  const { textValueColorPrimary, data, ...rest } = props;

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const bg = useColorModeValue("white", "navy.700");

  // Helper function to render the content for each card item
  const renderContent = (item: Data, isLastItem: boolean) => (
    <Box
      key={item.title}
      style={{
        marginLeft: isLastItem && Array.isArray(data) ? "auto" : undefined,
      }}
    >
      <Text fontWeight="500" color={textColorSecondary} fontSize="sm">
        {item.title}
      </Text>
      <Text
        color={textValueColorPrimary || textColorPrimary}
        fontWeight="500"
        fontSize="md"
      >
        {item.value}
      </Text>
    </Box>
  );

  const isArray = Array.isArray(data);
  const isTableLayout = useBreakpointValue({ base: false, md: true });

  // Prepare content based on whether data is an array or a single object
  const content = isArray
    ? data.map((item, index) => renderContent(item, index === data.length - 1))
    : renderContent(data, true);

  // Render the card with the content
  return (
    <Card
      bg={bg}
      {...rest}
      style={{ flexDirection: "row" }}
      width={isArray && isTableLayout ? "xs" : "auto"}
    >
      {content}
    </Card>
  );
}
