import { Box, Button, Text } from "@chakra-ui/react";
import { SourcingRequestDto } from "generated-client/model/sourcing-request-dto";
import { SourcingRequestProFormaInvoiceDto } from "generated-client/model/sourcing-request-pro-forma-invoice-dto";

interface CardLayoutProps {
  sourcingRequests: SourcingRequestDto[];
  handleOpenProformaInvoices: (
    proformaInvoices: SourcingRequestProFormaInvoiceDto[]
  ) => void;
}

export default function CardLayout({
  handleOpenProformaInvoices,
  sourcingRequests,
}: CardLayoutProps) {
  return (
    <Box pt="12px">
      {sourcingRequests?.map((sourcingRequest, index) => (
        <Box
          key={sourcingRequest.id}
          bg="white"
          p={5}
          shadow="md"
          borderWidth="1px"
          mb={4}
        >
          <Text>Sourcing request: {index + 1}</Text>

          <Text>Status: {sourcingRequest.status}</Text>

          <Text>
            Issued date:{" "}
            {new Date(sourcingRequest.createdAt).toLocaleDateString(undefined, {
              year: "numeric",
              month: "long",
            })}
          </Text>
          {sourcingRequest?.proFormaInvoices?.length > 0 && (
            <Button
              colorScheme="blue"
              size="sm"
              mt={4}
              onClick={() =>
                handleOpenProformaInvoices(sourcingRequest.proFormaInvoices)
              }
            >
              View invoices
            </Button>
          )}
        </Box>
      ))}
    </Box>
  );
}
