import React from "react";

import {
  Box,
  Button,
  Divider,
  Flex, IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { SupplierDto } from "generated-client/model/supplier-dto";
import { FaWhatsapp } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { CloseIcon } from "@chakra-ui/icons";

interface SupplierDetailModalProps {
  isOpen: boolean;
  supplier: SupplierDto;
  onClose: () => void;
  handleWhatsAppClick: () => void;
  textColorPrimary: string;
}

const SupplierDetailModal: React.FC<SupplierDetailModalProps> = ({
  isOpen,
  onClose,
  supplier,
  handleWhatsAppClick,
  textColorPrimary,
}) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
      <ModalOverlay />
      <ModalContent overflowY="auto" maxH="80vh">
        <ModalHeader>
          <Flex align="center">
            <Text>{supplier?.friendlyName}</Text>
            <IconButton
                aria-label="Close"
                icon={<CloseIcon />}
                size="sm"
                ml="auto"
                onClick={onClose}
                color="gray.500"
                variant="ghost" // Optional: Makes the button have a transparent background
            />
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Text mb={2}>Address: {supplier?.address}</Text>
          <Text mb={2}>
            Operating Since:{" "}
            {supplier?.companyRegistrationDate
              ? new Date(supplier?.companyRegistrationDate).toLocaleDateString(
                  undefined,
                  {
                    year: "numeric",
                    month: "long",
                  }
                )
              : ""}
          </Text>

          {supplier && supplier?.whatsappContact && (
            <Button
              leftIcon={
                <Box as="span" p="1" bg="green.500" borderRadius="full">
                  <FaWhatsapp color="white" style={{ fontSize: "80%" }} />
                </Box>
              }
              ml="-10px"
              maxW="200px"
              backgroundColor="#E7F2FF"
              color={textColorPrimary}
              onClick={handleWhatsAppClick}
              _hover={{ bg: "#E7F2FF" }}
              style={{ transform: "scale(0.9)" }}
            >
              {t("contact_supplier")}
            </Button>
          )}

          <Divider my={4} />

          {supplier?.products?.length ? (
            <>
              <Text fontWeight={"bold"} fontSize={16}>
                Product List
              </Text>
              {supplier?.products
                ?.filter((product) => product.stockAvailability === "IN_STOCK")
                .map((product) => {
                  return <Text key={product.id}>{product.name}</Text>;
                })}
            </>
          ) : null}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SupplierDetailModal;
