import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { ChevronRightIcon, ChevronLeftIcon } from "@chakra-ui/icons";
import { useRef, useState, useEffect } from "react";
import { ProductDto } from "generated-client/model/product-dto";

interface FeaturedProductsProps {
  featuredProducts: ProductDto[];
  handleOrderProduct: (product: ProductDto) => void;
}

const FeaturedProducts = ({
  featuredProducts,
  handleOrderProduct,
}: FeaturedProductsProps) => {
  const scrollableRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  // Handle scrolling to the right
  const scrollRight = () => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollBy({ left: 300, behavior: "smooth" });
    }
  };

  // Handle scrolling to the left
  const scrollLeft = () => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollBy({ left: -300, behavior: "smooth" });
    }
  };

  // Check scroll position to toggle visibility of arrows
  const handleScroll = () => {
    if (scrollableRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollableRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft + clientWidth < scrollWidth);
    }
  };

  // Add event listener for scroll event
  useEffect(() => {
    const scrollableElement = scrollableRef.current;
    if (scrollableElement) {
      scrollableElement.addEventListener("scroll", handleScroll);
      handleScroll(); // Initial check

      // Cleanup event listener on unmount
      return () => {
        scrollableElement.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  return (
    <Box position="relative" mb={10}>
      {showLeftArrow && (
        <Box
          position="absolute"
          top="50%"
          left="0"
          transform="translateY(-50%)"
          backgroundColor="rgba(255, 255, 255, 0.8)"
          borderRadius="full"
          p={1}
          boxShadow="md"
          cursor="pointer"
          zIndex={2}
          onClick={scrollLeft}
        >
          <ChevronLeftIcon boxSize={6} />
        </Box>
      )}

      <Flex
        className="scrollable-flex"
        ref={scrollableRef}
        overflowX="auto"
        alignItems="center"
        position="relative"
        css={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "-ms-overflow-style": "none",
          "scrollbar-width": "none",
        }}
      >
        {featuredProducts?.map((product) => {
          const imageUrl = `https://uploaded-files-symplifi.s3.eu-west-2.amazonaws.com/${product.files[0]?.key}`;
          return (
            <Box
              width="250px"
              height="220px"
              p={4}
              m={2}
              key={product.id}
              bg="gray.100"
              borderRadius="lg"
              boxShadow="md"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              flexShrink={0}
            >
              {/* {product.files[0]?.key && ( */}
              <Image
                src={imageUrl}
                alt={product.name}
                boxSize="60px"
                borderRadius="md"
                width={"60px"}
                height={"60px"}
                mr={4}
                mb={4}
              />
              {/* )} */}

              <Box display="flex" flexDir="column" width="220px">
                <Text fontWeight="bold">{product.name}</Text>
                <Text>Supplier: {product.supplier.friendlyName}</Text>

                <Button
                  variant="solid"
                  colorScheme="gray"
                  borderColor="black"
                  borderWidth={1}
                  mt={2}
                  borderRadius={4}
                  size="sm"
                  width="80px"
                  onClick={() => handleOrderProduct(product)}
                >
                  Order
                </Button>
              </Box>
            </Box>
          );
        })}
      </Flex>

      {showRightArrow && (
        <Box
          position="absolute"
          top="50%"
          right="0"
          transform="translateY(-50%)"
          backgroundColor="rgba(255, 255, 255, 0.8)"
          borderRadius="full"
          p={1}
          boxShadow="md"
          cursor="pointer"
          zIndex={2}
          onClick={scrollRight}
        >
          <ChevronRightIcon boxSize={6} />
        </Box>
      )}
    </Box>
  );
};

export default FeaturedProducts;
