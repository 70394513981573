/**
 * Symplifi   API
 * Symplifi backend API description 
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FileDto } from './file-dto';
import { SourcingItemDto } from './sourcing-item-dto';
import { CompanyDto } from './company-dto';
import { SourcingRequestProFormaInvoiceDto } from './sourcing-request-pro-forma-invoice-dto';


export interface SourcingRequestDto { 
    id: string;
    items?: Array<SourcingItemDto>;
    status: SourcingRequestDto.StatusEnum;
    companyId: string;
    company?: CompanyDto;
    userId: string;
    files?: Array<FileDto>;
    proFormaInvoices?: Array<SourcingRequestProFormaInvoiceDto>;
    createdAt: string;
    updatedAt: string;
}
export namespace SourcingRequestDto {
    export type StatusEnum = 'PENDING' | 'SOURCING' | 'INVOICED' | 'PAID' | 'SHIPPED' | 'DECLINED';
    export const StatusEnum = {
        Pending: 'PENDING' as StatusEnum,
        Sourcing: 'SOURCING' as StatusEnum,
        Invoiced: 'INVOICED' as StatusEnum,
        Paid: 'PAID' as StatusEnum,
        Shipped: 'SHIPPED' as StatusEnum,
        Declined: 'DECLINED' as StatusEnum
    };
}


