import {
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import Card from "../../../../components/card/Card";

import { SourcingRequestDto } from "generated-client/model/sourcing-request-dto";
import { SourcingRequestProFormaInvoiceDto } from "generated-client/model/sourcing-request-pro-forma-invoice-dto";

interface TableComponentProps {
  sourcingRequests: SourcingRequestDto[];
  handleOpenProformaInvoices: (
    proformaInvoices: SourcingRequestProFormaInvoiceDto[]
  ) => void;
}

export default function TableComponent({
  sourcingRequests,
  handleOpenProformaInvoices,
}: TableComponentProps) {
  return (
    <Table variant="simple" size="md" fontSize="0.9em">
      <Thead>
        <Tr>
          <Th width="150px">Sourcing request</Th>
          <Th>Status</Th>
          <Th>Issued date</Th>
          <Th>Pro forma invoices</Th>
        </Tr>
      </Thead>
      <Tbody>
        {sourcingRequests?.map((sourcingRequest, index) => (
          <Tr key={sourcingRequest.id}>
            <Td width="150px">
              <Text>{index + 1}</Text>
            </Td>
            <Td>
              <Text>{sourcingRequest.status}</Text>
            </Td>
            <Td>
              <Text>
                {new Date(sourcingRequest.createdAt).toLocaleDateString(
                  undefined,
                  {
                    year: "numeric",
                    month: "long",
                  }
                )}
              </Text>
            </Td>
            <Td>
              {sourcingRequest?.proFormaInvoices?.length > 0 && (
                <Button
                  colorScheme="blue"
                  size="sm"
                  onClick={() =>
                    handleOpenProformaInvoices(sourcingRequest.proFormaInvoices)
                  }
                >
                  View invoices
                </Button>
              )}
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}
