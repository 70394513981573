import {Box, Button, Table, Tbody, Td, Text, Th, Thead, Tr, Link, FormControl, Icon} from "@chakra-ui/react";
import Card from "../../../../components/card/Card";
import { formatCurrency } from "../../../../lib/utilities";
import React, {useRef, useState} from "react";
import {DirectPaymentDto} from "../../../../generated-client/model/direct-payment-dto";
import { downloadFile } from "services/file";
import {UploadFileRequest} from "../../../../generated-client/model/upload-file-request";
import TypeEnum = UploadFileRequest.TypeEnum;
import PaymentDetailsModal from "./PaymentDetails";
import { ChevronDownIcon } from "@chakra-ui/icons";
import {MdExpand, MdPayments} from "react-icons/md";
import {getPaymentRequest, getPaymentRequests} from "../../../../services/payment-request";
import FlexPayPaymentDetailsModal from "./FlexPayPaymentDetails";

interface TableComponentProps {
    directPayments: DirectPaymentDto[];
    accessToken: string;
    sendFile: (file: File, directPaymentId: string) => void;
    fetchData: () => void;
}

export default function TableComponent({ directPayments, accessToken, sendFile, fetchData }: TableComponentProps) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [loadingStates, setLoadingStates] = useState<{ [key: string]: boolean }>({});
    const [currentDirectPaymentId, setCurrentDirectPaymentId] = useState<string | null>(null);  // New state to keep track of the current directPaymentId
    const [isModalOpen, setIsModalOpen] = useState(false);  // New state to manage modal visibility
    const [modalPayment, setModalPayment] = useState(null);  // New state to manage the payment details for the modal
    const [modalPaymentRequest, setModalPaymentRequest] = useState(null);  // New state to manage the payment details for the modal
    const [isFlexPayModalOpen, setIsFlexPayModalOpen] = useState(false);

    const toggleFlexPayModal = async (payment?: DirectPaymentDto) => {
        if(payment?.paymentRequestId) {
            const paymentRequest = await getPaymentRequest(accessToken, payment.paymentRequestId);
            setModalPaymentRequest(paymentRequest.paymentRequest);
        }
        setIsFlexPayModalOpen(prevState => !prevState);
    }

    const toggleModal = (payment: DirectPaymentDto) => {
        setModalPayment(payment);
        setIsModalOpen(prevState => !prevState);
    }
    let fileInputRef = useRef(null);

    const setRowLoading = (id: string, isLoading: boolean) => {
        setLoadingStates(prev => ({ ...prev, [id]: isLoading }));
    };

    const handleFileUpload = async (file: File, directPaymentId: string) => {
        setRowLoading(directPaymentId, true);  // Set loading state
        await sendFile(file, directPaymentId);  // Assume sendFile is defined somewhere
        setRowLoading(directPaymentId, false);  // Clear loading state
        window.location.reload();
    };

    const handleFileChange = (event: any) => {
        if (!currentDirectPaymentId) return;  // Exit early if no current directPaymentId

        const file = event.target.files[0];
        handleFileUpload(file, currentDirectPaymentId);
    }

    const triggerFileInput = (directPaymentId: string) => {
        setCurrentDirectPaymentId(directPaymentId);  // Set the current directPaymentId when the button is clicked
        fileInputRef.current.click();
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Card
                w={{ base: "100%", md: "100%" }}
                mb={{ base: "0px", xl: "20px" }}
                minH="365px"
                pe="20px"
            >
                <Table variant="simple" size="md" fontSize="0.9em">
                    <Thead>
                        <Tr style={{ borderBottom: "2px solid #E2E8F0" }}>
                            <Th>Recipient</Th>
                            <Th>Payment Details</Th>
                            <Th>Status</Th>
                            <Th>Files</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {directPayments?.map((payment) => (
                            <Tr key={payment.id} style={{ borderBottom: "2px solid #E2E8F0" }}>
                                <Td minW="200px">
                                    <Text>Recipient: {payment.recipientName}</Text>
                                    <Text>Address: {payment.recipientAddress}</Text>
                                    <Text>Phone Number: {payment.recipientPhoneNumber}</Text>
                                    <Text>Account Number: {payment.recipientAccountNumber}</Text>
                                    <Text>Bank Swift code: {payment.recipientSwiftCode}</Text>
                                    <Text>Country: {payment.country}</Text>
                                </Td>
                                <Td minW="200px">
                                    <Text>Amount: {'$ ' + formatCurrency(payment.amount)}</Text>
                                    <Text>Pay now: {payment.amountToPayCurrency + ' ' + formatCurrency(payment.paymentRequestAmountToPayNow || payment.amountToPay)}</Text>
                                    {payment.paymentRequestAmountToPayNow > 0 && (<Box
                                        onClick={() => toggleModal(payment)}  // New box to open the modal with payment details
                                        mr={2}  // Margin to separate the buttons
                                        cursor="pointer"  // Change cursor to pointer on hover
                                        display="flex"  // Use flex to align the text and icon
                                        alignItems="center"  // Align items to the center vertically
                                    >
                                        <Text fontSize="sm" color='grey'>
                                            Payment account details
                                        </Text>
                                    </Box>)}
                                    {/*<Text>Exchange rate: {'RWF ' + payment.exchangeRate}</Text>*/}
                                    {payment.paymentRequestId && (
                                        <>
                                            <br/>
                                            <Text>Flex pay: {payment.paymentRequestCreditAmountCurrency + ' ' + formatCurrency(payment.paymentRequestCreditAmount)}</Text>
                                            <Box
                                                onClick={() => toggleFlexPayModal(payment)}  // New box to open the modal with payment details
                                                mr={2}  // Margin to separate the buttons
                                                cursor="pointer"  // Change cursor to pointer on hover
                                                display="flex"  // Use flex to align the text and icon
                                                alignItems="center"  // Align items to the center vertically
                                            >
                                                <Text fontSize="sm" color='grey'>
                                                    Flex pay repayment details
                                                </Text>
                                            </Box>
                                        </>
                                      )}
                                </Td>
                                <Td maxW="150px">{payment.status === 'PROOF_OF_USER_PAYMENT_SUBMITTED' || payment.status === 'PROOF_OF_PLATFORM_PAYMENT_SUBMITTED' ? 'PENDING' : payment.status}</Td>
                                <Td maxW="200px">
                                    {payment.files?.map((file, index) => (
                                        <Button  fontSize="sm" color='grey' key={index}  onClick={() => downloadFile(file.key, accessToken)}>
                                            {file.type === 'PROOF_OF_PLATFORM_PAYMENT' ? 'PROOF_OF_PAYMENT' : file.type}
                                        </Button>
                                    ))}
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Card>
            {modalPayment && (
                <PaymentDetailsModal
                    isOpen={isModalOpen}
                    paymentMethod={modalPayment.paymentMethod}
                    amount={modalPayment.paymentRequestId ? modalPayment.paymentRequestAmountToPayNow : modalPayment.amountToPay}
                    currency={modalPayment.paymentRequestId ? modalPayment.paymentRequestAmountToPayNowCurrency : modalPayment.amountToPayCurrency}
                    onClose={() => toggleModal(null)}
                />
            )}
            {modalPaymentRequest && (
                <FlexPayPaymentDetailsModal
                    isOpen={isFlexPayModalOpen}
                    paymentRequest={modalPaymentRequest}
                    onClose={() => toggleFlexPayModal(null)}
                />
            )}
        </Box>
    );
}
