import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Flex,
  useToast,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { activateCompanyTrial, updateCompany } from "services/company";
import { formatCurrency } from "../../../../../lib/utilities";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  pricingPlanName: string;
  paymentRequest: { amount: number; pricingPlanId: string };
  currency: string;
  pricingPlanAmount: number;
}

export const PlanSelectConfirmationModal: React.FC<Props> = ({
  currency,
  paymentRequest,
  pricingPlanAmount,
  pricingPlanName,
  onClose,
  isOpen,
}) => {
  const [requestSent, setRequestSent] = useState(false);
  const [, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const toast = useToast();

  const handleSubscriptionUpdate = async () => {
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      setRequestSent(true);
      await Promise.all([
        updateCompany(accessToken, {
          pricingPlanId: paymentRequest.pricingPlanId,
        }),
        activateCompanyTrial(accessToken),
      ]);
      setTimeout(() => {
        onClose();
        setRequestSent(false);
        setIsLoading(false);
        handleSubscriptionUpdate();
      }, 1000);
    } catch (error: any) {
      if (error?.response?.data?.statusCode === 500) {
        toast({
          title: "Subscription Error",
          description: t("subscription_error"),
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: error?.response?.data?.message
            ? error?.response?.data?.message
            : t("error_occurred"),
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
    setIsLoading(false);
  };

  const isFreePlan = pricingPlanName.toLowerCase() === "free";

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent style={{ padding: "50px" }}>
        <ModalHeader textAlign={"center"}>✅ Confirm your plan</ModalHeader>
        <ModalBody>
          {!isFreePlan && (
            <Text size="4xl" textAlign="center">
              {`You are signing up for the ${pricingPlanName} plan at $${formatCurrency(
                pricingPlanAmount
              )}/month. Your first 3 months will be free.`}
            </Text>
          )}

          {isFreePlan && (
            <Text size="4xl" textAlign="center">
              You are signing up for the Free plan. Sourcing is <b>not</b>{" "}
              included. Select Standard or Premium plan for sourcing.
            </Text>
          )}
        </ModalBody>
        <ModalFooter style={{ marginTop: "20px" }}>
          <Flex width="100%" justifyContent="flex-end">
            <Button
              disabled={requestSent}
              variant="ghost"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={requestSent}
              colorScheme="blue"
              isLoading={requestSent}
              onClick={handleSubmit}
            >
              Confirm
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
