import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  VStack,
  Button,
  Icon,
} from "@chakra-ui/react";
import React from "react";
import { CheckCircleIcon } from "@chakra-ui/icons";

interface SourcingSuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
  modalType: "sourcing" | "pay";
  paymentMethod?: "bank" | "mobile";
}

const SourcingSuccessModal: React.FC<SourcingSuccessModalProps> = ({
  onClose,
  isOpen,
  modalType,
  paymentMethod,
}) => {
  const renderTitleAndSubtext = (): { title: string; subtext: string } => {
    if (modalType === "sourcing") {
      return {
        title: "Request submitted",
        subtext: "You will be notified when your request has been completed",
      };
    } else {
      if (paymentMethod === "bank") {
        return {
          title: "Order submitted",
          subtext: "We will update you on the status of the delivery.",
        };
      }
      return {
        title: "Payment request sent to mobile number.",
        subtext: "Your order will be processed once the payment is received.",
      };
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <VStack spacing={4} align="center">
            {/*{(modalType === "pay" || modalType === "sourcing") &&*/}
            {/*paymentMethod !== "mobile" ? (*/}
              <Icon
                as={CheckCircleIcon}
                mt={"30px"}
                boxSize={8}
                color="green.500"
              />
            {/*) : null}*/}
            <Text textAlign="center">
              {renderTitleAndSubtext().title} <br />
              {renderTitleAndSubtext().subtext}
            </Text>

            <Button colorScheme="blue" onClick={onClose} mb={4}>
              Done
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SourcingSuccessModal;
