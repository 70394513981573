import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

async function callApi(accessToken: string, options: object) {
    const result = await axios({
        ...options,
        baseURL: process.env.REACT_APP_BACKEND_URL,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
    });
    return result.data;
}

export function callGet(accessToken: string, url: string, params?: any) {
    return callApi(accessToken, {
        url,
        method: 'GET',
        params,
    });
}

export function callPost(accessToken: string, url: string, data: any) {
    return callApi(accessToken, {
        url,
        data,
        method: 'POST',
    });
}



export function callPatch(accessToken: string, url: string, data?: any) {
    return callApi(accessToken, {
        url,
        data,
        method: 'PATCH',
    });
}

export function callUpdate(accessToken: string, url: string, data: any) {
    return callApi(accessToken, {
        url,
        data,
        method: 'PUT',
    });
}

export function callDelete (accessToken: string, url: string) {
    return callApi(accessToken, {
        url,
        method: 'DELETE',
    });
}

