import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface AmountInputProps {
  totalAmount: string | null;
  currency: string | null;
}

export const AmountInput: React.FC<AmountInputProps> = ({
  totalAmount,
  currency,
}) => {
  const { t } = useTranslation();

  return (
    <FormControl>
      <FormLabel>{t("total_amount")}</FormLabel>
      <InputGroup>
        <InputLeftAddon children={currency} />
        <Input
          placeholder=""
          type="string"
          value={totalAmount || ""}
          w={["100%", "100%", "80%", "40%"]}
        />
      </InputGroup>
    </FormControl>
  );
};
