import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  Text,
  Flex,
  Input,
  FormControl,
  Stack,
  Box,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  FormLabel,
  Divider,
  Tabs,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  formatCurrency,
  triggerMobilePayRequest,
} from "../../../../../lib/utilities";
import { RepaymentTermsType } from "../../index";
import { CreateDirectPaymentRequest } from "../../../../../generated-client/model/create-direct-payment-request";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";

interface ConfirmationModalProps {
  isOpen: boolean;
  sendAmount: number;
  totalAmount: number;
  recipientCountry: string;
  recipientName: string;
  recipientAddress: string;
  accountNumber: string;
  currency: string;
  recipientSwiftCode: string;
  repaymentTerms: RepaymentTermsType | null;
  onClose: () => void;
  onConfirm: (file?: File) => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  sendAmount,
  currency,
  repaymentTerms,
  onClose,
  onConfirm,
}) => {
  const [showPaymentMethod, setShowPaymentMethod] = useState(false);
  const [selectedPaymentMethodIndex, setSelectedPaymentMethodIndex] =
    useState(0);
  const [selectedPaymentMethod] =
    useState<CreateDirectPaymentRequest.PaymentMethodEnum>();
  const [payAmount, setPayAmount] = useState(repaymentTerms?.creditAmount);
  const [mobileNumber, setMobileNumber] = useState("+250");
  const [buttonText] = useState("Submit");
  const [requestSent, setRequestSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTransferSubmitLoading, setIsTransferSubmitLoading] = useState(false);
  const [error, setError] = useState("");
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const toast = useToast();

  let fileInputRef = useRef(null);

  const handleConfirmClick = async (file?: File) => {
    setIsTransferSubmitLoading(true);
    await onConfirm(file);

    setTimeout(() => {
      setIsTransferSubmitLoading(false);
    }, 2000);
  };

  const handleFileUpload = async (file: File) => {
    await handleConfirmClick(file);
    setIsFileUploaded(true);
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    handleFileUpload(file);
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  // const handleUpdate = async () => {
  //     const accessToken = await getAccessTokenSilently();
  //     await updateCompany(accessToken, {
  //         pricingPlanId: paymentRequest.pricingPlanId,
  //         paymentMethodType: selectedPaymentMethodIndex === 1 ? "Bank" : "Mobile",
  //     });
  //     setTimeout(() => {
  //         window.location.reload();
  //     }, 2000);
  // };

  const handleConfirm = async () => {
    setShowPaymentMethod(true);
  };

  const handleSubmit = async () => {
    setError("");
    if (payAmount <= 0) {
      setError("Amount must be greater than zero");
      return false;
    }
    if (mobileNumber.length < 13) {
      setError("Please check the phone number");
      return false;
    }

    setIsLoading(true);

    try {
      const accessToken = await getAccessTokenSilently();
      await triggerMobilePayRequest({
        accessToken,
        amount: payAmount,
        currency,
        phoneNumber: mobileNumber,
      });
      setRequestSent(true);
      await onConfirm();
      setTimeout(() => {
        onClose();
        setRequestSent(false);
        setPayAmount(0);
        setIsLoading(false);
        // handleUpdate();
      }, 50);
    } catch (error: any) {
      if (error?.response?.data?.statusCode === 500) {
        toast({
          title: "Payment Error",
          description: t("mobile_pay_error"),
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: error?.response?.data?.message
            ? error?.response?.data?.message
            : t("error_occurred"),
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
    setIsLoading(false);
  };

  const handlePaymentMethodIndexChange = (index: number) => {
    setSelectedPaymentMethodIndex(index);
  };

  useEffect(() => {
    setPayAmount(repaymentTerms?.balanceToPayNow);
  }, [repaymentTerms]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {showPaymentMethod ? "Choose payment method" : "Review Payment"}
        </ModalHeader>
        <ModalBody>
          {showPaymentMethod ? (
            <VStack spacing={4}>
              <Text>
                To complete your order, please make your payment of{" "}
                <b>
                  {currency}{" "}
                  {formatCurrency(
                    !repaymentTerms?.creditAmount
                      ? sendAmount
                      : repaymentTerms?.balanceToPayNow
                  )}
                </b>
                .
              </Text>
              <Tabs
                w="100%"
                onChange={handlePaymentMethodIndexChange}
                index={selectedPaymentMethodIndex}
              >
                <TabList>
                  <Tab>{t("mobile_money")}</Tab>
                  <Tab>{t("bank_transfer")}</Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <Box
                      w="100%"
                      border={"1px"}
                      borderColor="gray.200"
                      borderRadius="md"
                      p={2}
                    >
                      <VStack spacing={4} align="left">
                        <FormControl id="amount">
                          <FormLabel>Amount to pay</FormLabel>
                          <Input
                            type="number"
                            value={payAmount}
                            onChange={(e) =>
                              setPayAmount(parseFloat(e.target.value))
                            }
                          />
                        </FormControl>
                        <FormControl id="mobileNumber">
                          <FormLabel>Enter your mobile number</FormLabel>
                          <Input
                            type="tel"
                            value={mobileNumber}
                            onChange={(e) => setMobileNumber(e.target.value)}
                          />
                        </FormControl>
                        <Text pt={"10px"} fontSize="sm">
                          {t("mobile_pay_disclaimer_1")}
                        </Text>

                        {error && (
                          <Text color="red.500" fontSize="md">
                            {error}
                          </Text>
                        )}
                      </VStack>
                    </Box>
                  </TabPanel>
                  <TabPanel>
                    <Box
                      w="100%"
                      border="1px"
                      borderColor="gray.200"
                      borderRadius="md"
                      p={2}
                    >
                      <Text fontSize="md" fontWeight="medium" mb={1}>
                        Make your payment to one of the following accounts:
                      </Text>

                      <Divider />

                      <Text fontSize="sm" mt={4}>
                        {t("account_name")} SYMPLIFI RWANDA LTD
                        <br />
                        {t("account_number")} 100145553029
                        <br />
                        {t("bank_name")} Bank of Kigali
                        <br />
                      </Text>

                      <Text fontSize="sm" mt={4}>
                        {t("account_name")} SYMPLIFI RWANDA LTD
                        <br />
                        {t("account_number")} 20071655001
                        <br />
                        {t("bank_name")} I&M Bank (Rwanda) Plc
                        <br />
                      </Text>
                    </Box>
                  </TabPanel>
                </TabPanels>
                {selectedPaymentMethodIndex === 1 && (
                  <Text fontSize="sm" pt={"10px"}>
                    {t("upload_proof_after_payment")}
                  </Text>
                )}
              </Tabs>
            </VStack>
          ) : (
            <VStack align="start" spacing={2} minW={200}>
              <Text fontWeight="bold">Payment details</Text>
              <Stack spacing={1} width="100%">
                <Flex justifyContent="space-between">
                  <Text width="50%">Total amount:</Text>
                  <Text width="50%" flex="1" textAlign="left">
                    {currency} {formatCurrency(sendAmount)}
                  </Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <Text width="50%">Pay now:</Text>
                  <Text flex="1" textAlign="left">
                    {currency}{" "}
                    {formatCurrency(sendAmount - repaymentTerms?.creditAmount)}
                  </Text>
                </Flex>
              </Stack>
              {repaymentTerms?.creditAmount > 0 && (
                <>
                  <Text fontWeight="bold">Flex pay amount to pay later</Text>
                  <Stack spacing={1} width="100%">
                    <Flex justifyContent="space-between">
                      <Text width="50%">Amount:</Text>
                      <Text width="50%" flex="1" textAlign="left">
                        {currency} {formatCurrency(repaymentTerms.creditAmount)}
                      </Text>
                    </Flex>
                    <Flex justifyContent="space-between">
                      <Text width="50%">Transaction fee:</Text>
                      <Text flex="1" textAlign="left">
                        {currency}{" "}
                        {formatCurrency(repaymentTerms.transactionFee)}
                      </Text>
                    </Flex>
                    <Flex justifyContent="space-between">
                      <Text width="50%">Total amount to repay:</Text>
                      <Text flex="1" textAlign="left">
                        {currency} {formatCurrency(repaymentTerms.totalRepay)}
                      </Text>
                    </Flex>
                    <Flex justifyContent="space-between">
                      <Text width="50%">Due date:</Text>
                      <Text flex="1" textAlign="left">
                        {repaymentTerms.dueDate}
                      </Text>
                    </Flex>
                  </Stack>
                </>
              )}
            </VStack>
          )}
          {showPaymentMethod &&
            selectedPaymentMethod ===
              CreateDirectPaymentRequest.PaymentMethodEnum.BankTransfer && (
              <Text pt="24px" fontSize="xs">
                We process our payments through our regulated partner Mamlaka
                Hub & Spoke Limited, a Payment Services Provider authorized by
                the Central Bank of Kenya.
              </Text>
            )}
        </ModalBody>
        <ModalFooter>
          {!showPaymentMethod && (
            <Flex width="100%" justifyContent="flex-end">
              <Button
                variant="ghost"
                onClick={() => {
                  onClose();
                }}
              >
                Close
              </Button>
              <Button colorScheme="blue" mr={3} onClick={handleConfirm}>
                Confirm
              </Button>
            </Flex>
          )}
          {showPaymentMethod &&
            !requestSent &&
            selectedPaymentMethodIndex === 0 && (
              <Flex width="100%" justifyContent="flex-end">
                <Button
                  variant="ghost"
                  onClick={() => {
                    onClose();
                  }}
                >
                  Close
                </Button>
                <Button
                  colorScheme="blue"
                  isLoading={isLoading}
                  onClick={handleSubmit}
                >
                  {buttonText}
                </Button>
              </Flex>
            )}
          {showPaymentMethod &&
            !requestSent &&
            selectedPaymentMethodIndex === 1 && (
              <>
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  accept="image/*, .pdf, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .jpg, .png, .jpeg"
                />
                <Button
                  onClick={triggerFileInput}
                  disabled={isTransferSubmitLoading}
                  isLoading={isTransferSubmitLoading}
                  mr="3px"
                  colorScheme="blue"
                >
                  {isFileUploaded ? "✅" : ""} {t("upload_proof_of_payment")}
                </Button>
              </>
            )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationModal;
