/**
 * Symplifi   API
 * Symplifi backend API description 
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateDirectPaymentRequest { 
    country: string;
    amount: number;
    amountCurrency: CreateDirectPaymentRequest.AmountCurrencyEnum;
    amountToPay: number;
    amountToPayCurrency: CreateDirectPaymentRequest.AmountToPayCurrencyEnum;
    paymentMethod: CreateDirectPaymentRequest.PaymentMethodEnum;
    type: CreateDirectPaymentRequest.TypeEnum;
    termsAccepted: boolean;
    fileId: string;
    recipientName: string;
    recipientAccountNumber: string;
    recipientAddress: string;
    recipientSwiftCode: string;
    recipientPhoneNumber: string;
    recipientIban?: string;
    exchangeRate: number;
    paymentRequestId?: string;
}
export namespace CreateDirectPaymentRequest {
    export type AmountCurrencyEnum = 'USD' | 'KES' | 'KES_SUPPLIER' | 'KES_OTHER' | 'RWF' | 'AED' | 'CNY';
    export const AmountCurrencyEnum = {
        Usd: 'USD' as AmountCurrencyEnum,
        Kes: 'KES' as AmountCurrencyEnum,
        KesSupplier: 'KES_SUPPLIER' as AmountCurrencyEnum,
        KesOther: 'KES_OTHER' as AmountCurrencyEnum,
        Rwf: 'RWF' as AmountCurrencyEnum,
        Aed: 'AED' as AmountCurrencyEnum,
        Cny: 'CNY' as AmountCurrencyEnum
    };
    export type AmountToPayCurrencyEnum = 'USD' | 'KES' | 'KES_SUPPLIER' | 'KES_OTHER' | 'RWF' | 'AED' | 'CNY';
    export const AmountToPayCurrencyEnum = {
        Usd: 'USD' as AmountToPayCurrencyEnum,
        Kes: 'KES' as AmountToPayCurrencyEnum,
        KesSupplier: 'KES_SUPPLIER' as AmountToPayCurrencyEnum,
        KesOther: 'KES_OTHER' as AmountToPayCurrencyEnum,
        Rwf: 'RWF' as AmountToPayCurrencyEnum,
        Aed: 'AED' as AmountToPayCurrencyEnum,
        Cny: 'CNY' as AmountToPayCurrencyEnum
    };
    export type PaymentMethodEnum = 'BANK_TRANSFER' | 'MPESA';
    export const PaymentMethodEnum = {
        BankTransfer: 'BANK_TRANSFER' as PaymentMethodEnum,
        Mpesa: 'MPESA' as PaymentMethodEnum
    };
    export type TypeEnum = 'PAY_NOW' | 'PAY_NOW_SUPPLIER' | 'PAY_NOW_OTHER' | 'FLEX_PAY';
    export const TypeEnum = {
        PayNow: 'PAY_NOW' as TypeEnum,
        PayNowSupplier: 'PAY_NOW_SUPPLIER' as TypeEnum,
        PayNowOther: 'PAY_NOW_OTHER' as TypeEnum,
        FlexPay: 'FLEX_PAY' as TypeEnum
    };
}


