import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { GetAllPaymentRequestsRequest } from "../../../../generated-client/model/get-all-payment-requests-request";
import CountryEnum = GetAllPaymentRequestsRequest.CountryEnum;
import { SupplierDto } from "generated-client/model/supplier-dto";
import { getAllSuppliersAdmin } from "services/supplier";

export const useFetchSuppliers = () => {
  const [suppliers, setSuppliers] = useState<SupplierDto[]>([]);
  const [accessToken, setAccessToken] = useState<string>("");
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  const fetchData = async (
    skip = 0,
    take = 20,
    supplierNameSearch: string = undefined,
    country: CountryEnum = null
  ) => {
    try {
      const accessToken = await getAccessTokenSilently();
      setAccessToken(accessToken);
      const data = await getAllSuppliersAdmin(accessToken, {
        skip,
        take,
        supplierNameSearch,
        country,
      });
      setSuppliers(data.suppliers);
    } catch (error) {
      console.error("Error fetching suppliers:", error);
    }
  };

  useEffect(() => {
    if (isLoading || !isAuthenticated) return;

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated, getAccessTokenSilently]);

  return { suppliers, accessToken, fetchData };
};
