/**
 * Symplifi   API
 * Symplifi backend API description 
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateCompanyKycStatusRequest { 
    id?: string | null;
    status: UpdateCompanyKycStatusRequest.StatusEnum;
}
export namespace UpdateCompanyKycStatusRequest {
    export type StatusEnum = 'NOT_SUBMITTED' | 'PENDING' | 'APPROVED' | 'DECLINED';
    export const StatusEnum = {
        NotSubmitted: 'NOT_SUBMITTED' as StatusEnum,
        Pending: 'PENDING' as StatusEnum,
        Approved: 'APPROVED' as StatusEnum,
        Declined: 'DECLINED' as StatusEnum
    };
}


