import {
	useBreakpointValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import TableComponent from "./components/Table";
import CardLayout from "./components/CardLayout";
import {DirectPaymentDto} from "../../../generated-client/model/direct-payment-dto";
import {attachProofOfUserPayment, getDirectPayments} from "../../../services/direct-payment";
import {uploadFile} from "../../../services/file";

export default function MyDirectPayments() {
	const [directPayments, setDirectPayments] = useState<DirectPaymentDto[]>([]);
	const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
	const isTableLayout = useBreakpointValue({ base: false, lg: true });
	const [accessToken, setAccessToken] = useState<string>('');

	async function fetchData() {
		try {
			const accessToken = await getAccessTokenSilently();
			setAccessToken(accessToken);
			const data = await getDirectPayments(accessToken);

			setDirectPayments(data.directPayments);
		} catch (error) {
			console.error("Error fetching payment requests:", error);
		}
	}

	async function sendFile(file: File, directPaymentId: string) {
		try {
			const fileUploaded = await uploadFile(accessToken, {
				directPaymentId: directPaymentId,
				type: "PROOF_OF_USER_PAYMENT",
			}, file);

			await attachProofOfUserPayment(accessToken, {
				id: directPaymentId,
				fileId: fileUploaded.id,
			});

		} catch (error) {
			console.error("Error fetching payment requests:", error);
		}
	}

	useEffect(() => {
		if (isLoading) {
			return;
		}

		if (isAuthenticated) {
			fetchData();
		}
	}, [isLoading, isAuthenticated]);

	return (
		<>
			{isTableLayout ? (
				<TableComponent
					directPayments={directPayments}
					accessToken={accessToken}
					sendFile={sendFile}
					fetchData={fetchData}
				/>
			) : (
				<CardLayout
					directPayments={directPayments}
					accessToken={accessToken}
					sendFile={sendFile}
					fetchData={fetchData}
				/>
			)}
		</>
	);
}
