import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Button,
    Icon,
    Flex,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {CompanyDto} from "../../generated-client/model/company-dto";
import CountryEnum = CompanyDto.CountryEnum;

export default function LanguagePicker(props: { userCountry: CountryEnum}) {
    const { i18n } = useTranslation();
    const { userCountry } = props;

    const flag = (countryCode: string) => {
        return <span className={`flag-icon flag-icon-${countryCode}`} />;
    };

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    };

    return (
        <Flex justifyContent="flex-end">
            <Menu>
                {({ isOpen }) => (
                    <>
                        <MenuButton
                            as={Button}
                            size="sm"
                            rightIcon={<Icon as={ChevronDownIcon} />}
                            width="auto"  // Set width to auto
                        >
                            {flag(i18n.language === 'rw' ? 'rw' : 'us')}
                        </MenuButton>
                        <MenuList minW="0">
                            <MenuItem minH="48px" onClick={() => changeLanguage('en')}>
                                <Box>
                                    {flag('us')} {/* Note: 'us' for United States (English) */}
                                </Box>
                            </MenuItem>
                            <MenuItem minH="48px" onClick={() => changeLanguage('rw')}>
                                <Box>
                                    {flag('rw')} {/* Note: 'rw' for Rwanda (Kinyarwanda) */}
                                </Box>
                            </MenuItem>
                        </MenuList>
                    </>
                )}
            </Menu>
        </Flex>
    );
}
