import { Box, Text } from "@chakra-ui/react";
import { SupplierDto } from "generated-client/model/supplier-dto";

interface CardLayoutProps {
  suppliers: SupplierDto[];
  openEditSupplierModal: (supplierId: string) => void;
}

export default function CardLayout({
  suppliers,
  openEditSupplierModal,
}: CardLayoutProps) {
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {suppliers?.map((supplier) => (
        <Box
          key={supplier.id}
          bg="white"
          p={5}
          shadow="md"
          borderWidth="1px"
          mb={4}
          onClick={() => openEditSupplierModal(supplier.id)}
        >
          <Text fontSize="xl">{supplier.friendlyName}</Text>
          <Text>
            {supplier.category
              .replace(/_/g, " ")
              .toLowerCase()
              .replace(/\b[a-z]/g, (char) => char.toUpperCase())}
          </Text>

          {/* Info section */}
          <Box mb={4} fontSize="0.9em">
            <Text>
              Date Listed:{" "}
              {new Date(supplier.createdAt).toLocaleDateString(undefined, {
                year: "numeric",
                month: "long",
              })}
            </Text>
            <Text>Country: {supplier.country}</Text>
          </Box>
        </Box>
      ))}
    </Box>
  );
}
