import {Box, Flex, FormLabel, Icon, Stack, Text} from "@chakra-ui/react";
import {formatCurrency} from "../../../../lib/utilities";
import React from "react";
import {HSeparator} from "../../../../components/separator/Separator";
import {FaCalendar, FaCalendarAlt, FaEthereum} from "react-icons/fa";

interface RepaymentTermsProps {
    paymentAmount: number;
    transactionFee: number;
    totalRepay: number;
    balanceToPayNow: number;
    currency: string;
    repaymentSchedule: any[];
}

export const RepaymentTerms: React.FC<RepaymentTermsProps> = ({repaymentSchedule, paymentAmount, transactionFee, totalRepay, currency, balanceToPayNow }) => {
    return (
        <Stack spacing={1} w={["100%", "100%", "100%", "130%"]}>
            <Flex justify="space-between" mb={1}>
                <FormLabel>Payment amount</FormLabel>
                <Text>{currency} {formatCurrency(paymentAmount)}</Text>
            </Flex>
            <Flex justify="space-between" mb={1}>
                <FormLabel>Transaction fee</FormLabel>
                <Text flex="1" textAlign="right">{currency} {formatCurrency(transactionFee)}</Text>
            </Flex>
            <Flex justify="space-between" mb={0}>
                <FormLabel fontWeight="bold">Total amount to repay</FormLabel>
                <Text fontWeight="bold">{currency} {formatCurrency(totalRepay)}</Text>
            </Flex>
            <HSeparator mb='5px' mt='0px' />
            <Box>
                <Text mb={2}>Repayment Schedule</Text>
                {repaymentSchedule?.map((installment, index) => (
                    <Flex key={index} justifyContent="space-between">
                        <Text mr={19}><Icon w='9px' h='14px' as={FaCalendarAlt} /> {installment.date}</Text>
                        <Text>{currency} {formatCurrency(installment.amount)}</Text>
                    </Flex>
                ))}
            </Box>
            <HSeparator mb='5px' mt='5px' />
            <Flex justify="space-between">
                <FormLabel fontWeight="bold">Balance to pay now</FormLabel>
                <Text fontWeight="bold">{currency} {formatCurrency(balanceToPayNow)}</Text>
            </Flex>
        </Stack>
    );
};
