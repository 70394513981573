import { Button, Flex, Text } from '@chakra-ui/react';

type PaginationProps = {
    currentPage: number;
    pageSize: number;
    elementsCount: number;
    onPageChange: (page: number) => void;
};

export default function Pagination({ currentPage, pageSize, elementsCount, onPageChange }:  PaginationProps) {
    // const totalPages = Math.ceil(totalItems / pageSize);

    const handlePrev = () => {
        if (currentPage > 1) onPageChange(currentPage - 1);
    };

    const handleNext = () => {
        if (elementsCount === pageSize) onPageChange(currentPage + 1);
    };

    return (
        <Flex justify="center" align="center" mt="4">
            <Button onClick={handlePrev} disabled={currentPage <= 1}>Prev</Button>
            <Text mx="8">
                Page {currentPage}
            </Text>
            <Button onClick={handleNext} disabled={elementsCount !== pageSize}>Next</Button>
        </Flex>
    );
};
