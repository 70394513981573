import {
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import Card from "../../../../components/card/Card";

import { ProductDto } from "generated-client/model/product-dto";

interface TableComponentProps {
  products: ProductDto[];
  handleDeleteProduct: (id: string) => void;
  handleOpenEditProduct: (product: ProductDto, status: boolean) => void;
}

export default function TableComponent({
  products,
  handleDeleteProduct,
  handleOpenEditProduct,
}: TableComponentProps) {
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        w={{ base: "100%", md: "100%" }}
        mb={{ base: "0px", xl: "20px" }}
        minH="365px"
        pe="20px"
      >
        <Table variant="simple" size="md" fontSize="0.9em">
          <Thead>
            <Tr style={{ borderBottom: "2px solid #E2E8F0" }}>
              <Th>Supplier Name</Th>
              <Th>Product</Th>
              <Th>Price</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {products?.map((product) => (
              <Tr
                key={product.id}
                style={{ borderBottom: "2px solid #E2E8F0" }}
              >
                <Td>{product?.supplier?.name}</Td>
                <Td>{product.name}</Td>
                <Td>{product.price}</Td>
                <Td>
                  <Flex wrap={"wrap"} gap={2}>
                    <Button
                      colorScheme="blue"
                      size="sm"
                      borderRadius={4}
                      onClick={() => handleOpenEditProduct(product, true)}
                    >
                      Update
                    </Button>

                    <Button
                      colorScheme="red"
                      onClick={() => handleDeleteProduct(product.id)}
                      size="sm"
                      borderRadius={4}
                    >
                      Delete
                    </Button>
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Card>
    </Box>
  );
}
