import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    VStack,
    Text,
    Icon, Flex
} from "@chakra-ui/react";
import {CheckCircleIcon, SpinnerIcon, TimeIcon} from "@chakra-ui/icons"; // Import the CheckCircleIcon
import React from "react";

interface SuccessModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ onClose, isOpen }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader></ModalHeader>
                <ModalBody>
                    <VStack spacing={4} align="center">
                            <Icon as={CheckCircleIcon} boxSize={8} color="green.500" />
                            <Text textAlign="center">
                                Order submitted<br />
                                We are processing your order. You can view the Purchase Order in ‘My transactions’.
                            </Text>
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <Flex width="100%" justifyContent="center"> {/* Use Flex to center the button */}
                        <Button colorScheme="blue" onClick={onClose}>
                            Close
                        </Button>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default SuccessModal;
