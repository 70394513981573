import {
    Box, Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Image,
    Input,
    InputGroup,
    InputLeftAddon,
    Text, useColorModeValue
} from "@chakra-ui/react";
import DefaultAuth from "../../../../layouts/auth/Default";
import illustration from "../../../../assets/img/auth/symplifi.png";
import logoImage from "../../../../assets/img/dashboards/logo-nobg-dark.png";
import LanguagePicker from "../../../../components/languagePicker/LanguagePicker";
import {HSeparator} from "../../../../components/separator/Separator";
import React from "react";
import {useTranslation} from "react-i18next";
import {Item} from "../../paymentRequest";

interface GetStartedProps {
    onContinue: () => void;
}


export const GetStarted: React.FC<any> = ({onContinue}) => {
    const { t } = useTranslation();
    const textColor = useColorModeValue("navy.700", "white");


    return (
                <Box me='auto'>
                    <Heading color={textColor} fontSize='36px' mb='10px'>
                        {t('congratulations')}
                    </Heading>
                    <Text fontSize="md" mb={5}>
                        {t('qualified')}
                    </Text>
                    <Text fontSize="md" mb={5}>
                        {t('manage_cash')}
                    </Text>
                    <Heading size="md" mb={3}>{t('how_it_works')}</Heading>
                    <Text fontSize="md" mb={3}>
                        {t('how_it_works_steps')}
                    </Text>
                    <Flex ml={5} flexDirection="column" mb={3}>
                        <Text>- {t('step_1')}</Text>
                        <Text>- {t('step_2')}</Text>
                        <Text>- {t('step_3')}</Text>
                    </Flex>
                    <Text fontSize="md" mb={3}>
                        {t('we_take_care')}
                    </Text>
                    <Heading size="md" mb={3}>{t('terms')}</Heading>
                    <Text fontSize="md" mb={5}>
                        {t('terms_detail')}
                        {t('repay_on_time')}
                    </Text>
                    <Button colorScheme="blue" variant="solid" onClick={onContinue}>
                        {t('get_started')}
                    </Button>
                </Box>
    );
};
