import {ReactElement, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

function DefaultRedirectAdmin(): ReactElement | null {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/admin/default');
    }, [navigate]);

    return null;
}

export default DefaultRedirectAdmin;
