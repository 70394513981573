// Chakra Imports
import {
	Avatar,
	Button,
	Flex,
	Icon,
	Image,
	Link,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	useColorModeValue,
	useColorMode
} from '@chakra-ui/react';
// Custom Components
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import PropTypes from 'prop-types';
import React from 'react';
// Assets
import { FaEthereum } from 'react-icons/fa';
import routes from 'routes';

import { useAuth0 } from "@auth0/auth0-react";
import {redirect, Route, useNavigate} from "react-router-dom";
import LanguagePicker from "../languagePicker/LanguagePicker";
import {CompanyDto} from "../../generated-client/model/company-dto";
import CountryEnum = CompanyDto.CountryEnum;

export default function HeaderLinks(props: { secondary: boolean, userAdmin: boolean, companyCountry: CountryEnum, }) {
	const { secondary, userAdmin, companyCountry } = props;
	const navigate = useNavigate();
	const { logout } = useAuth0();
	const { colorMode, toggleColorMode } = useColorMode();
	// Chakra Color Mode
	const navbarIcon = useColorModeValue('gray.400', 'white');
	let menuBg = useColorModeValue('white', 'navy.800');
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const textColorBrand = useColorModeValue('brand.700', 'brand.400');
	const ethColor = useColorModeValue('gray.700', 'white');
	const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
	const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
	const ethBox = useColorModeValue('white', 'navy.800');
	const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
	);
	const getRoutes = (routes: RoutesType[]): any => {
		return routes.filter(r => r.admin !== true);
	};

	const borderButton = useColorModeValue('secondaryGray.500', 'whiteAlpha.200');
	return (
		<Flex
			w={{ sm: '100%', md: 'auto' }}
			alignItems='center'
			flexDirection='row'
			bg={menuBg}
			flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
			p='10px'
			borderRadius='30px'>
			<Flex
				bg={ethBg}
				display={secondary ? 'flex' : 'none'}
				borderRadius='30px'
				ms='auto'
				p='6px'
				align='center'
				me='6px'>
				<Flex align='center' justify='center' bg={ethBox} h='29px' w='29px' borderRadius='30px' me='7px'>
					<Icon color={ethColor} w='9px' h='14px' as={FaEthereum} />
				</Flex>
				<Text w='max-content' color={ethColor} fontSize='sm' fontWeight='700' me='6px'>
					1,924
					<Text as='span' display={{ base: 'none', md: 'unset' }}>
						{' '}
						ETH
					</Text>
				</Text>
			</Flex>
			<SidebarResponsive routes={getRoutes(routes)} />
			<Menu>
				<MenuButton p='0px'>
					<Avatar
						_hover={{ cursor: 'pointer' }}
						color='white'
						bg='#11047A'
						size='sm'
						w='30px'
						h='30px'
						src="assets/img/dashboards/profile.png" // Add the src prop with the image URL
					/>
				</MenuButton>
				<MenuList boxShadow={shadow} p='0px' mt='10px' borderRadius='20px' bg={menuBg} border='none'>
					<Flex w='100%' mb='0px'>
						<Text
							ps='20px'
							pt='16px'
							pb='10px'
							w='100%'
							borderBottom='1px solid'
							borderColor={borderColor}
							fontSize='sm'
							fontWeight='700'
							color={textColor}>
							👋&nbsp; Hey!
						</Text>
					</Flex>
					<Flex flexDirection='column' p='10px'>
						{/*{*/}
						{/*	name: 'Company details',*/}
						{/*	layout: '/dashboard',*/}
						{/*	path: '/profile',*/}
						{/*	icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,*/}
						{/*	component: Profile*/}
						{/*},*/}
						<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius='8px' px='14px'>
							<Text onClick={() => navigate('/dashboard/profile')} fontSize='sm'>Company Profile</Text>
						</MenuItem>
						{companyCountry === CountryEnum.Rwanda && (<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius='8px' px='14px'>
							<Text onClick={() => navigate('/dashboard/repaymentAccountDetails')} fontSize='sm'>Repayment Account Details</Text>
						</MenuItem>)}
						{userAdmin && (
							<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius='8px' px='14px'>
								<Text onClick={() => navigate('/admin/default')} fontSize='sm'>Admin</Text>
							</MenuItem>
							)}
						<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius='8px' px='14px'>
							<Text onClick={() => navigate('/dashboard/helpCenter')} fontSize='sm'>Help Center</Text>
						</MenuItem>
						<MenuItem
							_hover={{ bg: 'none' }}
							_focus={{ bg: 'none' }}
							color='red.400'
							borderRadius='8px'
							px='14px'>
							<Text onClick={() => {
								logout({ logoutParams: { returnTo: window.location.origin + '/auth/sign-in' } })
								navigate('/auth/sign-in');
							}} fontSize='sm'>Log Out</Text>
						</MenuItem>
					</Flex>
				</MenuList>
			</Menu>
			<LanguagePicker userCountry={companyCountry}  />
		</Flex>
	);
}

HeaderLinks.propTypes = {
	variant: PropTypes.string,
	fixed: PropTypes.bool,
	secondary: PropTypes.bool,
	onOpen: PropTypes.func
};
