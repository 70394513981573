import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Box,
  ModalHeader,
  Flex,
  IconButton,
  Button,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { SourcingRequestProFormaInvoiceDto } from "../../../../../generated-client/model/sourcing-request-pro-forma-invoice-dto";
import { formatCurrency } from "../../../../../lib/utilities";
import React, { useState } from "react";
import { downloadProFormaInvoice } from "../../../../../services/sourcing";
import { useAuth0 } from "@auth0/auth0-react";

interface InvoicesModalProps {
  isOpen: boolean;
  onClose: () => void;
  proformaInvoices: SourcingRequestProFormaInvoiceDto[];
  setProformaInvoiceToPay: (
    proformaInvoice: SourcingRequestProFormaInvoiceDto
  ) => void;
}

const InvoicesModal: React.FC<InvoicesModalProps> = ({
  proformaInvoices,
  setProformaInvoiceToPay,
  onClose,
  isOpen,
}) => {
  // loading button state
  const [loadingStates, setLoadingStates] = useState<{
    [key: string]: boolean;
  }>({});
  const { getAccessTokenSilently } = useAuth0();

  const setRowLoading = (id: string, isLoading: boolean) => {
    setLoadingStates((prev) => ({ ...prev, [id]: isLoading }));
  };
  const handleProFormaInvoiceDownload = async (
    sourcingRequestId: string,
    proFormaInvoiceId: string
  ) => {
    setRowLoading(proFormaInvoiceId, true);
    const accessToken = await getAccessTokenSilently();
    await downloadProFormaInvoice(
      sourcingRequestId,
      proFormaInvoiceId,
      accessToken
    );
    setRowLoading(proFormaInvoiceId, false);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        overflowY="auto"
        maxH="90vh"
        maxW={{ base: "90%", sm: "80%", md: "70%", lg: "30%", xl: "30%" }}
      >
        <ModalHeader>
          <Flex align="center">
            <Text fontWeight="bold" fontSize="xl">
              Pro forma invoices
            </Text>
            <IconButton
              aria-label="Close"
              icon={<CloseIcon />}
              size="sm"
              ml="auto"
              onClick={onClose}
              color="gray.500"
              variant="ghost"
            />
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Text mb={4} fontWeight="medium" fontSize="lg">
            Choose one
          </Text>
          {proformaInvoices.map((proformaInvoice, index) => {
            return (
              <Box
                w="100%"
                border={"1px"}
                borderColor="gray.200"
                borderRadius="md"
                p={2}
                mb={4}
                key={proformaInvoice.id}
              >
                <Text>Pro forma Invoice {index + 1}</Text>
                <Text>
                  Invoice Amount: {proformaInvoice.totalAmountLocalCurrency}{" "}
                  {formatCurrency(proformaInvoice.totalAmountLocal)}
                </Text>
                <Text>
                  Estimated Shipping: {proformaInvoice.totalAmountLocalCurrency}{" "}
                  {formatCurrency(proformaInvoice.shippingEstimateAmount)}
                </Text>

                <Flex alignItems="center" gap={4} mt={4}>
                  <Button
                    colorScheme="blue"
                    size="sm"
                    isLoading={loadingStates[proformaInvoice.id]}
                    onClick={() =>
                      handleProFormaInvoiceDownload(
                        proformaInvoice.sourcingRequestId,
                        proformaInvoice.id
                      )
                    }
                  >
                    View
                  </Button>
                  <Button
                    colorScheme="green"
                    size="sm"
                    onClick={() => setProformaInvoiceToPay(proformaInvoice)}
                  >
                    Pay
                  </Button>
                </Flex>
              </Box>
            );
          })}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default InvoicesModal;
