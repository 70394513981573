import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  Text,
  Input,
  FormControl,
  Stack,
  RadioGroup,
  Radio,
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  FormLabel,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  formatCurrency,
  triggerMobilePayRequest,
} from "../../../../lib/utilities";
import { SupplierDto } from "../../../../generated-client/model/supplier-dto";
import { CreatePaymentRequestRequest } from "../../../../generated-client/model/create-payment-request-request";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";

interface ConfirmationModalProps {
  isOpen: boolean;
  isFinalSubmitLoading: boolean;
  sendAmount: number;
  totalAmount: number;
  currency: string;
  onClose: () => void;
  onConfirm: (
    paymentMethod: CreatePaymentRequestRequest.PaymentMethodEnum,
    file: File,
    isMobilePay: boolean
  ) => void;
  supplier: SupplierDto;
  paymentType?: string;
  // lateFeeAmount?: number;
}

const PayNowModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  isFinalSubmitLoading,
  sendAmount,
  totalAmount,
  currency,
  supplier,
  onClose,
  onConfirm,
  paymentType,
  // lateFeeAmount,
}) => {
  const [showPaymentMethod, setShowPaymentMethod] = useState(false);
  const [isMobilePayLoading, setIsMobilePayLoading] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<CreatePaymentRequestRequest.PaymentMethodEnum>();
  // const isFullPayNow = totalAmount === sendAmount;
  // const isFullCredit = sendAmount === 0;
  // const isPartialCredit = sendAmount !== 0 && sendAmount !== totalAmount;
  const [selectedPaymentMethodIndex, setSelectedPaymentMethodIndex] =
    useState(0);
  const [payAmount, setPayAmount] = useState(
    paymentType === "flexPay" ? sendAmount : totalAmount
  );
  const [mobileNumber, setMobileNumber] = useState("+250");
  const [error, setError] = useState<{
    mobileNumber?: string;
    amount?: string;
  }>({ mobileNumber: "", amount: "" });

  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const toast = useToast();

  // Handler function to update the state when a new tab is selected
  const handlePaymentMethodIndexChange = (index: number) => {
    setSelectedPaymentMethodIndex(index);
  };

  const handlePaymentMethodChange = (
    paymentMethod: CreatePaymentRequestRequest.PaymentMethodEnum
  ) => {
    setSelectedPaymentMethod(paymentMethod);
  };

  const handleConfirmClick = (file?: File, isMobilePay?: boolean) => {
    onConfirm(selectedPaymentMethod, file, isMobilePay);
  };

  let fileInputRef = useRef(null);

  const handleFileUpload = async (file: File) => {
    await handleConfirmClick(file);
    setIsFileUploaded(true);
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    handleFileUpload(file);
  };

  const triggerFileInput = (directPaymentId: string) => {
    fileInputRef.current.click();
  };

  const handleMobilePayRequest = async () => {
    setIsMobilePayLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      await triggerMobilePayRequest({
        accessToken,
        amount: Math.ceil(payAmount * 1.0025),
        currency,
        phoneNumber: mobileNumber,
        supplierId: supplier.id,
      });

      handleConfirmClick(undefined, true);
    } catch (error: any) {
      if (error?.response?.data?.statusCode === 500) {
        toast({
          title: "Payment Error",
          description: t("mobile_pay_error"),
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: error?.response?.data?.message
            ? error?.response?.data?.message
            : t("error_occurred"),
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
    setIsMobilePayLoading(false);
  };

  const isValidForm = (): boolean => {
    if (!payAmount) {
      setError({ amount: "Pay amount can not be empty" });
      setIsMobilePayLoading(false);
      return false;
    }
    if (payAmount <= 0) {
      setError({ amount: "Pay amount can not be zero" });
      setIsMobilePayLoading(false);
      return false;
    }
    if (!mobileNumber) {
      setError({ mobileNumber: "Please provide a mobile number" });
      setIsMobilePayLoading(false);
      return false;
    }
    if (mobileNumber.length < 12) {
      setError({ mobileNumber: "Mobile number must be 12 digits" });
      setIsMobilePayLoading(false);
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    setError({ mobileNumber: "", amount: "" });
    if (!isValidForm()) {
      return;
    }
    handleMobilePayRequest();
  };

  useEffect(() => {
    if (paymentType === "flexPay") {
      setPayAmount(sendAmount);
    } else {
      setPayAmount(totalAmount);
      // if (lateFeeAmount > 0) {
      //   setPayAmount(totalAmount + lateFeeAmount);
      // } else {
      //   setPayAmount(totalAmount);
      // }
    }
  }, [paymentType, sendAmount, totalAmount]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("payment")}</ModalHeader>
        <ModalBody>
          <VStack spacing={4} align="left">
            <Text>
              {t("to_complete_your_order")}{" "}
              <b>
                {currency} {formatCurrency(sendAmount)}
              </b>{" "}
              {t("to_supplier")}
            </Text>
            <Text>{t("select_payment_method")}</Text>
            <RadioGroup
              w="100%"
              onChange={handlePaymentMethodChange}
              value={selectedPaymentMethod}
            >
              <Stack direction="column" alignItems="flex-start">
                <Box
                  w="100%"
                  border="1px"
                  borderColor="gray.200"
                  borderRadius="md"
                  p={2}
                >
                  <Radio
                    value={
                      CreatePaymentRequestRequest.PaymentMethodEnum.OnDelivery
                    }
                  >
                    {t("pay_on_delivery")}
                  </Radio>
                </Box>
                <Box
                  w="100%"
                  border="1px"
                  borderColor="gray.200"
                  borderRadius="md"
                  p={2}
                >
                  <Radio
                    value={
                      CreatePaymentRequestRequest.PaymentMethodEnum.Transfer
                    }
                  >
                    {t("pay_now")}
                  </Radio>
                </Box>
                {selectedPaymentMethod ===
                  CreatePaymentRequestRequest.PaymentMethodEnum.Transfer && (
                  <Tabs
                    w="100%"
                    onChange={handlePaymentMethodIndexChange}
                    index={selectedPaymentMethodIndex}
                  >
                    <TabList>
                      <Tab>{t("bank_transfer")}</Tab>
                      <Tab>{t("mobile_money")}</Tab>
                    </TabList>

                    <TabPanels>
                      <TabPanel>
                        <Box
                          w="100%"
                          border="1px"
                          borderColor="gray.200"
                          borderRadius="md"
                          p={2}
                        >
                          <Text fontSize="sm">
                            {t("account_name")} {supplier?.name}
                            <br />
                            {t("account_number")} {supplier?.accountNumber}
                            <br />
                            {t("bank_name")} {supplier?.bankName}
                            <br />
                          </Text>
                        </Box>
                      </TabPanel>
                      <TabPanel>
                        <Box
                          w="100%"
                          border={supplier?.mobilePayNumber ? "1px" : "0px"}
                          borderColor="gray.200"
                          borderRadius="md"
                          p={2}
                        >
                          {supplier?.mobilePayNumber ? (
                            <>
                              <FormControl id="amount">
                                <FormLabel>Enter amount to pay</FormLabel>
                                <Input
                                  type="number"
                                  value={payAmount}
                                  onChange={(e) =>
                                    setPayAmount(parseFloat(e.target.value))
                                  }
                                />
                                <Text color="red.500" pt={"10px"} mb={4}>
                                  {error?.amount}
                                </Text>
                              </FormControl>
                              <FormControl id="mobileNumber">
                                <FormLabel mt={4}>
                                  Enter your mobile number
                                </FormLabel>
                                <Input
                                  type="tel"
                                  value={mobileNumber}
                                  onChange={(e) =>
                                    setMobileNumber(e.target.value)
                                  }
                                />
                                {error?.mobileNumber && (
                                  <Text color="red.500" pt={"10px"} mb={4}>
                                    {error?.mobileNumber}
                                  </Text>
                                )}
                              </FormControl>
                            </>
                          ) : (
                            <Text fontSize="sm">{t("not_available")}</Text>
                          )}
                        </Box>
                        {supplier?.mobilePayNumber && (
                          <Text pt={"10px"} fontSize="sm">
                            {t("mobile_pay_disclaimer_1")}
                          </Text>
                        )}
                      </TabPanel>
                    </TabPanels>
                    {selectedPaymentMethod ===
                      CreatePaymentRequestRequest.PaymentMethodEnum.Transfer &&
                      selectedPaymentMethodIndex === 0 && (
                        <Text fontSize="sm" pt={"10px"}>
                          {t("upload_proof_after_payment")}
                        </Text>
                      )}
                    {selectedPaymentMethodIndex === 0 && (
                      <Text fontSize="sm" pt={"15px"}>
                        {t("view_account_details_and_upload_proof_of_payment")}
                      </Text>
                    )}
                  </Tabs>
                )}
              </Stack>
            </RadioGroup>
          </VStack>
          {showPaymentMethod && selectedPaymentMethodIndex === 0 && (
            <Text pt="24px" fontSize="xs">
              We process our payments through our regulated partner Mamlaka Hub
              & Spoke Limited, a Payment Services Provider authorized by the
              Central Bank of Kenya.
            </Text>
          )}
        </ModalBody>
        {selectedPaymentMethod ===
          CreatePaymentRequestRequest.PaymentMethodEnum.Transfer && (
          <ModalFooter display="flex">
            <FormControl display="flex" justifyContent="flex-end" w="100%">
              {selectedPaymentMethodIndex === 0 ? (
                <>
                  <Button
                    isLoading={isFinalSubmitLoading}
                    mr={5}
                    onClick={() => {
                      setShowPaymentMethod(false);
                      handleConfirmClick();
                    }}
                  >
                    {t("continue")}
                  </Button>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    accept="image/*, .pdf, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .jpg, .png, .jpeg"
                  />
                  <Button
                    onClick={() => triggerFileInput("1")}
                    hidden={isFinalSubmitLoading}
                    mr="3px"
                    colorScheme="blue"
                  >
                    {isFileUploaded ? "✅" : ""} {t("upload_proof_of_payment")}
                  </Button>
                </>
              ) : (
                supplier?.mobilePayNumber && (
                  <>
                    <Button mr={5} onClick={onClose}>
                      {t("close")}
                    </Button>
                    <Button
                      onClick={handleSubmit}
                      isLoading={isMobilePayLoading}
                      mr="3px"
                      colorScheme="blue"
                    >
                      {t("submit")}
                    </Button>
                  </>
                )
              )}
            </FormControl>
          </ModalFooter>
        )}
        {selectedPaymentMethod ===
          CreatePaymentRequestRequest.PaymentMethodEnum.OnDelivery && (
          <ModalFooter display="flex">
            <FormControl display="flex" justifyContent="flex-end" w="100%">
              <Button
                isLoading={isFinalSubmitLoading}
                mr="3px"
                colorScheme="blue"
                onClick={() => {
                  setShowPaymentMethod(false);
                  handleConfirmClick();
                }}
              >
                {t("continue")}
              </Button>
            </FormControl>
          </ModalFooter>
        )}
        {!selectedPaymentMethod && (
          <ModalFooter display="flex">
            <FormControl display="flex" justifyContent="flex-end" w="100%">
              <Button
                mr="3px"
                colorScheme="blue"
                onClick={() => {
                  setShowPaymentMethod(false);
                }}
                isDisabled
              >
                {t("continue")}
              </Button>
            </FormControl>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default PayNowModal;
