import {Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text} from "@chakra-ui/react";
import {formatCurrency} from "../../../../lib/utilities";
import React from "react";
import {PaymentRequestDtoWithOtherData} from "../../../../generated-client/model/payment-request-dto-with-other-data";

interface RequestModalProps {
    isModalOpen: boolean;
    handleCloseModal: () => void;
    selectedRequest: PaymentRequestDtoWithOtherData | null; // Replace with the appropriate type
}

export default function RequestModal({ isModalOpen, handleCloseModal, selectedRequest }: RequestModalProps) {
   return (
     <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <ModalOverlay/>
        <ModalContent>
            <ModalHeader>Payment Request Details</ModalHeader>
            <ModalCloseButton/>
            <ModalBody>
                <Text mt={4} mb={2} fontSize='xl'><strong>Payment request:</strong></Text>
                <Text><strong>Recipient:</strong> {selectedRequest?.supplierName}</Text>
                <Text><strong>Amount:</strong> {selectedRequest?.amountCurrency + ' ' + formatCurrency(selectedRequest?.amount)}</Text>
                {selectedRequest?.amount - selectedRequest?.amountCredit > 0 && (
                    <Text><strong>Pay now:</strong> {selectedRequest?.amountCurrency + ' ' + formatCurrency(selectedRequest?.amount - selectedRequest?.amountCredit)}</Text>)
                }
                {selectedRequest?.amountCredit > 0 && (
                        <Text><strong>Flex pay:</strong> {selectedRequest?.amountCreditCurrency + ' ' + formatCurrency(selectedRequest?.amountCredit)}</Text>
                )}
                {selectedRequest?.amountToRepay > 0 && (
                    <Text><strong>Amount to repay:</strong> {selectedRequest?.amountToRepayCurrency + ' ' + formatCurrency(selectedRequest?.amountToRepay)}</Text>
                )}

                <Text><strong>Created
                    At:</strong> {new Date(selectedRequest?.createdAt).toLocaleDateString('en-US')}
                </Text>
                <Text><strong>Due
                    Date:</strong> {new Date(selectedRequest?.dueDate).toLocaleDateString('en-US')}
                </Text>
                <Text><strong>Status:</strong> {selectedRequest?.status.replace('_', ' ')}</Text>
                <Text><strong>Updated
                    At:</strong> {new Date(selectedRequest?.updatedAt).toLocaleDateString('en-US')}
                </Text>

                {/* Items */}
                <Text mt={4} mb={2} fontSize='xl'><strong>Items:</strong></Text>
                {selectedRequest?.items.map(item => (
                    <Text>{item.name}: {formatCurrency(item.quantity)} * {selectedRequest.amountCurrency} {formatCurrency(item.unitPrice)} = {selectedRequest.amountCurrency} {formatCurrency(item.total)}</Text>
                ))}

                {/* Company Details */}
                <Text mt={4} mb={2} fontSize='xl'><strong>Company Details:</strong></Text>
                <Text><strong>Name:</strong> {selectedRequest?.company?.name}</Text>
                <Text><strong>Address:</strong> {selectedRequest?.company?.address}</Text>

                {/* Company Credit Infor Details */}
                <Text mt={4} mb={2} fontSize='xl'><strong>Company Credit Information:</strong></Text>
                <Text><strong>Credit
                    score:</strong> {selectedRequest?.company?.creditInformation?.creditScore}</Text>
                <Text><strong>Credit
                    limit:</strong> {selectedRequest?.company?.creditInformation?.creditLimit}</Text>
            </ModalBody>
        </ModalContent>
    </Modal>
    );
}
