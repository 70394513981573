import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Box,
  ModalHeader,
  Flex,
  IconButton,
  Divider,
} from "@chakra-ui/react";
import React, { Fragment } from "react";
import { CloseIcon } from "@chakra-ui/icons";
import { SourcingRequestProFormaInvoiceDto } from "generated-client/model/sourcing-request-pro-forma-invoice-dto";

interface ProformaInvoiceDetailModalProps {
  isOpen: boolean;
  onClose: () => void;
  proformaInvoice: SourcingRequestProFormaInvoiceDto;
}

const ProformaInvoiceDetailModal: React.FC<ProformaInvoiceDetailModalProps> = ({
  proformaInvoice,
  onClose,
  isOpen,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent overflowY="auto" maxH="50vh">
        <ModalHeader>
          <Flex align="center">
            <Text>Invoice</Text>
            <IconButton
              aria-label="Close"
              icon={<CloseIcon />}
              size="sm"
              ml="auto"
              onClick={onClose}
              color="gray.500"
              variant="ghost"
            />
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Box
            w="100%"
            border={"1px"}
            borderColor="gray.200"
            borderRadius="md"
            p={2}
            mb={4}
          >
            <Text>Supplier Name: {proformaInvoice.supplierName}</Text>
            <Text>Supplier Address: {proformaInvoice.supplierAddress}</Text>
            <Text>Total Amount: {proformaInvoice.totalAmount}</Text>
            <Text>
              Shipping Estimate: {proformaInvoice.shippingEstimateAmount}
            </Text>
            <Text>
              Shipping Arrival Date: {proformaInvoice.shippingArrivalDate}
            </Text>

            <Divider my={4} />

            <Text fontWeight="bold" fontSize={16} mb={2}>
              Invoice items
            </Text>

            {proformaInvoice.items.map((item, index) => {
              return (
                <Fragment key={item.id}>
                  <Text mb={1}>Name: {item.name}</Text>
                  <Text>Quantity: {item.quantity}</Text>
                  <Text>Price: {item.price}</Text>
                  <Text>Price: {item.total}</Text>
                </Fragment>
              );
            })}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ProformaInvoiceDetailModal;
