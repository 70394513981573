import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    VStack,
    Text,
    Icon, Flex, FormControl
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons"; // Import the CheckCircleIcon
import React, {useRef, useState} from "react";
import {formatCurrency} from "../../../../lib/utilities";

interface SuccessModalProps {
    isOpen: boolean;
    amount: number;
    currency: string;
    bankDetails: string[];
    onClose: () => void;
}

const ProofOfUserPaymentModal: React.FC<SuccessModalProps> = ({ bankDetails, onClose, amount, currency, isOpen }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);

    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    }

    const triggerFileInput = () => {
        fileInputRef.current.click();
    }


    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader></ModalHeader>
                <ModalBody>
                    <VStack spacing={4} align="center">
                        <Icon as={CheckCircleIcon} boxSize={8} color="green.500" /> {/* Big green checkmark */}
                        <Text textAlign="center">
                            <b>Flex pay request submitted</b><br />
                        </Text>
                        <Text textAlign="center">We are processing your request. You will receive a notification when its approved.</Text>
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <Flex width="100%" justifyContent="center"> {/* Use Flex to center the button */}
                        <Button colorScheme="blue" onClick={onClose}>
                            Continue
                        </Button>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ProofOfUserPaymentModal;
