import {Box, Button, Table, Tbody, Td, Text, Th, Thead, Tr, Link, FormControl} from "@chakra-ui/react";
import Card from "../../../../components/card/Card";
import { formatCurrency } from "../../../../lib/utilities";
import React, {useRef, useState} from "react";
import {DirectPaymentDto} from "../../../../generated-client/model/direct-payment-dto";
import { downloadFile } from "services/file";
import {UploadFileRequest} from "../../../../generated-client/model/upload-file-request";
import TypeEnum = UploadFileRequest.TypeEnum;
import {PaymentRequestDtoWithOtherData} from "../../../../generated-client/model/payment-request-dto-with-other-data";
import StatusEnum = DirectPaymentDto.StatusEnum;

interface TableComponentProps {
    directPayments: DirectPaymentDto[];
    accessToken: string;
    sendFile: (file: File, directPaymentId: string) => void;
    handleStatusChange: (directPaymentId: string, newStatus: string) => void;
    fetchData: () => void;
    errorMessages: { [requestId: string]: string };
}

export default function TableComponent({ directPayments, accessToken, sendFile, handleStatusChange, errorMessages }: TableComponentProps) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [loadingStates, setLoadingStates] = useState<{ [key: string]: boolean }>({});
    const [currentDirectPaymentId, setCurrentDirectPaymentId] = useState<string | null>(null);  // New state to keep track of the current directPaymentId
    let fileInputRef = useRef(null);

    const setRowLoading = (id: string, isLoading: boolean) => {
        setLoadingStates(prev => ({ ...prev, [id]: isLoading }));
    };

    const handleFileUpload = async (file: File, directPaymentId: string) => {
        setRowLoading(directPaymentId, true);  // Set loading state
        await sendFile(file, directPaymentId);  // Assume sendFile is defined somewhere
        setRowLoading(directPaymentId, false);  // Clear loading state
        window.location.reload();
    };

    const handleFileChange = (event: any) => {
        if (!currentDirectPaymentId) return;  // Exit early if no current directPaymentId

        const file = event.target.files[0];
        handleFileUpload(file, currentDirectPaymentId);
    }

    const triggerFileInput = (directPaymentId: string) => {
        setCurrentDirectPaymentId(directPaymentId);  // Set the current directPaymentId when the button is clicked
        fileInputRef.current.click();
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Card
                w={{ base: "100%", md: "100%" }}
                mb={{ base: "0px", xl: "20px" }}
                minH="365px"
                pe="20px"
            >
                <Table variant="simple" size="md" fontSize="0.9em">
                    <Thead>
                        <Tr>
                            <Th>Transfer</Th>
                            <Th>Payment</Th>
                            <Th>Status</Th>
                            <Th>Files</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {directPayments?.map((payment) => (
                            <Tr key={payment.id}>
                                <Td minW="200px">
                                    {payment.companyName && <Text>Sender: {payment.companyName}</Text>}
                                    <Text>Recipient: {payment.recipientName}</Text>
                                    <Text>Address: {payment.recipientAddress}</Text>
                                    <Text>Phone Number: {payment.recipientPhoneNumber}</Text>
                                    <Text>Account Number: {payment.recipientAccountNumber}</Text>
                                    <Text>Bank Swift code: {payment.recipientSwiftCode}</Text>
                                    <Text>Country: {payment.country}</Text>
                                </Td>
                                <Td minW="200px">
                                    <Text>Amount: {'$ ' + formatCurrency(payment.amount)}</Text>
                                    <Text>Exchange rate: {payment.amountToPayCurrency + ' ' + payment.exchangeRate}</Text>
                                    <Text>Amount to pay: {payment.amountToPayCurrency + ' ' + formatCurrency(payment.amountToPay)}</Text>
                                    {payment.paymentRequestId && (
                                        <>
                                            <br/>
                                            <Text><b>Flex pay</b></Text>
                                            <Text>Credit amount: {payment.paymentRequestCreditAmountCurrency + ' ' + formatCurrency(payment.paymentRequestCreditAmount)}</Text>
                                            <Text>Pay now: {payment.paymentRequestAmountToPayNowCurrency + ' ' + formatCurrency(payment.paymentRequestAmountToPayNow)}</Text>
                                            <Text>Due date:  {new Date(payment.paymentRequestDueDate).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric'
                                            })}</Text>
                                        </>
                                    )}
                                </Td>
                                <Td>
                                    <select
                                        value={payment.status === 'PROOF_OF_USER_PAYMENT_SUBMITTED' || payment.status === 'PROOF_OF_PLATFORM_PAYMENT_SUBMITTED' ?
                                            'PENDING' : payment.status }
                                        onChange={(e) => handleStatusChange(payment.id, e.target.value)}
                                    >
                                        {Object.values(StatusEnum).filter(s => s !== 'PROOF_OF_USER_PAYMENT_SUBMITTED' && s !== 'PROOF_OF_PLATFORM_PAYMENT_SUBMITTED').map((status) => (
                                            <option key={status} value={status}>
                                                {status.replace('_', ' ')}
                                            </option>
                                        ))}
                                    </select>
                                    <div style={{minHeight: '20px'}}>
                                        {errorMessages[payment.id] &&
                                            <Text color="red.500">{errorMessages[payment.id]}</Text>}
                                    </div>
                                </Td>
                                <Td maxW="200px">
                                    {payment.files?.map((file, index) => (
                                        <Button  fontSize="sm" color='grey' key={index}  onClick={() => downloadFile(file.key, accessToken)}>
                                            {file.type === 'PROOF_OF_PLATFORM_PAYMENT' ? 'PROOF_OF_PAYMENT' : file.type}
                                        </Button>
                                    ))}
                                </Td>
                                <Td maxW="200px">
                                    {!payment.files?.find(f => f.type === TypeEnum.ProofOfPlatformPayment) ?
                                        (
                                            <FormControl>
                                                <input
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    ref={fileInputRef}
                                                    onChange={handleFileChange}  // No need to pass directPaymentId here
                                                    accept="image/*, .pdf, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .jpg, .png, .jpeg"
                                                />
                                                <Button
                                                    onClick={() => triggerFileInput(payment.id)}  // Pass directPaymentId to triggerFileInput
                                                    isLoading={loadingStates[payment.id]}
                                                >
                                                    <Text fontSize="sm" color='grey'>Upload proof <br/> of payment</Text>
                                                </Button>
                                            </FormControl>
                                        ) : (
                                            <>  </>
                                        )}
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Card>
        </Box>
    );
}
