import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  Text,
  Flex,
  Divider,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Box,
  Tabs,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { formatCurrency } from "../../../../lib/utilities";
import { PaymentRequestDto } from "../../../../generated-client/model/payment-request-dto";
import {CompanyDto} from "../../../../generated-client/model/company-dto";
import CountryEnum = CompanyDto.CountryEnum;

const flexPayBankDetails = [
  "Account Name: MAMLAKA HUB & SPOKE LIMITED",
  "Bank account number: 55010160018203",
  "Bank/Branch: UBA Kenya Bank Limited, Westland’s Branch",
  "Swift code: UNAFKENA",
];

const mPesaDetails = [
  "Paybill Number: 559900",
  "Account number: 55010160018203",
];

interface SuccessModalProps {
  isOpen: boolean;
  country: CountryEnum;
  paymentRequest: PaymentRequestDto;
  currency: string;
  onClose: () => void;
}

const PaymentDetailsModal: React.FC<SuccessModalProps> = ({
  onClose,
  paymentRequest,
  currency,
  isOpen,
    country,
}) => {
  const [selectedPaymentMethodIndex, setSelectedPaymentMethodIndex] =
    useState(0);

  // Handler function to update the state when a new tab is selected
  const handlePaymentMethodChange = (index: number) => {
    setSelectedPaymentMethodIndex(index);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalBody>
          <VStack spacing={4} align="center">
            <Text textAlign="center">
              {"Account details"}
              <br />
            </Text>
            <VStack align="start" spacing={4}>
              <Text>
                Amount to repay:{" "}
                <b>
                  {currency} {formatCurrency(paymentRequest.amountToRepay)}
                </b>
              </Text>
              <Tabs
                onChange={handlePaymentMethodChange}
                index={selectedPaymentMethodIndex}
              >
                <TabList>
                  <Tab>Bank Transfer</Tab>
                  <Tab>Mobile Money</Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <Box
                      w="100%"
                      border="1px"
                      borderColor="gray.200"
                      borderRadius="md"
                      p={2}
                    >
                      {country === CountryEnum.Rwanda && (
                          <>
                              <Text fontSize="sm">
                              <Text fontWeight="medium" mb={2}>
                                Account name: SYMPLIFI RWANDA LTD
                              </Text>
                              <Text mb={2}>Account number: 20071655001</Text>
                              <Text mb={2}>Bank name: I&M Bank (Rwanda) Plc</Text>
                              <Text mb={2}>
                                Bank address: KN 03AV/9 Kigali, Rwanda
                              </Text>
                              <Text>Swift code: IMRWRWRW</Text>
                            </Text>
                          </>)}
                        {country === CountryEnum.Kenya && (
                            <>
                              <Text fontSize="sm">
                                <Text fontWeight="medium" mb={2}>
                                  Account name: SYMPLIFI KENYA LIMITED
                                </Text>
                                <Text mb={2}>Account number: 2050433946</Text>
                                <Text mb={2}>Bank name: ABSA TOWERS BRANCH</Text>
                              </Text>
                            </>)}
                    </Box>
                  </TabPanel>
                  <TabPanel>
                    <Box
                      w="100%"
                      border="1px"
                      borderColor="gray.200"
                      borderRadius="md"
                      p={2}
                    >
                      <Text fontSize="sm">
                        {/*<Text fontWeight="medium" mb={2}>Account name: SYMPLIFI RWANDA LTD</Text>*/}
                        {/*<Text mb={2}>Account number: 20071655001</Text>*/}
                        {/*<Text mb={2}>Bank name: I&M Bank (Rwanda) Plc</Text>*/}
                        {/*<Text mb={2}>Bank address: KN 03AV/9 Kigali, Rwanda</Text>*/}
                        {/*<Text>Swift code: IMRWRWRW</Text>*/}
                        Not available
                      </Text>
                    </Box>
                  </TabPanel>
                </TabPanels>
              </Tabs>
              <Divider mb={2} />
            </VStack>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Flex width="100%" justifyContent="center">
            {" "}
            {/* Use Flex to center the button */}
            <Button colorScheme="blue" onClick={onClose}>
              Close
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PaymentDetailsModal;
