import {callDelete, callGet, callPost, callUpdate} from "../../lib/api-private";
import {CreatePaymentRequestRequest} from "../../generated-client/model/create-payment-request-request";
import {GetAllPaymentRequestsResponse} from "../../generated-client/model/get-all-payment-requests-response";
import {GetDirectPaymentsResponse} from "../../generated-client/model/get-direct-payments-response";
import {AttachProofRequest} from "../../generated-client/model/attach-proof-request";
import {CreateDirectPaymentRequest} from "../../generated-client/model/create-direct-payment-request";
import {CreateDirectPaymentResponse} from "../../generated-client/model/create-direct-payment-response";
import {UpdatePaymentRequestStatusRequest} from "../../generated-client/model/update-payment-request-status-request";
import {UpdateDirectPaymentRequest} from "../../generated-client/model/update-direct-payment-request";
import {GetAllDirectPaymentsResponse} from "../../generated-client/model/get-all-direct-payments-response";
import {GetAllDirectPaymentsRequest} from "../../generated-client/model/get-all-direct-payments-request";

export const getDirectPayments = async (accessToken: string) : Promise<GetDirectPaymentsResponse> => {
  const data = await callGet(accessToken, "/directPayment");
  return data;
};

export const createDirectPayment = async (accessToken: string, data: CreateDirectPaymentRequest) : Promise<CreateDirectPaymentResponse> => {
  const response = await callPost(accessToken, "/directPayment", data);
  return response;
};


export const getAllDirectPaymentsAdmin = async (accessToken: string, data: GetAllDirectPaymentsRequest) : Promise<GetAllDirectPaymentsResponse> => {
  const response = await callPost(accessToken, "/directPayment/all", data);
  return response;
};


export const attachProofOfPlatformPayment = async (accessToken: string, data: AttachProofRequest) : Promise<void> => {
  callUpdate(accessToken, "/directPayment/proofOfPlatformPayment", data);
};

export const attachProofOfUserPayment = async (accessToken: string, data: AttachProofRequest) : Promise<void> => {
  callUpdate(accessToken, "/directPayment/proofOfUserPayment", data);
};

export const updateDirectPaymentAdmin = async (accessToken: string, data: UpdateDirectPaymentRequest) : Promise<void> => {
  callUpdate(accessToken, "/directPayment", data);
};
