import { Text, Box } from "@chakra-ui/react";
import Card from "components/card/Card";
import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export default function RepaymentAccountDetails() {
  const { isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    if (isLoading) {
      return;
    }
  }, [isLoading, isAuthenticated]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        w={{ base: "100%", md: "50%" }}
        mb={{ base: "0px", "2xl": "20px" }}
        gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
        minH="250px"
        pe="20px"
      >
        <Text fontSize="xl" fontWeight="bold" mb={4}>
          Repayment details
        </Text>
        <Text mb={4}>
          Transfer your credit payments to the following account:{" "}
        </Text>
        <Text fontSize="xl" fontWeight="bold" mb={4}>
          Bank transfer
        </Text>
        <Text fontWeight="medium" mb={2}>
          Account name: SYMPLIFI RWANDA LTD
        </Text>
        <Text mb={2}>Account number: 20071655001</Text>
        <Text mb={2}>Bank name: I&M Bank (Rwanda) Plc</Text>
        <Text mb={2}>Bank address: KN 03AV/9 Kigali, Rwanda</Text>
        <Text>Swift code: IMRWRWRW</Text>
        <Text fontSize="xl" fontWeight="bold" mt={4}>
          Mobile money
        </Text>
        <Text>
          Mobile money: kuri 0793897995 (Ibaruye kuri IWUNZE MAURICE IKECHUKWU)
        </Text>
      </Card>
    </Box>
  );
}
