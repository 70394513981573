import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Box,
  ModalHeader,
  Flex,
  IconButton,
} from "@chakra-ui/react";
import React, { Fragment } from "react";
import { CloseIcon, EmailIcon } from "@chakra-ui/icons";
import { SourcingItemDto } from "generated-client/model/sourcing-item-dto";
import { MdWhatsapp } from "react-icons/md";

interface OrderDetailModalProps {
  isOpen: boolean;
  onClose: () => void;
  items?: SourcingItemDto[];
}

const OrderDetailModal: React.FC<OrderDetailModalProps> = ({
  items,
  onClose,
  isOpen,
}) => {
  const handleEmailShare = () => {
    const subject = "Check out these items";
    const body = items
      ?.map(
        (item) =>
          `${item.name}, Quantity: ${item.quantity}, Product category: ${item.category}`
      )
      .join("\n");
    const mailtoUrl = `mailto:?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoUrl;
  };

  const handleWhatsAppShare = () => {
    const message = items
      ?.map(
        (item) =>
          `${item.name}, Quantity: ${item.quantity}, Product category: ${item.category}`
      )
      .join("\n");
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.location.href = whatsappUrl;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent overflowY="auto" maxH="50vh">
        <ModalHeader>
          <Flex align="center">
            <Text>Order Detail</Text>
            <IconButton
              aria-label="Close"
              icon={<CloseIcon />}
              size="sm"
              ml="auto"
              onClick={onClose}
              color="gray.500"
              variant="ghost"
            />
          </Flex>
        </ModalHeader>
        <ModalBody>
          {items.map((item, index) => {
            return (
              <Fragment key={item.id}>
                <Text fontWeight="bold" fontSize={16} mb={2}>
                  Item {index + 1}
                </Text>
                <Box
                  w="100%"
                  border={"1px"}
                  borderColor="gray.200"
                  borderRadius="md"
                  p={2}
                  mb={4}
                >
                  <Text mb={1}>Name: {item.name}</Text>
                  <Text mb={1}>Product Category: {item.category}</Text>
                  <Text>Quantity: {item.quantity}</Text>
                </Box>
              </Fragment>
            );
          })}
        </ModalBody>
        <ModalFooter>
          <Flex>
            <Button
              leftIcon={<EmailIcon />}
              colorScheme="blue"
              mr={4}
              onClick={handleEmailShare}
            >
              Email
            </Button>
            <Button
              leftIcon={<MdWhatsapp />}
              colorScheme="green"
              onClick={handleWhatsAppShare}
            >
              WhatsApp
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OrderDetailModal;
