import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import React, { Fragment, useState } from "react";
import { createSupplier } from "services/supplier";
import { useAuth0 } from "@auth0/auth0-react";
import { CreateSupplierRequest } from "generated-client/model/create-supplier-request";
import { CloseIcon } from "@chakra-ui/icons";

interface SupplierModalProps {
  isOpen: boolean;
  onClose: () => void;
  updateRefresh: (value: boolean) => void;
}

const SupplierModal: React.FC<SupplierModalProps> = ({
  onClose,
  isOpen,
  updateRefresh,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [supplierPayload, setSupplierPayload] = useState<CreateSupplierRequest>(
    {
      name: "",
      friendlyName: "",
      bankName: "",
      accountNumber: "",
      mobilePayAccountName: "",
      mobilePayNumber: "",
      whatsappContact: "",
      country: "",
      address: "",
      companyRegistrationDate: "",
      email: "",
      managingDirector: "",
      mobileNumber: "",
    }
  );
  const [error, setError] = useState(null);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const countries = ["Rwanda", "Kenya"];

  const otherFields = [
    { key: "name", label: "Supplier Name" },
    { key: "category", label: "Supplier Category" },
    { key: "friendlyName", label: "Listing Name" },
    { key: "country", label: "Country" },
    { key: "address", label: "Address" },
    { key: "companyRegistrationDate", label: "Company Registration Date" },
    { key: "managingDirector", label: "Managing Director" },
    { key: "email", label: "Email" },
    { key: "whatsappContact", label: "Mobile Contact" },
  ];

  const sections = [
    {
      label: "Bank Account for Payments",
      fields: [
        { key: "bankName", label: "Bank Name" },
        { key: "accountNumber", label: "Account Number" },
      ],
    },
    {
      label: "Mobile Money for Payments",
      fields: [
        { key: "mobilePayAccountName", label: "Mobile Pay Account Name" },
        { key: "mobilePayNumber", label: "Mobile Pay Number" },
      ],
    },
  ];

  const handleValueChange = (
    key: keyof CreateSupplierRequest,
    value: string
  ) => {
    setSupplierPayload((prevPayload) => ({
      ...prevPayload,
      [key]: value,
    }));
  };

  const handleSubmit = async () => {
    setError("");
    if (!supplierPayload.name) {
      setError("Name can not be empty");
      return;
    }

    if (!supplierPayload.friendlyName) {
      setError("Friendly name can not be empty");
      return;
    }
    if (!supplierPayload.country) {
      setError("Country can not be empty");
      return;
    }

    if (
      supplierPayload.email &&
      !supplierPayload.email.match(
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setError("Email is invalid");
      return;
    }

    setIsSubmitLoading(true);

    const accessToken = await getAccessTokenSilently();

    await createSupplier(accessToken, supplierPayload);
    setIsSubmitLoading(false);
    onClose();
    updateRefresh(true);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex justifyContent="space-between" alignItems="center">
            <Text>Add New Supplier</Text>
            <CloseIcon onClick={onClose} />
          </Flex>
        </ModalHeader>
        <ModalBody>
          <FormControl>
            {otherFields.map((field) => {
              if (field.key === "country") {
                return (
                  <Fragment key={field.label}>
                    <FormLabel>{field.label}</FormLabel>
                    <Select
                      placeholder="Select country"
                      value={
                        supplierPayload[
                          field.key as keyof CreateSupplierRequest
                        ]
                      }
                      onChange={(e) =>
                        handleValueChange(
                          field.key as keyof CreateSupplierRequest,
                          e.target.value
                        )
                      }
                      mb={4}
                    >
                      {countries.map((country, index) => (
                        <option key={index} value={country}>
                          {country}
                        </option>
                      ))}
                    </Select>
                  </Fragment>
                );
              } else if (field.key === "category") {
                return (
                  <>
                    <FormLabel>{field.label}</FormLabel>
                    <Select
                      placeholder="Select category"
                      value={
                        supplierPayload[
                          field.key as keyof CreateSupplierRequest
                        ]
                      }
                      onChange={(e) =>
                        handleValueChange(
                          field.key as keyof CreateSupplierRequest,
                          e.target.value
                        )
                      }
                      mb={4}
                    >
                      {Object.values(CreateSupplierRequest.CategoryEnum).map(
                        (category, index) => (
                          <option key={index} value={category}>
                            {category
                              .replace(/_/g, " ")
                              .toLowerCase()
                              .replace(/\b[a-z]/g, (char) =>
                                char.toUpperCase()
                              )}
                          </option>
                        )
                      )}
                    </Select>
                  </>
                );
              } else {
                return (
                  <FormControl key={field.key}>
                    <FormLabel>{field.label}</FormLabel>
                    <Input
                      placeholder={field.label}
                      value={
                        supplierPayload[
                          field.key as keyof CreateSupplierRequest
                        ]
                      }
                      onChange={(e) =>
                        handleValueChange(
                          field.key as keyof CreateSupplierRequest,
                          e.target.value
                        )
                      }
                      mb={4}
                      type={
                        field.key === "email"
                          ? "email"
                          : field.key === "companyRegistrationDate"
                          ? "date"
                          : field.key === "mobileNumber" ||
                            field.key === "mobilePayNumber"
                          ? "tel"
                          : "text"
                      }
                    />
                  </FormControl>
                );
              }
            })}

            {sections.map((section, index) => (
              <FormControl key={index}>
                <FormLabel fontWeight="bold">{section.label}</FormLabel>
                {section.fields.map((field) => (
                  <FormControl key={field.key}>
                    <Input
                      placeholder={field.label}
                      value={
                        supplierPayload[
                          field.key as keyof CreateSupplierRequest
                        ]
                      }
                      onChange={(e) =>
                        handleValueChange(
                          field.key as keyof CreateSupplierRequest,
                          e.target.value
                        )
                      }
                      mb={4}
                    />
                  </FormControl>
                ))}
              </FormControl>
            ))}
          </FormControl>

          <Flex justifyContent="flex-start" mt={4}>
            {error && (
              <Text color="red.500" mt={2}>
                {error}
              </Text>
            )}
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex width="100%" justifyContent="center">
            <Button
              colorScheme="blue"
              onClick={handleSubmit}
              isLoading={isSubmitLoading}
              disabled={isSubmitLoading}
            >
              Add
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SupplierModal;
