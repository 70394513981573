import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  Text,
  Input,
  FormControl,
  FormLabel,
  Flex,
  Icon,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { PaymentRequestDto } from "../../../../generated-client/model/payment-request-dto";
import { CreatePaymentRequestRequest } from "generated-client/model/create-payment-request-request";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { triggerMobilePayRequest } from "lib/utilities";

interface MobilePayModalProps {
  isOpen: boolean;
  onClose: () => void;
  paymentRequest: Partial<PaymentRequestDto>;
  currency: string; // Add a prop for the amount
  onConfirm?: (
    paymentMethod: CreatePaymentRequestRequest.PaymentMethodEnum,
    file: File
  ) => void;
}

const MobilePayModal: React.FC<MobilePayModalProps> = ({
  currency,
  paymentRequest,
  onClose,
  isOpen,
  onConfirm,
}) => {
  const [payAmount, setPayAmount] = useState(paymentRequest.amountCredit);
  const [mobileNumber, setMobileNumber] = useState("+250");
  const [buttonText] = useState("Submit");
  const [requestSent, setRequestSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const toast = useToast();
  const { getAccessTokenSilently } = useAuth0();

  const handleConfirmClick = (file?: File) => {
    if (onConfirm) {
      onConfirm(CreatePaymentRequestRequest.PaymentMethodEnum.Transfer, file);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      const accessToken = await getAccessTokenSilently();
      await triggerMobilePayRequest({
        accessToken,
        amount: payAmount,
        currency,
        phoneNumber: mobileNumber,
      });
      setRequestSent(true);
      setTimeout(() => {
        onClose(); // This function should be responsible for closing the modal
        setRequestSent(false);
        setPayAmount(0);
        setIsLoading(false);
        handleConfirmClick();
      }, 5000);
    } catch (error: any) {
      if (error?.response?.data?.statusCode === 500) {
        toast({
          title: "Payment Error",
          description: t("mobile_pay_error"),
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: error?.response?.data?.message
            ? error?.response?.data?.message
            : t("error_occurred"),
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setPayAmount(paymentRequest.amountCredit);
  }, [paymentRequest]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        {!requestSent && (
          <ModalHeader textAlign={"center"}>
            Repay with Mobile Money
          </ModalHeader>
        )}
        <ModalBody>
          {!requestSent && (
            <VStack spacing={4} align="left">
              <Text textAlign="left">
                Amount due: {currency} {paymentRequest.amountCredit}
              </Text>
              <FormControl id="amount">
                <FormLabel>Enter amount to pay</FormLabel>
                <Input
                  type="number"
                  value={payAmount}
                  onChange={(e) => setPayAmount(parseFloat(e.target.value))}
                />
              </FormControl>
              <FormControl id="mobileNumber">
                <FormLabel>Enter your mobile number</FormLabel>
                <Input
                  type="tel"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                />
              </FormControl>
              <Text pt={"10px"} fontSize="sm">
                {t("mobile_pay_disclaimer_1")}
              </Text>
            </VStack>
          )}
          {requestSent && (
            <VStack spacing={4} align="center">
              <Icon
                as={CheckCircleIcon}
                mt={"30px"}
                boxSize={8}
                color="green.500"
              />
              <Text textAlign="center">
                Payment request sent to mobile number.
                <br />
                Once we receive your payment, status will be updated.
              </Text>
            </VStack>
          )}
        </ModalBody>
        <ModalFooter>
          {!requestSent && (
            <Flex width="100%" justifyContent="flex-end">
              <Button
                variant="ghost"
                onClick={() => {
                  onClose();
                }}
              >
                Close
              </Button>
              <Button
                colorScheme="blue"
                isLoading={isLoading}
                onClick={handleSubmit}
              >
                {buttonText}
              </Button>
            </Flex>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MobilePayModal;
