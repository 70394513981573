import {
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import Card from "../../../../components/card/Card";

import { SupplierDto } from "generated-client/model/supplier-dto";

interface TableComponentProps {
  suppliers: SupplierDto[];
  openEditSupplierModal: (supplierId: string) => void;
}

export default function TableComponent({
  suppliers,
  openEditSupplierModal,
}: TableComponentProps) {
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        w={{ base: "100%", md: "100%" }}
        mb={{ base: "0px", xl: "20px" }}
        minH="365px"
        pe="20px"
      >
        <Table variant="simple" size="md" fontSize="0.9em">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Category</Th>
              <Th>Date Listed</Th>
              <Th>Country</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {suppliers?.map((supplier) => (
              <Tr key={supplier.id}>
                <Td>
                  <Text>{supplier.friendlyName}</Text>
                </Td>
                <Td>
                  <Text>{supplier.category.replace(/_/g, ' ')
                      .toLowerCase()
                      .replace(/\b[a-z]/g, char => char.toUpperCase())}</Text>
                </Td>
                <Td>
                  <Text>
                    {new Date(supplier.createdAt).toLocaleDateString(
                      undefined,
                      {
                        year: "numeric",
                        month: "long",
                      }
                    )}
                  </Text>
                </Td>
                <Td>
                  <Text>{supplier.country}</Text>
                </Td>
                <Td>
                  <Button
                    colorScheme="blue"
                    onClick={() => openEditSupplierModal(supplier.id)}
                  >
                    View
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Card>
    </Box>
  );
}
