import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  ModalHeader,
  Flex,
  IconButton,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import React from "react";
import { CloseIcon } from "@chakra-ui/icons";
import { SourcingRequestProFormaInvoiceDto } from "../../../../../generated-client/model/sourcing-request-pro-forma-invoice-dto";
import { formatCurrency } from "../../../../../lib/utilities";

interface ShippingModalProps {
  isOpen: boolean;
  onClose: () => void;
  proformaInvoiceToPay: SourcingRequestProFormaInvoiceDto;
  handleActionPress: (isShippingEnabled: boolean) => void;
}

const ShippingModal: React.FC<ShippingModalProps> = ({
  onClose,
  isOpen,
  proformaInvoiceToPay,
  handleActionPress,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent overflowY="auto" maxH="50vh">
        <ModalHeader>
          <Flex align="center">
            <Text fontWeight="bold" fontSize="xl">
              Shipping
            </Text>
            <IconButton
              aria-label="Close"
              icon={<CloseIcon />}
              size="sm"
              ml="auto"
              onClick={onClose}
              color="gray.500"
              variant="ghost"
            />
          </Flex>
        </ModalHeader>
        <ModalBody>
          <>
            <Text>
              To utilize Flex pay you are required to use our freight forwarding
              partners.
            </Text>

            <Flex
              mt={4}
              mb={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Text fontWeight="medium">Shipping estimate</Text>
              <Text align="right" fontWeight="bold">
                {proformaInvoiceToPay.totalAmountLocalCurrency}{" "}
                {formatCurrency(proformaInvoiceToPay.shippingEstimateAmount)}
              </Text>
            </Flex>
            <Flex alignItems="center" justifyContent="space-between">
              <Text fontWeight="medium">Estimated arrival date</Text>
              <Text fontWeight="bold">
                {new Date(
                  proformaInvoiceToPay.shippingArrivalDate
                ).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </Text>
            </Flex>
          </>
        </ModalBody>
        <ModalFooter>
          <Flex gap={4}>
            <Button
              colorScheme="gray"
              onClick={() => handleActionPress(false)}
              size="sm"
            >
              Skip
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => handleActionPress(true)}
              size="sm"
            >
              Add shipping
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ShippingModal;
