import { callGet, callPost, callUpdate } from "../../lib/api-private";
import { GetCompanyResponse } from "../../generated-client/model/get-company-response";
import { UpdateCompanyRequest } from "../../generated-client/model/update-company-request";
import { UpdateCompanyKycStatusRequest } from "../../generated-client/model/update-company-kyc-status-request";
import { GetAllCompaniesWithKycResponse } from "../../generated-client/model/get-all-companies-with-kyc-response";
import { UpdateCompanyCreditLimitRequest } from "../../generated-client/model/update-company-credit-limit-request";
import { GetAllCompaniesRequest } from "../../generated-client/model/get-all-companies-request";
import { UpdateCompanyLatestPaymentDatetRequest } from "generated-client/model/update-company-latest-payment-datet-request";

interface CacheEntry {
  timestamp: number | null;
  data: Promise<GetCompanyResponse> | null;
}

const cache: {
  company: CacheEntry;
} = {
  company: {
    timestamp: null,
    data: null
  }
};

export const getCompany = async (accessToken: string): Promise<GetCompanyResponse> => {
  const currentTime = Date.now();

  // Check if a valid cache entry exists and if it's within 3 seconds
  if (cache.company.timestamp !== null && (currentTime - cache.company.timestamp < 5000) && cache.company.data !== null) {
    return await cache.company.data;  // Await and return the promise from cache
  }

  // If no valid cache or cache is expired, create a new promise to fetch data
  const dataPromise = callGet(accessToken, "/company");
  cache.company = {
    timestamp: currentTime,
    data: dataPromise
  };

  // Return the resolved data
  return await dataPromise;
};

export const getAllCompaniesWithKycAdmin = async (
  accessToken: string,
  data: GetAllCompaniesRequest
): Promise<GetAllCompaniesWithKycResponse> => {
  const response = await callPost(accessToken, "/company/all", data);
  return response;
};

export const updateCompany = async (
  accessToken: string,
  data: UpdateCompanyRequest
): Promise<void> => {
  await callUpdate(accessToken, "/company", data);
};

export const setKycDocumentsSubmitted = async (
  accessToken: string
): Promise<void> => {
  callUpdate(accessToken, "/company/kyc-docs-submitted", {});
};

export const updateCompanyKycStatusAdmin = async (
  accessToken: string,
  data: UpdateCompanyKycStatusRequest
): Promise<void> => {
  callUpdate(accessToken, "/company/kyc-status", data);
};

export const updateCompanyLatestPaymentDateAdmin = async (
  accessToken: string,
  data: UpdateCompanyLatestPaymentDatetRequest
) => {
  return await callUpdate(accessToken, "/company/latest-payment-date", data);
};

export const updateCompanyCreditLimitAdmin = async (
  accessToken: string,
  data: UpdateCompanyCreditLimitRequest
) => {
  return callUpdate(accessToken, "/company/credit-limit", data);
};

export const activateCompanyTrial = async (
    accessToken: string,
) => {
  return callUpdate(accessToken, "/company/trial/activate", { id: 'hello world' });
};
