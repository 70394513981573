import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  Text,
  Icon,
  Flex,
} from "@chakra-ui/react";
import { CheckCircleIcon, TimeIcon } from "@chakra-ui/icons"; // Import the CheckCircleIcon
import React from "react";
import { useTranslation } from "react-i18next";

interface SuccessModalProps {
  isOpen: boolean;
  isPending: boolean;
  onClose: () => void;
}

const SuccessModal: React.FC<SuccessModalProps> = ({
  onClose,
  isPending,
  isOpen,
}) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalBody>
          <VStack spacing={4} align="center">
            {!isPending ? (
              <>
                <Icon as={CheckCircleIcon} boxSize={8} color="green.500" />
                <Text textAlign="center">
                  {t("order_submitted")}
                  <br />
                  {t("processing_order")}
                </Text>
              </>
            ) : (
              <>
                <Icon as={TimeIcon} boxSize={8} color="yellow.500" />
                <Text textAlign="center">
                  {t("order_pending")}
                  <br />
                  {t("to_complete_order")}
                </Text>
              </>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Flex width="100%" justifyContent="center">
            {" "}
            {/* Use Flex to center the button */}
            <Button colorScheme="blue" onClick={onClose}>
              {t("close")}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SuccessModal;
