/**
 * Symplifi   API
 * Symplifi backend API description 
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SourcingItemDto { 
    id?: string;
    name: string;
    quantity: number;
    category: SourcingItemDto.CategoryEnum;
}
export namespace SourcingItemDto {
    export type CategoryEnum = 'CONSTRUCTION_MATERIALS' | 'ELECTRONICS';
    export const CategoryEnum = {
        ConstructionMaterials: 'CONSTRUCTION_MATERIALS' as CategoryEnum,
        Electronics: 'ELECTRONICS' as CategoryEnum
    };
}


