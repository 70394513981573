import {
    Box, Button, Text, FormControl, Flex, Divider, Td
} from "@chakra-ui/react";
import Card from "../../../../components/card/Card";
import { formatCurrency } from "../../../../lib/utilities";
import React, { useRef, useState } from "react";
import { DirectPaymentDto } from "../../../../generated-client/model/direct-payment-dto";
import { downloadFile } from "services/file";
import { UploadFileRequest } from "../../../../generated-client/model/upload-file-request";
import TypeEnum = UploadFileRequest.TypeEnum;
import PaymentDetailsModal from "./PaymentDetails";
import {getPaymentRequest} from "../../../../services/payment-request";
import FlexPayPaymentDetailsModal from "./FlexPayPaymentDetails";

interface TableComponentProps {
    directPayments: DirectPaymentDto[];
    accessToken: string;
    sendFile: (file: File, directPaymentId: string) => void;
    fetchData: () => void;
}

export default function CardLayout({ directPayments, accessToken, sendFile, fetchData }: TableComponentProps) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [loadingStates, setLoadingStates] = useState<{ [key: string]: boolean }>({});
    const [currentDirectPaymentId, setCurrentDirectPaymentId] = useState<string | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);  // New state to manage modal visibility
    const [modalPayment, setModalPayment] = useState<DirectPaymentDto>(null);  // New state to manage the payment details for the modal
    const [modalPaymentRequest, setModalPaymentRequest] = useState(null);  // New state to manage the payment details for the modal
    const [isFlexPayModalOpen, setIsFlexPayModalOpen] = useState(false);

    const toggleFlexPayModal = async (payment?: DirectPaymentDto) => {
        if(payment?.paymentRequestId) {
            const paymentRequest = await getPaymentRequest(accessToken, payment.paymentRequestId);
            setModalPaymentRequest(paymentRequest.paymentRequest);
        }
        setIsFlexPayModalOpen(prevState => !prevState);
    }

    const toggleModal = (payment: DirectPaymentDto) => {
        setModalPayment(payment);
        setIsModalOpen(prevState => !prevState);
    }

    let fileInputRef = useRef(null);

    const setRowLoading = (id: string, isLoading: boolean) => {
        setLoadingStates(prev => ({ ...prev, [id]: isLoading }));
    };

    const handleFileUpload = async (file: File, directPaymentId: string) => {
        setRowLoading(directPaymentId, true);
        await sendFile(file, directPaymentId);
        setRowLoading(directPaymentId, false);
        window.location.reload();
    };

    const handleFileChange = (event: any) => {
        if (!currentDirectPaymentId) return;
        const file = event.target.files[0];
        handleFileUpload(file, currentDirectPaymentId);
    }

    const triggerFileInput = (directPaymentId: string) => {
        setCurrentDirectPaymentId(directPaymentId);
        fileInputRef.current.click();
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            {directPayments?.map((payment) => (
                <Box
                    key={payment.id}
                    bg="white"
                    p={5}
                    shadow="md"
                    borderWidth="1px"
                    mb={4}
                >
                    <Text fontSize="xl">{payment.recipientName}</Text>

                    {/* Info section */}
                    <Box mb={4} fontSize="0.9em">
                        <Text>Address: {payment.recipientAddress}</Text>
                        <Text>Account Number: {payment.recipientAccountNumber}</Text>
                        <Text>Bank Swift code: {payment.recipientSwiftCode}</Text>
                        <Text>Country: {payment.country}</Text>
                        <br/>
                        <Text>Amount: {'$ ' + formatCurrency(payment.amount)}</Text>
                        <Text>Pay now: {payment.amountToPayCurrency + ' ' + formatCurrency(payment.amountToPay)}</Text>
                        <Box
                            onClick={() => toggleModal(payment)}  // New box to open the modal with payment details
                            mr={2}  // Margin to separate the buttons
                            cursor="pointer"  // Change cursor to pointer on hover
                            display="flex"  // Use flex to align the text and icon
                            alignItems="center"  // Align items to the center vertically
                        >
                            <Text fontSize="sm" color='grey'>
                                Payment account details
                            </Text>
                        </Box>
                        {/*<Text>Exchange rate: {'RWF ' + payment.exchangeRate}</Text>*/}
                        {payment.paymentRequestId && (
                            <>
                                <br/>
                                <Text>Flex pay: {payment.paymentRequestCreditAmountCurrency + ' ' + formatCurrency(payment.paymentRequestCreditAmount)}</Text>
                                <Box
                                    onClick={() => toggleFlexPayModal(payment)}  // New box to open the modal with payment details
                                    mr={2}  // Margin to separate the buttons
                                    cursor="pointer"  // Change cursor to pointer on hover
                                    display="flex"  // Use flex to align the text and icon
                                    alignItems="center"  // Align items to the center vertically
                                >
                                    <Text fontSize="sm" color='grey'>
                                        Flex pay repayment details
                                    </Text>
                                </Box>
                                <br/>
                            </>
                        )}
                        {/*<Divider mb={2} />*/}
                        <Text>Status: {
                            payment.status === 'PROOF_OF_USER_PAYMENT_SUBMITTED' || payment.status === 'PROOF_OF_PLATFORM_PAYMENT_SUBMITTED' ?
                                'PENDING' : payment.status
                        }</Text>
                    </Box>

                    {/* Files section */}
                    <Box mb={4}>
                        <Text mb={2}>Files:</Text>
                        {payment.files?.map((file, index) => (
                            <Button fontSize="sm" color='grey' key={index} onClick={() => downloadFile(file.key, accessToken)}>
                                {file.type === 'PROOF_OF_PLATFORM_PAYMENT' ? 'PROOF_OF_PAYMENT' : file.type}
                            </Button>
                        ))}
                    </Box>
                </Box>
            ))}
            {modalPayment && (
                <PaymentDetailsModal
                    isOpen={isModalOpen}
                    paymentMethod={modalPayment.paymentMethod}
                    amount={modalPayment.amountToPay}
                    currency={modalPayment.amountToPayCurrency}
                    onClose={() => toggleModal(null)}
                />
            )}
            {modalPaymentRequest && (
                <FlexPayPaymentDetailsModal
                    isOpen={isFlexPayModalOpen}
                    paymentRequest={modalPaymentRequest}
                    onClose={() => toggleFlexPayModal(null)}
                />
            )}
        </Box>
    );
}
