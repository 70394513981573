/**
 * Symplifi   API
 * Symplifi backend API description 
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ExchangeRateDto { 
    id: string;
    from: ExchangeRateDto.FromEnum;
    to: ExchangeRateDto.ToEnum;
    value: number;
}
export namespace ExchangeRateDto {
    export type FromEnum = 'USD' | 'KES' | 'KES_SUPPLIER' | 'KES_OTHER' | 'RWF' | 'AED' | 'CNY';
    export const FromEnum = {
        Usd: 'USD' as FromEnum,
        Kes: 'KES' as FromEnum,
        KesSupplier: 'KES_SUPPLIER' as FromEnum,
        KesOther: 'KES_OTHER' as FromEnum,
        Rwf: 'RWF' as FromEnum,
        Aed: 'AED' as FromEnum,
        Cny: 'CNY' as FromEnum
    };
    export type ToEnum = 'USD' | 'KES' | 'KES_SUPPLIER' | 'KES_OTHER' | 'RWF' | 'AED' | 'CNY';
    export const ToEnum = {
        Usd: 'USD' as ToEnum,
        Kes: 'KES' as ToEnum,
        KesSupplier: 'KES_SUPPLIER' as ToEnum,
        KesOther: 'KES_OTHER' as ToEnum,
        Rwf: 'RWF' as ToEnum,
        Aed: 'AED' as ToEnum,
        Cny: 'CNY' as ToEnum
    };
}


