import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { SourcingRequestDto } from "generated-client/model/sourcing-request-dto";
import { getAllSourcingRequestsAdmin } from "services/sourcing";
import { GetAllSourcingRequestsRequest } from "generated-client/model/get-all-sourcing-requests-request";

export const useFetchSourcingRequests = () => {
  const [sourcingRequests, setSourcingRequests] = useState<
    SourcingRequestDto[]
  >([]);
  const [accessToken, setAccessToken] = useState<string>("");
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  const fetchData = async ({
    skip = 0,
    take = 20,
    country,
    companyNameSearch,
  }: {
    skip?: number;
    take?: number;
    country?: GetAllSourcingRequestsRequest.CountryEnum;
    companyNameSearch?: string;
  }) => {
    try {
      const accessToken = await getAccessTokenSilently();
      setAccessToken(accessToken);
      const data = await getAllSourcingRequestsAdmin(accessToken, {
        skip,
        take,
        companyNameSearch,
        country,
      });
      setSourcingRequests(data.sourcingRequests);
    } catch (error) {
      console.error("Error fetching sourcing requests:", error);
    }
  };

  useEffect(() => {
    if (isLoading || !isAuthenticated) return;

    fetchData({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated, getAccessTokenSilently]);

  return { sourcingRequests, accessToken, fetchData };
};
