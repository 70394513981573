import {
  Box,
  VStack,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Input,
  Select,
  Flex,
  Checkbox,
  Button,
  FormControl,
  InputGroup,
  InputLeftAddon,
  RadioGroup,
  Radio,
  Divider,
  FormLabel,
  useBreakpointValue,
} from "@chakra-ui/react";

import { useEffect, useRef, useState } from "react";
import Card from "components/card/Card";
import { useAuth0 } from "@auth0/auth0-react";
import ConfirmationModal from "./components/ConfirmationModal";
import { createPaymentRequest } from "../../../services/payment-request";
import { createDirectPayment } from "../../../services/direct-payment";
import { CreateDirectPaymentRequest } from "../../../generated-client/model/create-direct-payment-request";
import ProofOfUserPaymentModal from "./components/ProofOfUserPaymentModal";
import { useNavigate } from "react-router-dom";
import { uploadFile } from "../../../services/file";
import {
  formatCurrency,
  formatCurrencyWithTwoDecimals,
} from "../../../lib/utilities";
import { GetStartedDirectPayments } from "./components/GetStarted";
import { CompanyDto } from "../../../generated-client/model/company-dto";
import { getCompany, updateCompany } from "../../../services/company";
import KycStatusEnum = CompanyDto.KycStatusEnum;
import { getUser } from "../../../services/user";
import { UserDto } from "../../../generated-client/model/user-dto";
import RoleEnum = UserDto.RoleEnum;
import { getExchangeRate } from "../../../services/finance";
import { ExchangeRateDto } from "../../../generated-client/model/exchange-rate-dto";
import { DirectPaymentDto } from "../../../generated-client/model/direct-payment-dto";
import AmountCurrencyEnum = DirectPaymentDto.AmountCurrencyEnum;
import { RepaymentTerms } from "./components/RepaymentsTerms";
import { CreatePaymentRequestRequest } from "../../../generated-client/model/create-payment-request-request";
import { getPricingPlans } from "services/pricing/pricing";
import { PricingPlanDto } from "generated-client/model/pricing-plan-dto";

const flexPayBankDetails = [
  "Account Name: MAMLAKA HUB & SPOKE LIMITED",
  "Bank account number: 55010160018203",
  "Bank/Branch: UBA Kenya Bank Limited, Westland’s Branch",
  "Swift code: UNAFKENA",
];

const mPesaDetails = [
  "Paybill Number: 559900",
  "Account number: 55010160018203",
];

export type RepaymentTermsType = {
  creditAmount: number;
  totalRepay: number;
  balanceToPayNow: number;
  transactionFee: number;
  repaymentSchedule: any[];
};

export default function InternationalTransferPage() {
  const navigate = useNavigate();

  const [recipientCountry, setRecipientCountry] = useState<string>("");
  const [amountToSend, setAmountToSend] = useState<number>(0);
  const [creditLimit, setCreditLimit] = useState<number>(0);
  const [recipientName, setRecipientName] = useState<string>("");
  const [accountNumber, setAccountNumber] = useState<string>("");
  const [swiftCode, setSwiftCode] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [recipientAddress, setRecipientAddress] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [companyData, setCompanyData] = useState<CompanyDto>();
  const [paymentType, setPaymentType] = useState<string>("bankTransfer");
  const [activeTab, setActiveTab] = useState<string>("supplier");
  const [selectedFile, setSelectedFile] = useState(null);
  const [fxRateSupplier, setFxRateSupplier] = useState<number>(0);
  const [fxRateOther, setFxRateOther] = useState<number>(0);
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [, setIsUserAdmin] = useState(false);
  const [currency, setCurrency] = useState("");
  const fileInputRef = useRef(null);
  const [formError, setFormError] = useState<string | null>(null);
  const [creditAmountError, setCreditAmountError] = useState<string | null>(
    null
  );
  const [amountInputError, setAmountInputError] = useState<string | null>(null);
  const amountInputRefSupplier = useRef(null);
  const amountInputRefOtherRecipient = useRef(null);
  const [showFlexPayForm, setShowFlexPayForm] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [creditAmount, setCreditAmount] = useState<number>(0);
  const [duration, setDuration] = useState("1month");
  const [paymentMethod, setPaymentMethod] =
    useState<CreateDirectPaymentRequest.PaymentMethodEnum>(null);
  const [repaymentTerms, setRepaymentTerms] = useState<RepaymentTermsType>({
    creditAmount: 0,
    totalRepay: 0,
    balanceToPayNow: 0,
    transactionFee: 0,
    repaymentSchedule: [],
  });
  const [selectedPriceId, setSelectedPriceId] = useState(null);
  const [plans, setPlans] = useState<PricingPlanDto[]>([]);
  const [isPayModalOpen, setIsPayModalOpen] = useState(false);
  const [usdToRwf, setUsdToRwf] = useState<number>(0);
  const [isSubscribeLoading, setIsSubscribeLoading] = useState(false);

  useEffect(() => {
    const terms = calculateRepaymentTerms(creditAmount, duration);
    setRepaymentTerms(terms as RepaymentTermsType);
  }, [creditAmount, duration, amountToSend]);

  const validateForm = () => {
    if (
      !recipientCountry ||
      amountToSend <= 0 ||
      !recipientName ||
      !accountNumber ||
      !recipientAddress ||
      !swiftCode ||
      !phoneNumber
    ) {
      setFormError("All fields must be filled out");
      return false;
    }

    if (swiftCode.length < 8) {
      setFormError("Swift code must be at least 8 characters long");
      return false;
    }

    if (
      activeTab === "supplier" &&
      recipientCountry !== "Kenya" &&
      !selectedFile
    ) {
      setFormError("Invoice is required");
      return false;
    }
    if (activeTab === "other" && amountToSend > 100000) {
      setFormError("Amount cannot be higher than $100,000");
      return false;
    }

    if (activeTab !== "other" && paymentType === "flexPay" && !termsAccepted) {
      setFormError("Terms must be accepted");
      return false;
    }

    if (
      activeTab !== "other" &&
      paymentType === "flexPay" &&
      creditAmount <= 0
    ) {
      setFormError("Credit amount must be bigger than 0");
      return false;
    }

    if (activeTab !== "other" && paymentType === "flexPay") {
      if (
        creditAmount > creditLimit ||
        creditAmount >
          amountToSend *
            (activeTab === "supplier" ? fxRateSupplier : fxRateOther)
      ) {
        setFormError("Credit amount too high");
        return false;
      }
      if (creditAmount <= 0) {
        setFormError("Credit amount must be bigger than 0");
        return false;
      }
      // if (creditAmount > repaymentTerms.balanceToPayNow) {
      // 	setFormError("Credit amount cannot be higher than the balance to pay now");
      // 	return false;
      // }
    }

    setFormError(null);
    return true;
  };

  const validateAmountInput = (val: number) => {
    if (activeTab === "other" && val > 100000) {
      setAmountInputError("Amount cannot be higher than $100,000");
      return false;
    }
    setAmountInputError(null);
    return true;
  };

  const validateCreditAmountInput = (val: number) => {
    if (val > creditLimit) {
      setCreditAmountError("Amount cannot be higher than credit limit");
      return false;
    }
    setCreditAmountError(null);
    return true;
  };

  const openReviewModal = () => {
    if (validateForm()) {
      setIsOpen(true);
    }
  };

  async function fetchData() {
    try {
      const accessToken = await getAccessTokenSilently();
      const data = await getCompany(accessToken);
      const user = await getUser(accessToken);
      const fxRateSupplier = await getExchangeRate(
        accessToken,
        ExchangeRateDto.FromEnum.Usd,
        ExchangeRateDto.ToEnum.KesSupplier
      );
      const fxRateOther = await getExchangeRate(
        accessToken,
        ExchangeRateDto.FromEnum.Usd,
        ExchangeRateDto.ToEnum.KesOther
      );
      const fxRateRwf = await getExchangeRate(
        accessToken,
        ExchangeRateDto.FromEnum.Usd,
        ExchangeRateDto.ToEnum.Rwf
      );
      const { pricingPlans } = await getPricingPlans(accessToken);
      setPlans(pricingPlans);
      if (user.user.role === RoleEnum.Admin) {
        setIsUserAdmin(true);
      }
      setCompanyData(data.company);
      setFxRateOther(fxRateOther.exchangeRate.value);
      setFxRateSupplier(fxRateSupplier.exchangeRate.value);
      setUsdToRwf(fxRateRwf.exchangeRate.value);
      setCurrency(
        data.company.country === "Kenya"
          ? AmountCurrencyEnum.Kes
          : AmountCurrencyEnum.Rwf
      );
      setCreditLimit(data.company.creditInformation?.creditLimit || 0);
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  }

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (isAuthenticated) {
      fetchData();
    }
  }, [isLoading, isAuthenticated]);

  const finalSubmit = async (
    paymentMethod: CreateDirectPaymentRequest.PaymentMethodEnum
  ) => {
    // setIsOpen(false); // Close the modal
    setFormError(null);
    setPaymentMethod(paymentMethod);

    try {
      const accessToken = await getAccessTokenSilently();

      let createdPaymentRequest;
      if (repaymentTerms.creditAmount > 0) {
        createdPaymentRequest = await createPaymentRequest(accessToken, {
          amount: repaymentTerms.creditAmount,
          amountCurrency:
            currency as CreatePaymentRequestRequest.AmountCurrencyEnum,
          amountInSecondaryCurrency: 0,
          amountInSecondaryCurrencyCurrency:
            currency as CreatePaymentRequestRequest.AmountCurrencyEnum,
          amountCredit: repaymentTerms.creditAmount,
          durationInDays:
            duration === "1month" ? 31 : duration === "1months" ? 61 : 91,
          items: [],
          type: CreatePaymentRequestRequest.TypeEnum.FlexPay,
          paymentMethod: CreatePaymentRequestRequest.PaymentMethodEnum.Transfer,
          supplierName: recipientName + " (" + accountNumber + ")",
          supplierId: null,
          termsAccepted: true,
        });
      }

      let type =
        activeTab === "supplier"
          ? CreateDirectPaymentRequest.TypeEnum.PayNowSupplier
          : CreateDirectPaymentRequest.TypeEnum.PayNowOther;
      if (paymentType === "flexPay") {
        type = CreateDirectPaymentRequest.TypeEnum.FlexPay;
      }

      const createdDirectPayment = await createDirectPayment(accessToken, {
        country: recipientCountry,
        amount: amountToSend,
        amountCurrency: "USD",
        exchangeRate: activeTab === "supplier" ? fxRateSupplier : fxRateOther,
        amountToPay:
          amountToSend *
          (activeTab === "supplier" ? fxRateSupplier : fxRateOther),
        amountToPayCurrency: currency as AmountCurrencyEnum,
        recipientName: recipientName,
        recipientAccountNumber: accountNumber,
        recipientSwiftCode: swiftCode,
        recipientPhoneNumber: phoneNumber,
        recipientAddress: recipientAddress,
        type,
        termsAccepted: termsAccepted,
        fileId: null,
        paymentMethod,
        paymentRequestId: createdPaymentRequest?.paymentRequestId,
      });

      if (selectedFile) {
        await uploadFile(
          accessToken,
          {
            directPaymentId: createdDirectPayment.directPayment.id,
            type: "INVOICE",
          },
          selectedFile
        );
      }

      setIsOpen(false); // Close the modal
      if (repaymentTerms.balanceToPayNow === 0) {
        setIsSuccessModalOpen(true);
      } else {
        navigate("/dashboard/myUsdPayments");
      }
    } catch (err) {
      setIsOpen(false); // Close the modal
      setFormError("Failed to submit the payment request. Please try again.");
    } finally {
    }
  };

  const calculateRepaymentTerms = (amount: number, duration: string) => {
    let feePercentage;
    let months;

    switch (duration) {
      case "1month":
        feePercentage = 0.03;
        months = 1;
        break;
      case "2months":
        feePercentage = 0.06;
        months = 2;
        break;
      case "3months":
        feePercentage = 0.09;
        months = 3;
        break;
      default:
        return {
          /* default values */
        };
    }

    const transactionFee = amount * feePercentage;
    const totalRepay = amount + transactionFee;
    const repaymentSchedule = createRepaymentSchedule(totalRepay, months);
    const balanceToPayNow =
      amountToSend * (activeTab === "supplier" ? fxRateSupplier : fxRateOther) -
      amount;

    return {
      creditAmount: amount,
      totalRepay,
      transactionFee,
      balanceToPayNow: balanceToPayNow > 0 ? balanceToPayNow : 0,
      repaymentSchedule,
    };
  };

  const createRepaymentSchedule = (totalAmount: number, months: number) => {
    let schedule = [];
    const monthlyRepayment = Math.floor((totalAmount / months) * 100) / 100; // Round down to 2 decimal places
    let totalPaid = 0;
    let repaymentDate = new Date(); // Starting from today

    for (let i = 0; i < months; i++) {
      repaymentDate.setMonth(repaymentDate.getMonth() + 1); // Increment month
      let amountToPay = monthlyRepayment;

      // For the last payment, add any remaining balance
      if (i === months - 1) {
        amountToPay = totalAmount - totalPaid;
      }

      schedule.push({
        date: repaymentDate.toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }),
        amount: amountToPay,
      });

      totalPaid += amountToPay;
    }

    return schedule;
  };

  const closeReviewModal = () => {
    setIsOpen(false);
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleTabChange = (index: string) => {
    setActiveTab(index);
    setFormError(null);
    setPaymentType("bankTransfer");

    // Clear the state values here
    setRecipientCountry("");
    setAmountToSend(0);
    if (amountInputRefOtherRecipient.current) {
      amountInputRefOtherRecipient.current.value = ""; // Directly set the input's value to empty
    }
    if (amountInputRefSupplier.current) {
      amountInputRefSupplier.current.value = ""; // Directly set the input's value to empty
    }
    setRecipientName("");
    setAccountNumber("");
    setPhoneNumber("");
    setSwiftCode("");
    setRecipientAddress("");
    setSelectedFile(null);
    setCreditAmount(0);
    setShowFlexPayForm(false);
  };

  const handleUpdateCompany = async (pricingPlanId: string) => {
    const accessToken = await getAccessTokenSilently();
    await updateCompany(accessToken, {
      pricingPlanId,
    });
  };

  if (!companyData) {
    return <div>Loading...</div>; // or return a loading spinner component
  }

  // Check if the kycStatus is PENDING
  if (
    companyData.kycStatus === KycStatusEnum.Pending ||
    companyData.kycStatus === KycStatusEnum.Declined
  ) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Card
          w={{ base: "100%", md: "50%" }}
          mb={{ base: "0px", "2xl": "20px" }}
          gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
          minH="365px"
          pe="20px"
        >
          <Text fontSize="xl" fontWeight="bold" mb={4}>
            {"International"}
          </Text>
          {companyData.kycStatus === KycStatusEnum.Pending && (
            <Text mb={4}>
              {
                "Your documents are being reviewed. We will notify you when your account has been activated."
              }
            </Text>
          )}
          {companyData.kycStatus === KycStatusEnum.Declined && (
            <Text mb={4}>
              {
                "Your documents have been declined. Please contact us for more details."
              }
            </Text>
          )}
        </Card>
      </Box>
    );
  }

  if (companyData.kycStatus !== KycStatusEnum.Approved) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Card
          w={{ base: "100%", md: "100%" }}
          mb={{ base: "0px", "2xl": "20px" }}
          gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
          minH="365px"
          pe="20px"
        >
          <GetStartedDirectPayments
            onContinue={() => {
              navigate("/dashboard/kyc");
            }}
          />
        </Card>
      </Box>
    );
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        w={{ base: "100%", md: "90%", xl: "70%" }}
        mb={{ base: "0px", "2xl": "20px" }}
        gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
        minH="365px"
        pe="20px"
      >
        <Text fontSize="xl" fontWeight="bold" mb={4}>
          International
        </Text>

        <Tabs
          onChange={(index) =>
            handleTabChange(index === 0 ? "supplier" : "other")
          }
        >
          <TabList>
            <Tab>Pay a supplier</Tab>
            {/*<Tab>Other recipient</Tab>*/}
          </TabList>

          <TabPanels>
            <TabPanel>
              <VStack spacing={4} align="start">
                <Text mt={6} mb={2} fontWeight="bold">
                  Payment details
                </Text>
                <FormControl>
                  <Text mb={2} fontSize="sm" color="gray.500">
                    Recipient country
                  </Text>
                  <Select
                    value={recipientCountry}
                    placeholder="Select recipient country"
                    w={["100%", "100%", "85%", "45%"]}
                    onChange={(e) => setRecipientCountry(e.target.value)}
                  >
                    {/* Add countries here */}
                    <option value="Australia">Australia</option>
                    <option value="Belgium">Belgium</option>
                    <option value="China">China</option>
                    <option value="Egypt">Egypt</option>
                    <option value="France">France</option>
                    <option value="Germany">Germany</option>
                    <option value="India">India</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="Italy">Italy</option>
                    <option value="Japan">Japan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Malaysia">Malaysia</option>
                    <option value="Netherlands">Netherlands</option>
                    <option value="Oman">Oman</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="South Africa">South Africa</option>
                    <option value="South Korea">South Korea</option>
                    <option value="Spain">Spain</option>
                    <option value="Tanzania">Tanzania</option>
                    <option value="Thailand">Thailand</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Uganda">Uganda</option>
                    <option value="United Arab Emirates">
                      United Arab Emirates
                    </option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="United States">United States</option>
                  </Select>
                </FormControl>

                <FormControl>
                  <Text mb={2} fontSize="sm" color="gray.500">
                    Enter amount
                  </Text>
                  <InputGroup w={["100%", "100%", "85%", "45%"]}>
                    <InputLeftAddon children="$" />
                    <Input
                      placeholder="0"
                      ref={amountInputRefSupplier}
                      onBlur={(e) =>
                        setAmountToSend(
                          e.target.value ? parseFloat(e.target.value) : 0
                        )
                      }
                      type="number"
                    />
                  </InputGroup>
                </FormControl>

                <Box
                  p={4}
                  border="1px"
                  borderRadius="md"
                  borderColor="gray.200"
                  w={["100%", "100%", "80%", "60%"]}
                >
                  <Flex justifyContent="space-between" mb={2}>
                    <Text fontWeight="bold" color="gray.700">
                      FX rate
                    </Text>
                    <Text textAlign="right">
                      $1 = {currency}{" "}
                      {formatCurrencyWithTwoDecimals(fxRateSupplier)}
                    </Text>
                  </Flex>
                  <Flex justifyContent="space-between" mb={2}>
                    <Text fontWeight="bold" color="gray.700">
                      You pay
                    </Text>
                    <Text textAlign="right">
                      {currency} {formatCurrency(amountToSend * fxRateSupplier)}
                    </Text>
                  </Flex>
                  <Flex justifyContent="space-between">
                    <Text fontWeight="bold" color="gray.700">
                      Estimated delivery
                    </Text>
                    <Text textAlign="right">48 hours</Text>
                  </Flex>
                </Box>

                <Text fontWeight="bold" mb={2}>
                  Enter recipient bank details
                </Text>

                <FormControl>
                  <Text mb={2} fontSize="sm" color="gray.500">
                    Name
                  </Text>
                  <Input
                    value={recipientName}
                    w={["100%", "100%", "85%", "45%"]}
                    onChange={(e) => setRecipientName(e.target.value)}
                  />
                </FormControl>

                <FormControl>
                  <Text mb={2} fontSize="sm" color="gray.500">
                    Address
                  </Text>
                  <Input
                    value={recipientAddress}
                    w={["100%", "100%", "85%", "45%"]}
                    onChange={(e) => setRecipientAddress(e.target.value)}
                  />
                </FormControl>

                <FormControl>
                  <Text mb={2} fontSize="sm" color="gray.500">
                    USD Account number
                  </Text>
                  <Input
                    value={accountNumber}
                    w={["100%", "100%", "85%", "45%"]}
                    onChange={(e) => setAccountNumber(e.target.value)}
                  />
                </FormControl>

                <FormControl>
                  <Text mb={2} fontSize="sm" color="gray.500">
                    Bank swift code
                  </Text>
                  <Input
                    value={swiftCode}
                    w={["100%", "100%", "85%", "45%"]}
                    onChange={(e) => setSwiftCode(e.target.value)}
                  />
                </FormControl>

                <FormControl>
                  <Text mb={2} fontSize="sm" color="gray.500">
                    Recipient phone number (include country code)
                  </Text>
                  <Input
                    value={phoneNumber}
                    w={["100%", "100%", "85%", "45%"]}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </FormControl>

                {/*<Checkbox isChecked={saveDetails} onChange={(e) => setSaveDetails(e.target.checked)}>*/}
                {/*	Save*/}
                {/*</Checkbox>*/}

                <FormControl>
                  <Text mb={2} fontSize="sm" color="gray.500">
                    Upload invoice
                  </Text>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    accept="image/*, .pdf, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .jpg, .png, .jpeg"
                  />
                  {selectedFile ? (
                    <Text fontSize="sm">{selectedFile.name}</Text>
                  ) : (
                    <Button
                      onClick={triggerFileInput}
                      style={{ backgroundColor: "#e1e1e1" }}
                    >
                      <Text fontSize="sm" color="grey">
                        Choose file
                      </Text>
                    </Button>
                  )}
                </FormControl>

                <Text fontWeight="bold" mb={2}>
                  Fund payment
                </Text>
                <RadioGroup
                  onChange={(value) => {
                    setPaymentType(value);
                    setCreditAmount(0);
                    setRepaymentTerms({
                      creditAmount: 0,
                      totalRepay: 0,
                      balanceToPayNow: 0,
                      transactionFee: 0,
                      repaymentSchedule: [],
                    } as RepaymentTermsType);
                    setShowFlexPayForm(value === "flexPay");
                  }}
                  value={paymentType}
                >
                  <VStack spacing={4} align="stretch">
                    <Box
                      p={4}
                      border="1px"
                      borderRadius="md"
                      borderColor="gray.200"
                    >
                      <Radio value="bankTransfer" alignSelf="flex-start">
                        {" "}
                        {/* Adjusted alignSelf to "flex-start" */}
                        <Flex direction="column" maxW="500px">
                          <Flex
                            justifyContent="space-between"
                            width="100%"
                            alignItems="center"
                          >
                            <Text fontWeight="bold">Pay now</Text>
                            <Text>
                              {currency}{" "}
                              {formatCurrency(amountToSend * fxRateSupplier)}
                            </Text>
                          </Flex>
                          <Divider mb={2} />
                          <Text fontSize="sm">
                            Send local currency payment to the account provided
                            in the next step
                          </Text>
                        </Flex>
                      </Radio>
                    </Box>
                    {creditLimit > 0 && (
                      <Box
                        p={4}
                        border="1px"
                        borderRadius="md"
                        borderColor="gray.200"
                      >
                        <Radio value="flexPay" pl="10px" alignSelf="flex-start">
                          {" "}
                          {/* Adjusted alignSelf to "flex-start" */}
                          <Flex direction="column" maxW="500px">
                            <Text fontWeight="bold">Flex pay</Text>
                            <Text fontSize="sm">
                              Available credit limit: {currency}{" "}
                              {formatCurrency(creditLimit)}
                            </Text>
                            <Divider mb={2} />
                            <Text fontSize="sm">
                              We pay your supplier now, you pay us later
                            </Text>
                          </Flex>
                        </Radio>
                      </Box>
                    )}
                    {creditLimit === 0 && (
                      <Box
                        p={4}
                        border="1px"
                        borderRadius="md"
                        borderColor="gray.200"
                      >
                        <Radio
                          value="flexPay"
                          pl="10px"
                          isDisabled
                          _disabled={{ opacity: 1, cursor: "not-allowed" }}
                          alignSelf="flex-start"
                        >
                          {" "}
                          {/* Adjusted alignSelf to "flex-start" */}
                          <Flex direction="column" maxW="500px">
                            <Text fontWeight="bold">Flex pay</Text>
                            <Text fontSize="sm">
                              Available credit limit: {currency}{" "}
                              {formatCurrency(creditLimit)}
                            </Text>
                            <Divider mb={2} />
                            <Text fontSize="sm">
                              Not eligible for Flex pay. Keep transacting to
                              automatically qualify.
                            </Text>
                          </Flex>
                        </Radio>
                      </Box>
                    )}
                  </VStack>
                </RadioGroup>

                {showFlexPayForm && (
                  <VStack
                    spacing={4}
                    align="start"
                    w={["100%", "100%", "85%", "45%"]}
                  >
                    <FormControl>
                      <FormLabel>
                        Enter amount you want to pay on credit
                      </FormLabel>
                      <InputGroup>
                        <InputLeftAddon children={currency} />
                        <Input
                          type="number"
                          onChange={(e) =>
                            validateCreditAmountInput(
                              parseFloat(e.target.value)
                            )
                          }
                          max={200}
                          placeholder="0"
                          onBlur={(e) =>
                            setCreditAmount(
                              e.target.value ? parseFloat(e.target.value) : 0
                            )
                          }
                        />
                      </InputGroup>
                      {creditAmountError && (
                        <Text color="red.500" pt={"10px"} mb={4}>
                          {creditAmountError}
                        </Text>
                      )}
                    </FormControl>

                    <FormControl>
                      <FormLabel>Select duration</FormLabel>
                      <Select
                        value={duration}
                        onChange={(e) => setDuration(e.target.value)}
                      >
                        <option value="1month">1 month (3% fee)</option>
                        <option value="2months">2 months (6% fee)</option>
                        <option value="3months">3 months (9% fee)</option>
                      </Select>
                    </FormControl>

                    <Text mt={6} mb={4} fontWeight="bold">
                      Repayment terms
                    </Text>

                    <RepaymentTerms
                      currency={currency}
                      balanceToPayNow={repaymentTerms.balanceToPayNow}
                      paymentAmount={creditAmount}
                      totalRepay={repaymentTerms.totalRepay}
                      transactionFee={repaymentTerms.transactionFee}
                      repaymentSchedule={repaymentTerms.repaymentSchedule}
                    />

                    <Text w={["100%", "100%", "100%", "200%"]}>
                      Click below to accept the repayment terms and conditions.
                    </Text>
                    <Text w={["100%", "100%", "100%", "200%"]}>
                      By clicking below, you are concluding a legally binding
                      loan agreement with Symplifi Kenya Limited to repay the
                      loan based on the repayment terms and conditions above.
                    </Text>

                    <Checkbox
                      w={["100%", "100%", "100%", "200%"]}
                      mt={6}
                      isChecked={termsAccepted}
                      onChange={(e) => setTermsAccepted(e.target.checked)}
                    >
                      I accept the terms and conditions of repayment
                    </Checkbox>
                  </VStack>
                )}

                {formError && (
                  <Text color="red.500" mb={4}>
                    {formError}
                  </Text>
                )}
                <Button colorScheme="blue" onClick={openReviewModal}>
                  Review
                </Button>
              </VStack>
            </TabPanel>
            <TabPanel>
              <VStack spacing={4} align="start">
                <Text mt={6} mb={2} fontWeight="bold">
                  Payment details
                </Text>
                <FormControl>
                  <Text mb={2} fontSize="sm" color="gray.500">
                    Recipient country
                  </Text>
                  <Select
                    placeholder="Select recipient country"
                    value={recipientCountry}
                    w={["100%", "100%", "85%", "45%"]}
                    onChange={(e) => setRecipientCountry(e.target.value)}
                  >
                    {/* Add countries here */}
                    <option value="Kenya">Kenya</option>
                  </Select>
                </FormControl>

                <FormControl>
                  <Text mb={2} fontSize="sm" color="gray.500">
                    Enter amount
                  </Text>
                  <InputGroup w={["100%", "100%", "85%", "45%"]}>
                    <InputLeftAddon children="$" />
                    <Input
                      placeholder="0"
                      ref={amountInputRefOtherRecipient}
                      onChange={(e) =>
                        validateAmountInput(parseFloat(e.target.value))
                      }
                      onBlur={(e) =>
                        setAmountToSend(
                          e.target.value ? parseFloat(e.target.value) : 0
                        )
                      }
                      type="number"
                    />
                  </InputGroup>
                  {amountInputError && (
                    <Text color="red.500" pt={"10px"} mb={4}>
                      {amountInputError}
                    </Text>
                  )}
                </FormControl>

                <Box
                  p={4}
                  border="1px"
                  borderRadius="md"
                  borderColor="gray.200"
                  w={["100%", "100%", "80%", "60%"]}
                >
                  <Flex justifyContent="space-between" mb={2}>
                    <Text fontWeight="bold" color="gray.700">
                      FX rate
                    </Text>
                    <Text textAlign="right">
                      $1 = {currency}{" "}
                      {formatCurrencyWithTwoDecimals(fxRateOther)}
                    </Text>
                  </Flex>
                  <Flex justifyContent="space-between" mb={2}>
                    <Text fontWeight="bold" color="gray.700">
                      You pay
                    </Text>
                    <Text textAlign="right">
                      {currency} {formatCurrency(amountToSend * fxRateOther)}
                    </Text>
                  </Flex>
                  <Flex justifyContent="space-between">
                    <Text fontWeight="bold" color="gray.700">
                      Estimated delivery
                    </Text>
                    <Text textAlign="right">48 hours</Text>
                  </Flex>
                </Box>

                <Text fontWeight="bold" mb={2}>
                  Enter recipient bank details
                </Text>

                <FormControl>
                  <Text mb={2} fontSize="sm" color="gray.500">
                    Name
                  </Text>
                  <Input
                    value={recipientName}
                    w={["100%", "100%", "85%", "45%"]}
                    onChange={(e) => setRecipientName(e.target.value)}
                  />
                </FormControl>

                <FormControl>
                  <Text mb={2} fontSize="sm" color="gray.500">
                    Address
                  </Text>
                  <Input
                    value={recipientAddress}
                    w={["100%", "100%", "85%", "45%"]}
                    onChange={(e) => setRecipientAddress(e.target.value)}
                  />
                </FormControl>

                <FormControl>
                  <Text mb={2} fontSize="sm" color="gray.500">
                    USD Account number
                  </Text>
                  <Input
                    value={accountNumber}
                    w={["100%", "100%", "85%", "45%"]}
                    onChange={(e) => setAccountNumber(e.target.value)}
                  />
                </FormControl>

                <FormControl>
                  <Text mb={2} fontSize="sm" color="gray.500">
                    Bank swift code
                  </Text>
                  <Input
                    value={swiftCode}
                    w={["100%", "100%", "85%", "45%"]}
                    onChange={(e) => setSwiftCode(e.target.value)}
                  />
                </FormControl>

                {/*<Checkbox isChecked={saveDetails} onChange={(e) => setSaveDetails(e.target.checked)}>*/}
                {/*	Save*/}
                {/*</Checkbox>*/}

                <Text mt={4}>
                  Send local currency payment to the account provided in the
                  next step
                </Text>

                {formError && (
                  <Text color="red.500" mb={4}>
                    {formError}
                  </Text>
                )}
                <Button colorScheme="blue" onClick={openReviewModal}>
                  Review
                </Button>
              </VStack>
            </TabPanel>
          </TabPanels>
        </Tabs>

        {/* Modal */}
        <ConfirmationModal
          repaymentTerms={
            repaymentTerms.creditAmount === 0 ? null : repaymentTerms
          }
          isOpen={isOpen}
          sendAmount={amountToSend}
          currency={currency}
          totalAmount={
            repaymentTerms.creditAmount === 0
              ? amountToSend *
                (activeTab === "supplier" ? fxRateSupplier : fxRateOther)
              : repaymentTerms.balanceToPayNow
          }
          recipientCountry={recipientCountry}
          recipientName={recipientName}
          accountNumber={accountNumber}
          recipientAddress={recipientAddress}
          recipientSwiftCode={swiftCode}
          onClose={closeReviewModal}
          onConfirm={finalSubmit}
        />
        <ProofOfUserPaymentModal
          amount={
            repaymentTerms.creditAmount === 0
              ? amountToSend *
                (activeTab === "supplier" ? fxRateSupplier : fxRateOther)
              : repaymentTerms.balanceToPayNow
          }
          bankDetails={
            paymentMethod ===
            CreateDirectPaymentRequest.PaymentMethodEnum.BankTransfer
              ? flexPayBankDetails
              : mPesaDetails
          }
          currency={currency}
          isOpen={isSuccessModalOpen}
          onClose={() => {
            setIsSuccessModalOpen(false);
            navigate("/dashboard/myUsdPayments");
          }}
        />
      </Card>
    </Box>
  );
}
