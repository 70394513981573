import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from './files/en.json';
import rw from './files/rw.json';

const resources = {
    en: {
        translation: en
    },
    rw: {
        translation: rw
    }
};

// Function to get the language from localStorage or default to 'en'
const getInitialLanguage = () => {
    const savedLang = localStorage.getItem('i18nextLng');
    return savedLang || 'en';
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: getInitialLanguage(),    // use stored language or default to 'en'
        fallbackLng: "rw",

        interpolation: {
            escapeValue: false
        }
    });

// Function to change language and store in localStorage
i18n.on('languageChanged', (lng) => {
    localStorage.setItem('i18nextLng', lng);
});

export default i18n;