// Chakra imports
import { Portal, Box, useDisclosure } from "@chakra-ui/react";
import Footer from "components/footer/FooterDashboard";
// Layout components
import Navbar from "components/navbar/NavbarDashboard";
import Sidebar from "components/sidebar/Sidebar";
import { SidebarContext } from "contexts/SidebarContext";
import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import routes from "routes";
import { useAuth0 } from "@auth0/auth0-react";
import DefaultRedirect from "../../components/defaultRedirect/defaultRedirect";
import { getUser } from "../../services/user";
import { UserDto } from "../../generated-client/model/user-dto";
import RoleEnum = UserDto.RoleEnum;
import { getCompany } from "../../services/company";
import { CompanyDto } from "../../generated-client/model/company-dto";
import CountryEnum = CompanyDto.CountryEnum;

// Custom Chakra theme
export default function Dashboard(props: { [x: string]: any }) {
  const { ...rest } = props;
  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [companyCountry, setCompanyCountry] = useState<CountryEnum>(null);
  const [isUserNew, setIsUserNew] = useState<boolean>(true);
  // functions for changing the states from components

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  async function checkUserParams() {
    if (isAuthenticated) {
      const accessToken = await getAccessTokenSilently();
      const user = await getUser(accessToken);
      const company = await getCompany(accessToken);
      if (user.user.role === RoleEnum.Admin) {
        setIsUserAdmin(true);
      }
      setIsUserNew(!company.company.address);
      setCompanyCountry(company.company.country);
    }
  }
  checkUserParams();

  const getActiveRoute = (routes: RoutesType[]): string => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].name;
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes: RoutesType[]): boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getActiveNavbarText = (routes: RoutesType[]): string | boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].name;
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((route: RoutesType, key: any) => {
      if (route.layout === "/dashboard") {
        return (
          <Route
            path={route.path}
            element={React.createElement(route.component)}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = "ltr";
  const { onOpen } = useDisclosure();
  return (
    <Box>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Sidebar
          routes={isUserNew ? [] : routes.filter((r) => r.admin !== true)}
          display="none"
          {...rest}
        />
        <Box
          float="right"
          minHeight="100vh"
          height="100%"
          overflow="auto"
          position="relative"
          maxHeight="100%"
          w={{ base: "100%", xl: "calc( 100% - 290px )" }}
          maxWidth={{ base: "100%", xl: "calc( 100% - 290px )" }}
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Portal>
            <Box>
              <Navbar
                onOpen={onOpen}
                logoText={"Symplifi"}
                brandText={getActiveRoute(routes)}
                secondary={getActiveNavbar(routes)}
                message={getActiveNavbarText(routes)}
                fixed={fixed}
                companyCountry={companyCountry}
                userAdmin={isUserAdmin}
                {...rest}
              />
            </Box>
          </Portal>

          <Box
            mx="auto"
            p={{ base: "20px", md: "30px" }}
            pe="20px"
            minH="100vh"
            pt="50px"
          >
            <Routes>
              {getRoutes(routes)}
              <Route path="*" element={<DefaultRedirect />} />
            </Routes>
          </Box>
          <Box>
            <Footer />
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
