import React from 'react';
import ReactDOM, {createRoot} from "react-dom/client";
import './assets/css/App.css';
import {
	HashRouter,
	Route,
	Routes,
	Navigate,
	useNavigate,
	useLocation,
	useNavigationType,
	createRoutesFromChildren,
	matchRoutes
} from 'react-router-dom';
import AuthLayout from './layouts/auth';
import DashboardLayout from './layouts/dashboard';
import AdminLayout from './layouts/admin';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme';
import { Auth0Provider } from '@auth0/auth0-react';
import { useAuth0 } from "@auth0/auth0-react";
import './translations/i18n';
import 'flag-icon-css/css/flag-icons.css';
import * as Sentry from "@sentry/react";

function ProtectedRoute() {
	const { user, isAuthenticated, isLoading } = useAuth0();
	const navigate = useNavigate();

	if (isLoading) {
		return <div>Loading...</div>; // or return a loading spinner component
	}

	function redirectUser() {
		if (isAuthenticated) {
			Sentry.captureEvent({message: 'A User is authenticated' + isAuthenticated + ' ' + user?.email});
			navigate("/dashboard/default");
		} else {
			Sentry.captureEvent({message: 'A User is not authenticated' + isAuthenticated + ' ' + user?.email});
			navigate("/auth/sign-in");
		}
	}
	redirectUser();
}

Sentry.init({
	dsn: "https://cab33aa83d6f68615528c8e259c7328b@o926369.ingest.sentry.io/4505990608650240",
	integrations: [
		new Sentry.BrowserTracing({
			routingInstrumentation: Sentry.reactRouterV6Instrumentation(
				React.useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes
			),
		}),
		new Sentry.Replay()
	],
	tracesSampleRate: 1.0,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});


const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
	<ChakraProvider theme={theme}>
		<Auth0Provider
			domain="dev-o7g0cobmkuw6iefn.us.auth0.com"
			clientId="xKWjECT423ZAzBXwRh7H57rhSWlut1tt"
			authorizationParams={{
				redirect_uri: window.location.origin + "/dashboard/default",
				// scope: "openid profile email offline_access",
				audience: "https://symplifi.finance"
			}}
			cacheLocation="localstorage"
		>
			<React.StrictMode>
				<HashRouter>
					<Routes>
						<Route path="/auth/*" element={<AuthLayout />} />
						<Route path="/dashboard/*" element={<DashboardLayout />} />
						<Route path="/admin/*" element={<AdminLayout />} />
						<Route path="/" element={<ProtectedRoute />} />
					</Routes>
				</HashRouter>
			</React.StrictMode>
		</Auth0Provider>
	</ChakraProvider>
);
