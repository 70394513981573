import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  Text,
  Icon,
  Flex,
  FormControl,
  Divider,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Box,
  Tabs,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons"; // Import the CheckCircleIcon
import React, { useRef, useState } from "react";
import { formatCurrency } from "../../../../lib/utilities";
import { DirectPaymentDto } from "../../../../generated-client/model/direct-payment-dto";
import { CreateDirectPaymentRequest } from "../../../../generated-client/model/create-direct-payment-request";
import { PaymentRequestDto } from "../../../../generated-client/model/payment-request-dto";
import { SupplierDto } from "../../../../generated-client/model/supplier-dto";
import {useTranslation} from "react-i18next";

interface SuccessModalProps {
  isOpen: boolean;
  paymentRequest: PaymentRequestDto;
  supplier: SupplierDto;
  currency: string;
  onClose: () => void;
}

const PaymentDetailsModal: React.FC<SuccessModalProps> = ({
  onClose,
  paymentRequest,
  supplier,
  currency,
  isOpen,
}) => {
  const [selectedPaymentMethodIndex, setSelectedPaymentMethodIndex] =
    useState(0);
  const { t } = useTranslation();

  // Handler function to update the state when a new tab is selected
  const handlePaymentMethodChange = (index: number) => {
    setSelectedPaymentMethodIndex(index);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalBody>
          <VStack spacing={4} align="center">
            <Text textAlign="center">
              {"Supplier account details"}
              <br />
            </Text>
            <VStack align="start" spacing={4}>
              <Text>
                Amount to pay now:{" "}
                <b>
                  {currency}{" "}
                  {formatCurrency(
                    paymentRequest.amount - paymentRequest.amountCredit
                  )}
                </b>
              </Text>
              <Text>Supplier name: {paymentRequest.supplierName}</Text>
              <Tabs
                w="100%"
                onChange={handlePaymentMethodChange}
                index={selectedPaymentMethodIndex}
              >
                <TabList>
                  <Tab>Bank Transfer</Tab>
                  <Tab>Mobile Money</Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <Box
                      w="100%"
                      border="1px"
                      borderColor="gray.200"
                      borderRadius="md"
                      p={2}
                    >
                      {supplier &&
                      (<Text fontSize="sm">
                        Account name: {supplier?.name}
                        <br />
                        Account number: {supplier?.accountNumber}
                        <br />
                        Bank: {supplier?.bankName}
                        <br />
                      </Text>)}
                      {!supplier &&
                          (<>
                            <Text fontSize="sm" mt={4}>
                              {t("account_name")} SYMPLIFI RWANDA LTD
                              <br />
                              {t("account_number")} 100145553029
                              <br />
                              {t("bank_name")} Bank of Kigali
                              <br />
                            </Text>

                            <Text fontSize="sm" mt={4}>
                              {t("account_name")} SYMPLIFI RWANDA LTD
                              <br />
                              {t("account_number")} 20071655001
                              <br />
                              {t("bank_name")} I&M Bank (Rwanda) Plc
                              <br />
                            </Text>
                          </>)
                      }
                    </Box>
                  </TabPanel>
                  <TabPanel>
                    <Box
                      w="100%"
                      border="1px"
                      borderColor="gray.200"
                      borderRadius="md"
                      p={2}
                    >
                      <Text fontSize="sm">
                        {supplier?.mobilePayNumber ? (
                          <>
                            Account name: {supplier?.mobilePayAccountName}
                            <br />
                            Account number: {supplier?.mobilePayNumber}
                            <br />
                          </>
                        ) : (
                          "Not available"
                        )}
                      </Text>
                    </Box>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </VStack>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Flex width="100%" justifyContent="center">
            {" "}
            {/* Use Flex to center the button */}
            <Button colorScheme="blue" onClick={onClose}>
              Close
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PaymentDetailsModal;
