import { Flex, Input, Select, useBreakpointValue } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import Pagination from "../sharedComponents/pagination";
import { useFetchSourcingRequests } from "./hooks/FetchSourcing";

import CardLayout from "./components/CardLayout";
import { updateSourcingRequestStatusAdmin } from "services/sourcing";
import { SourcingRequestDto } from "generated-client/model/sourcing-request-dto";
import TableComponent from "./components/Table";
import OrderDetailModal from "./components/OrderDetailModal";
import { SourcingItemDto } from "generated-client/model/sourcing-item-dto";
import CreateProFormaInvoiceModal from "./components/CreateProFormaInvoiceModal";
import { SourcingRequestProFormaInvoiceDto } from "generated-client/model/sourcing-request-pro-forma-invoice-dto";
import ProformaInvoiceDetailModal from "./components/ProformaInvoiceDetailModal";

export default function SourcingRequests() {
  const { sourcingRequests, fetchData, accessToken } =
    useFetchSourcingRequests();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20;
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("All");
  const [isOrderDetailModalOpen, setIsOrderDetailModalOpen] = useState(false);
  const [isProformaModalOpen, setIsProformaModalOpen] = useState(false);
  const [isProformaDetailModalOpen, setIsProformaDetailModalOpen] =
    useState(false);
  const [sourcingRequesttems, setSourcingRequestItems] = useState<
    SourcingItemDto[]
  >([]);
  const [sourcingRequest, setSourcingRequest] = useState<SourcingRequestDto>();
  const [proformaInvoice, setProformaInvoice] =
    useState<SourcingRequestProFormaInvoiceDto>();

  const isTableLayout = useBreakpointValue({ base: false, lg: true });

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
      setCurrentPage(1);
    }, 2000);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm, selectedCountry]);

  const filteredSourcingRequests = sourcingRequests.filter((c) => {
    if (debouncedSearchTerm) {
      return (
        c?.company?.name
          ?.toLowerCase()
          .includes(debouncedSearchTerm.toLowerCase()) &&
        (selectedCountry === "All" || c?.company?.country === selectedCountry)
      );
    }
    return selectedCountry === "All" || c?.company?.country === selectedCountry;
  });

  async function handleStatusChange(
    sourcingRequestId: string,
    newStatus: string
  ) {
    try {
      await updateSourcingRequestStatusAdmin(accessToken, {
        id: sourcingRequestId,
        status: newStatus as SourcingRequestDto.StatusEnum,
      });

      setTimeout(() => {
        fetchData({
          skip: (currentPage - 1) * pageSize,
          take: pageSize,
          companyNameSearch: debouncedSearchTerm,
          country: selectedCountry === "All" ? null : (selectedCountry as any),
        });
      }, 500);
    } catch (error) {
      console.error("Error fetching sourcing requests:", error);
    }
  }

  const handleOpenOrderDetailModal = (
    state: boolean,
    items: SourcingItemDto[]
  ) => {
    setIsOrderDetailModalOpen(state);
    setSourcingRequestItems(items);
  };

  const handleOpenProformaInvoice = (data: SourcingRequestDto) => {
    setSourcingRequest(null);
    setTimeout(() => {
      setIsProformaModalOpen(true);
      setSourcingRequest(data);
    }, 500);
  };

  const handleOpenProformaInvoiceDetail = (
    data: SourcingRequestProFormaInvoiceDto
  ) => {
    setProformaInvoice(null);
    setTimeout(() => {
      setIsProformaDetailModalOpen(true);
      setProformaInvoice(data);
    }, 500);
  };

  useEffect(() => {
    fetchData({
      skip: (currentPage - 1) * pageSize,
      take: pageSize,
      companyNameSearch: debouncedSearchTerm,
      country: selectedCountry === "All" ? null : (selectedCountry as any),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, debouncedSearchTerm, selectedCountry]);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <Flex
        alignItems="center"
        gap="2"
        direction={{ base: "column", md: "row" }}
        pt={{ base: "50px", md: "30px", xl: "30px" }}
      >
        {" "}
        {/* Adjust the gap as needed */}
        <Input
          placeholder="Search by company name..."
          onChange={(e) => setSearchTerm(e.target.value)}
          w="300px"
          bg="white"
          borderColor="gray.300"
          _hover={{ borderColor: "gray.400" }}
          _focus={{ borderColor: "blue.500", boxShadow: "0 0 0 1px blue.500" }}
        />
        <Select
          onChange={(e) => setSelectedCountry(e.target.value)}
          value={selectedCountry}
          w="200px"
          bg="white"
          borderColor="gray.300"
          _hover={{ borderColor: "gray.400" }}
          _focus={{ borderColor: "blue.500", boxShadow: "0 0 0 1px blue.500" }}
        >
          <option value="All">All</option>
          <option value="Rwanda">Rwanda</option>
          <option value="Kenya">Kenya</option>
          {/* Add more options here */}
        </Select>
      </Flex>
      {isTableLayout ? (
        <TableComponent
          sourcingRequests={filteredSourcingRequests}
          handleStatusChange={handleStatusChange}
          handleOpenOrderDetailModal={handleOpenOrderDetailModal}
          handleOpenProformaInvoice={handleOpenProformaInvoice}
          handleOpenProformaInvoiceDetail={handleOpenProformaInvoiceDetail}
        />
      ) : (
        <CardLayout
          sourcingRequests={filteredSourcingRequests}
          handleStatusChange={handleStatusChange}
          handleOpenOrderDetailModal={handleOpenOrderDetailModal}
          handleOpenProformaInvoice={handleOpenProformaInvoice}
          handleOpenProformaInvoiceDetail={handleOpenProformaInvoiceDetail}
        />
      )}
      <Pagination
        currentPage={currentPage}
        pageSize={pageSize}
        elementsCount={filteredSourcingRequests.length}
        onPageChange={handlePageChange}
      />

      <OrderDetailModal
        isOpen={isOrderDetailModalOpen}
        onClose={() => setIsOrderDetailModalOpen(false)}
        items={sourcingRequesttems}
      />

      <CreateProFormaInvoiceModal
        isOpen={isProformaModalOpen}
        onClose={() => setIsProformaModalOpen(false)}
        sourcingRequest={sourcingRequest}
        accessToken={accessToken}
        fetchData={fetchData}
      />

      {isProformaDetailModalOpen && (
        <ProformaInvoiceDetailModal
          isOpen={isProformaDetailModalOpen}
          onClose={() => setIsProformaDetailModalOpen(false)}
          proformaInvoice={proformaInvoice}
        />
      )}
    </>
  );
}
