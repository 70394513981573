import { callDelete, callPost, callUpdate } from "../../lib/api-private";
import { GetAllProductsRequest } from "generated-client/model/get-all-products-request";
import { GetProductsResponse } from "generated-client/model/get-products-response";
import { UpdateProductRequest } from "generated-client/model/update-product-request";
import { CreateProductResponse } from "generated-client/model/create-product-response";
import { CreateProductRequest } from "generated-client/model/create-product-request";
import { GetAllProductsBySupplierRequest } from "generated-client/model/get-all-products-by-supplier-request";
import { AttachProductImageRequest } from "generated-client/model/attach-product-image-request";

export const getAllProductsAdmin = async (
  accessToken: string,
  data: GetAllProductsRequest
): Promise<GetProductsResponse> => {
  const response = await callPost(accessToken, "/product/all", data);
  return response;
};

export const getAllProductsBySupplierId = async (
  accessToken: string,
  data: GetAllProductsBySupplierRequest
): Promise<GetProductsResponse> => {
  const response = await callPost(accessToken, "/product/all/supplier", data);
  return response;
};

export const updateProduct = async (
  accessToken: string,
  data: UpdateProductRequest
): Promise<CreateProductResponse> => {
  return callUpdate(accessToken, "/product", data);
};

export const createProduct = async (
  accessToken: string,
  data: CreateProductRequest
): Promise<CreateProductResponse> => {
  return callPost(accessToken, "/product", data);
};

export const deleteProduct = async (
  accessToken: string,
  id: string
): Promise<void> => {
  return callDelete(accessToken, `/product/${id}`);
};

export const attachProductImage = async (
  accessToken: string,
  data: AttachProductImageRequest
): Promise<void> => {
  callUpdate(accessToken, "/product/attachProductImage", data);
};
