import {
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Input,
  InputLeftAddon,
  InputGroup,
  Flex,
} from "@chakra-ui/react";
import Card from "../../../../components/card/Card";
import { formatCurrency } from "../../../../lib/utilities";
import { useEffect, useRef, useState } from "react";
import { downloadFile } from "services/file";
import { UploadFileRequest } from "../../../../generated-client/model/upload-file-request";
import TypeEnum = UploadFileRequest.TypeEnum;

import { CompanyDtoWithFiles } from "../../../../generated-client/model/company-dto-with-files";
import { updateCompanyLatestPaymentDateAdmin } from "services/company";
import { CalendarIcon } from "@chakra-ui/icons";

interface TableComponentProps {
  companies: CompanyDtoWithFiles[];
  accessToken: string;
  handleStatusChange: (directPaymentId: string, newStatus: string) => void;
  handleCreditLimitUpdate: (companyId: string, creditLimit: number) => void;
  fetchData: any;
  errorMessages: { [requestId: string]: string };
  filterData?: {
    subscriptionStatus: string;
    country: string;
    skip: number;
    take: number;
    companyNameSearch: string;
  };
}

interface SubscriptionDates {
  [key: string]: string | null;
}

export default function TableComponent({
  companies,
  accessToken,
  handleStatusChange,
  handleCreditLimitUpdate,
  fetchData,
  filterData,
}: TableComponentProps) {
  const [subscriptionDates, setSubscriptionDates] = useState<SubscriptionDates>(
    {}
  );
  const [openInputIndex, setOpenInputIndex] = useState<number | null>(null);

  const getFileText = (fileType: TypeEnum, index: number) => {
    if (fileType === TypeEnum.ProcessedBankStatement) {
      return "Bank statement CSV";
    } else if (fileType === TypeEnum.ProofOfUserSubscriptionPayment) {
      return "Proof of subscription";
    } else {
      return index;
    }
  };

  const handleSubscriptionDateChange = async (
    companyId: string,
    newDate: string
  ) => {
    setSubscriptionDates((prevDates) => ({
      ...prevDates,
      [companyId]: newDate,
    }));

    try {
      await updateCompanyLatestPaymentDateAdmin(accessToken, {
        // @ts-ignore
        subscriptionDate: new Date(newDate),
        id: companyId,
      });

      fetchData(
        filterData.skip,
        filterData.take,
        filterData.companyNameSearch,
        filterData.country,
        filterData.subscriptionStatus
      );
    } catch (err) {
      console.error("Failed to update company payment date", err);
    }
  };

  useEffect(() => {
    if (companies?.length) {
      const newSubscriptionDates = companies?.reduce(
        (acc: SubscriptionDates, company) => {
          acc[company.id] = company?.subscriptionDate
            ? new Date(company?.subscriptionDate)?.toISOString().split("T")[0]
            : "";
          return acc;
        },
        {}
      );

      setSubscriptionDates(newSubscriptionDates);
    }
  }, [companies]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        w={{ base: "100%", md: "100%" }}
        mb={{ base: "0px", xl: "20px" }}
        minH="365px"
        pe="20px"
      >
        <Table variant="simple" size="md" fontSize="0.9em">
          <Thead>
            <Tr>
              <Th>Company details</Th>
              <Th>Credit limit</Th>
              <Th>KYC documents</Th>
              <Th>Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {companies?.map((company, index) => (
              <Tr key={company.id}>
                <Td minW="200px" maxW="400px">
                  <Text>Name: {company.name}</Text>
                  <Text>Address: {company.address}</Text>
                  <Text>Type: {company.type}</Text>
                  <Text>Registration number: {company.registrationNumber}</Text>
                  <Text>Country: {company.country}</Text>
                  <Text>Website url: {company.websiteUrl}</Text>
                  <Text>
                    User contact:{" "}
                    {company.users[0]?.email +
                      " " +
                      company.users[0]?.phoneNumber}
                  </Text>
                  {company?.pricingPlan && (
                    <>
                      <Text>Plan name: {company?.pricingPlan?.name}</Text>
                      {openInputIndex !== index && (
                        <Flex
                          gap={2}
                          align="center"
                          onClick={() => setOpenInputIndex(index)}
                        >
                          <Text>{subscriptionDates?.[company.id] ?? ""}</Text>
                          <CalendarIcon />
                        </Flex>
                      )}
                      <Input
                        value={subscriptionDates?.[company.id] ?? ""}
                        onChange={(e) =>
                          handleSubscriptionDateChange(
                            company.id,
                            e.target.value
                          )
                        }
                        type="date"
                        width="180px"
                        hidden={openInputIndex !== index}
                        onBlur={() => setOpenInputIndex(null)}
                      />
                    </>
                  )}
                </Td>
                <Td minW="250px">
                  <InputGroup>
                    <InputLeftAddon
                      children={company.country === "Kenya" ? "KES" : "RWF"}
                    />
                    <Input
                      maxW={"200px"}
                      minW={"150px"}
                      defaultValue={formatCurrency(
                        company.creditInformation.creditLimit
                      )}
                      onWheel={(e) => e.preventDefault()}
                      onBlur={(e) => {
                        const rawValue = e.target.value.replace(/[^0-9.]/g, "");
                        const floatValue = rawValue
                          ? parseFloat(rawValue)
                          : company.creditInformation.creditLimit;
                        const formattedValue = formatCurrency(floatValue);

                        if (
                          floatValue !== company.creditInformation.creditLimit
                        ) {
                          handleCreditLimitUpdate(company.id, floatValue);
                        }
                        // Update the input field to show the formatted number
                        e.target.value = formattedValue;
                      }}
                      type="string"
                    />
                  </InputGroup>
                </Td>
                <Td maxW="300px">
                  {company.files?.map((file, index) => (
                    <Button
                      fontSize="sm"
                      color="grey"
                      key={index}
                      onClick={() => downloadFile(file.key, accessToken)}
                    >
                      {getFileText(file.type, index)}
                    </Button>
                  ))}
                </Td>
                <Td>
                  <select
                    value={company.kycStatus}
                    onChange={(e) =>
                      handleStatusChange(company.id, e.target.value)
                    }
                  >
                    {Object.values(CompanyDtoWithFiles.KycStatusEnum).map(
                      (status) => (
                        <option key={status} value={status}>
                          {status.replace("_", " ")}
                        </option>
                      )
                    )}
                  </select>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Card>
    </Box>
  );
}
