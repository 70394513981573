import { Box, Button, Flex, Text, VStack } from "@chakra-ui/react";
import { SourcingItemDto } from "generated-client/model/sourcing-item-dto";
import { SourcingRequestDto } from "generated-client/model/sourcing-request-dto";
import { SourcingRequestProFormaInvoiceDto } from "generated-client/model/sourcing-request-pro-forma-invoice-dto";
import {useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {downloadProFormaInvoice} from "../../../../services/sourcing";

interface CardLayoutProps {
  sourcingRequests: SourcingRequestDto[];
  handleStatusChange: (sourcingRequestId: string, newStatus: string) => void;
  handleOpenOrderDetailModal: (
    value: boolean,
    items: SourcingItemDto[]
  ) => void;
  handleOpenProformaInvoice: (sourcingRequest: SourcingRequestDto) => void;
  handleOpenProformaInvoiceDetail: (
    proformaInvoice: SourcingRequestProFormaInvoiceDto
  ) => void;
}

export default function CardLayout({
  sourcingRequests,
  handleStatusChange,
  handleOpenOrderDetailModal,
  handleOpenProformaInvoice,
  handleOpenProformaInvoiceDetail,
}: CardLayoutProps) {
  const [loadingStates, setLoadingStates] = useState<{ [key: string]: boolean }>({});
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  const setRowLoading = (id: string, isLoading: boolean) => {
    setLoadingStates((prev) => ({ ...prev, [id]: isLoading }));
  };
  const handleProFormaInvoiceDownload = async (
      sourcingRequestId: string,
      proFormaInvoiceId: string
  ) => {
    setRowLoading(proFormaInvoiceId, true);
    const accessToken = await getAccessTokenSilently();
    await downloadProFormaInvoice(sourcingRequestId, proFormaInvoiceId, accessToken);
    setRowLoading(proFormaInvoiceId, false);
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {sourcingRequests?.map((sourcingRequest) => (
        <Box
          key={sourcingRequest.id}
          bg="white"
          p={5}
          shadow="md"
          borderWidth="1px"
          mb={4}
        >
          <Box mb={4} fontSize="0.9em">
            <Text>Company: {sourcingRequest?.company?.name}</Text>
            <Text>Country: {sourcingRequest?.company?.country}</Text>
            <Text>
              Request Date:{" "}
              {new Date(sourcingRequest.createdAt).toLocaleDateString(
                undefined,
                {
                  year: "numeric",
                  month: "long",
                }
              )}
            </Text>
            <Text my={1}>Status: </Text>
            <select
              value={sourcingRequest.status}
              onChange={(e) =>
                handleStatusChange(sourcingRequest.id, e.target.value)
              }
            >
              {Object.values(SourcingRequestDto.StatusEnum).map((status) => (
                <option key={status} value={status}>
                  {status}
                </option>
              ))}
            </select>

            <VStack align="start" mt={2}>
              <Button
                colorScheme="blue"
                size="xs"
                onClick={() =>
                  handleOpenOrderDetailModal(true, sourcingRequest.items)
                }
              >
                View order
              </Button>

              <Flex wrap={"wrap"} gap={2}>
                {sourcingRequest?.proFormaInvoices?.length
                  ? sourcingRequest?.proFormaInvoices.map((item, index) => {
                      return (
                        <Button
                          key={index}
                          colorScheme="blackAlpha"
                          size="sm"
                          isLoading={loadingStates[item.id]}
                          onClick={() => handleProFormaInvoiceDownload(sourcingRequest.id, item.id)}
                        >
                          Invoice {index + 1}
                        </Button>
                      );
                    })
                  : null}
                <Button
                  colorScheme="blue"
                  size="sm"
                  onClick={() => handleOpenProformaInvoice(sourcingRequest)}
                >
                  Create invoice
                </Button>
              </Flex>
            </VStack>
          </Box>
        </Box>
      ))}
    </Box>
  );
}
