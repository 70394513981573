import { PricingPlanDto } from "generated-client/model/pricing-plan-dto";
import { triggerMobilePay } from "services/mobile-pay";

export function formatCurrency(value: number) {
  if (!value) return "0";

  const formattedValue = parseFloat(value.toString()).toLocaleString("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  // Remove .00 if it exists
  return formattedValue.endsWith(".00")
    ? formattedValue.slice(0, -3)
    : formattedValue;
}

export function formatCurrencyWithTwoDecimals(value: number) {
  if (!value) return "0";

  const formattedValue = parseFloat(value.toString()).toLocaleString("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formattedValue;
}

export function roundNumber(n: number): number {
  return Math.round((n + Number.EPSILON) * 100) / 100;
}

export function findSubscriptionPlanById(
  plans: PricingPlanDto[],
  selectedPriceId: string
) {
  return plans.find((plan) => plan.id === selectedPriceId);
}

export const triggerMobilePayRequest = async ({
  amount,
  currency,
  phoneNumber,
  accessToken,
  supplierId,
}: {
  amount: number;
  currency: string;
  phoneNumber: string;
  accessToken: string;
  supplierId?: string;
}) => {
  return await triggerMobilePay(accessToken, {
    amount: Math.ceil(amount * 1.0025),
    currency: currency,
    phoneNumber: phoneNumber,
    supplierId,
  });
};

export const formatNumber = (n: number) => {
  return Math.round((n + Number.EPSILON) * 100) / 100;
};
