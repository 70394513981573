import { callGet, callPost, callUpdate } from "../../lib/api-private";
import { GetSuppliersResponse } from "../../generated-client/model/get-suppliers-response";
import { GetAllSuppliersRequest } from "generated-client/model/get-all-suppliers-request";
import { UpdateSupplierRequest } from "generated-client/model/update-supplier-request";
import { CreateSupplierRequest } from "generated-client/model/create-supplier-request";
import { GetSupplierResponse } from "generated-client/model/get-supplier-response";

export const getSuppliers = async (
  accessToken: string
): Promise<GetSuppliersResponse> => {
  const data = await callGet(accessToken, "/supplier");
  return data;
};

export const getAllSuppliersAdmin = async (
  accessToken: string,
  data: GetAllSuppliersRequest
): Promise<GetSuppliersResponse> => {
  const response = await callPost(accessToken, "/supplier/all", data);
  return response;
};

export const getSupplierDetailAdmin = async (
  accessToken: string,
  supplierId: string
): Promise<GetSupplierResponse> => {
  const response = await callGet(accessToken, `/supplier/${supplierId}`);
  return response;
};

export const updateSupplier = async (
  accessToken: string,
  data: UpdateSupplierRequest
): Promise<void> => {
  return callUpdate(accessToken, "/supplier", data);
};

export const createSupplier = async (
  accessToken: string,
  data: CreateSupplierRequest
): Promise<{ supplierId: string }> => {
  const result = callPost(accessToken, "/supplier", data);
  return result;
};
