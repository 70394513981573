import {
  Box,
  Stack,
  Text,
  Divider,
  List,
  ListIcon,
  ListItem,
  Button,
  Flex,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";

type Props = {
  id: string;
  name: string;
  price: number;
  features: any[];
  isLoading: boolean;
  onPlanSelect: (id: string, name: string) => void;
};

const PricingBox: React.FC<Props> = ({
  id,
  name,
  price,
  features,
  isLoading,
  onPlanSelect,
}) => {
  return (
    <Stack
      spacing={2}
      border="3px solid"
      // borderColor={isSelected ? "blue" : "gray.300"}
      borderColor={"gray.300"}
      borderRadius="0.7rem"
      p={4}
      backgroundColor={"white"}
      position="relative"
      cursor={"pointer"}
    >
      <Flex alignItems="center">
        <Text fontSize="xl" fontWeight="bold">
          {name}
        </Text>
        <Text fontSize="xl" fontWeight="bold" ml="auto">
          {`$${price > 0 ? price + ' / month' : price}`}
        </Text>
      </Flex>

      <Divider borderColor="blackAlpha.500" />
      <List flex="1" mb={4}>
        {features.map((feat: any) => (
          <ListItem key={Math.random()} mb={1}>
            <ListIcon as={CheckCircleIcon} color="gray.400" />
            {feat}
          </ListItem>
        ))}
      </List>
      <Box>
        <Button
          variant="solid"
          size="md"
          width="100%"
          display="flex"
          justifyContent="center"
          backgroundColor={"blue"}
          _hover={{
            backgroundColor: "blue.500",
          }}
          color="white"
          onClick={() => onPlanSelect(id, name)}
          isLoading={isLoading}
        >
          Select
        </Button>
      </Box>
    </Stack>
  );
};

export default PricingBox;
