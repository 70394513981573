/**
 * Symplifi   API
 * Symplifi backend API description 
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FileDto } from './file-dto';


export interface DirectPaymentDto { 
    id: string;
    country: string;
    amount: number;
    amountCurrency: DirectPaymentDto.AmountCurrencyEnum;
    exchangeRate: number;
    amountToPay: number;
    amountToPayCurrency: DirectPaymentDto.AmountToPayCurrencyEnum;
    recipientName: string;
    recipientAccountNumber: string;
    recipientAddress: string;
    recipientSwiftCode: string;
    recipientIban?: string;
    recipientPhoneNumber?: string;
    status: DirectPaymentDto.StatusEnum;
    type: DirectPaymentDto.TypeEnum;
    termsAccepted: boolean;
    companyId: string;
    companyName: string;
    userId: string;
    paymentRequestId?: string;
    paymentRequestDueDate?: string;
    paymentRequestAmountToPayNow?: number;
    paymentRequestAmountToPayNowCurrency?: DirectPaymentDto.PaymentRequestAmountToPayNowCurrencyEnum;
    paymentRequestCreditAmount?: number;
    paymentRequestCreditAmountCurrency?: DirectPaymentDto.PaymentRequestCreditAmountCurrencyEnum;
    paymentRequestAmountToRepay?: number;
    paymentMethod: DirectPaymentDto.PaymentMethodEnum;
    paymentRequestAmountToRepayCurrency?: DirectPaymentDto.PaymentRequestAmountToRepayCurrencyEnum;
    files?: Array<FileDto>;
    createdAt: string;
    updatedAt: string;
}
export namespace DirectPaymentDto {
    export type AmountCurrencyEnum = 'USD' | 'KES' | 'KES_SUPPLIER' | 'KES_OTHER' | 'RWF' | 'AED' | 'CNY';
    export const AmountCurrencyEnum = {
        Usd: 'USD' as AmountCurrencyEnum,
        Kes: 'KES' as AmountCurrencyEnum,
        KesSupplier: 'KES_SUPPLIER' as AmountCurrencyEnum,
        KesOther: 'KES_OTHER' as AmountCurrencyEnum,
        Rwf: 'RWF' as AmountCurrencyEnum,
        Aed: 'AED' as AmountCurrencyEnum,
        Cny: 'CNY' as AmountCurrencyEnum
    };
    export type AmountToPayCurrencyEnum = 'USD' | 'KES' | 'KES_SUPPLIER' | 'KES_OTHER' | 'RWF' | 'AED' | 'CNY';
    export const AmountToPayCurrencyEnum = {
        Usd: 'USD' as AmountToPayCurrencyEnum,
        Kes: 'KES' as AmountToPayCurrencyEnum,
        KesSupplier: 'KES_SUPPLIER' as AmountToPayCurrencyEnum,
        KesOther: 'KES_OTHER' as AmountToPayCurrencyEnum,
        Rwf: 'RWF' as AmountToPayCurrencyEnum,
        Aed: 'AED' as AmountToPayCurrencyEnum,
        Cny: 'CNY' as AmountToPayCurrencyEnum
    };
    export type StatusEnum = 'PENDING' | 'PROOF_OF_USER_PAYMENT_SUBMITTED' | 'PROOF_OF_PLATFORM_PAYMENT_SUBMITTED' | 'DECLINED' | 'COMPLETED';
    export const StatusEnum = {
        Pending: 'PENDING' as StatusEnum,
        ProofOfUserPaymentSubmitted: 'PROOF_OF_USER_PAYMENT_SUBMITTED' as StatusEnum,
        ProofOfPlatformPaymentSubmitted: 'PROOF_OF_PLATFORM_PAYMENT_SUBMITTED' as StatusEnum,
        Declined: 'DECLINED' as StatusEnum,
        Completed: 'COMPLETED' as StatusEnum
    };
    export type TypeEnum = 'PAY_NOW' | 'PAY_NOW_SUPPLIER' | 'PAY_NOW_OTHER' | 'FLEX_PAY';
    export const TypeEnum = {
        PayNow: 'PAY_NOW' as TypeEnum,
        PayNowSupplier: 'PAY_NOW_SUPPLIER' as TypeEnum,
        PayNowOther: 'PAY_NOW_OTHER' as TypeEnum,
        FlexPay: 'FLEX_PAY' as TypeEnum
    };
    export type PaymentRequestAmountToPayNowCurrencyEnum = 'USD' | 'KES' | 'KES_SUPPLIER' | 'KES_OTHER' | 'RWF' | 'AED' | 'CNY';
    export const PaymentRequestAmountToPayNowCurrencyEnum = {
        Usd: 'USD' as PaymentRequestAmountToPayNowCurrencyEnum,
        Kes: 'KES' as PaymentRequestAmountToPayNowCurrencyEnum,
        KesSupplier: 'KES_SUPPLIER' as PaymentRequestAmountToPayNowCurrencyEnum,
        KesOther: 'KES_OTHER' as PaymentRequestAmountToPayNowCurrencyEnum,
        Rwf: 'RWF' as PaymentRequestAmountToPayNowCurrencyEnum,
        Aed: 'AED' as PaymentRequestAmountToPayNowCurrencyEnum,
        Cny: 'CNY' as PaymentRequestAmountToPayNowCurrencyEnum
    };
    export type PaymentRequestCreditAmountCurrencyEnum = 'USD' | 'KES' | 'KES_SUPPLIER' | 'KES_OTHER' | 'RWF' | 'AED' | 'CNY';
    export const PaymentRequestCreditAmountCurrencyEnum = {
        Usd: 'USD' as PaymentRequestCreditAmountCurrencyEnum,
        Kes: 'KES' as PaymentRequestCreditAmountCurrencyEnum,
        KesSupplier: 'KES_SUPPLIER' as PaymentRequestCreditAmountCurrencyEnum,
        KesOther: 'KES_OTHER' as PaymentRequestCreditAmountCurrencyEnum,
        Rwf: 'RWF' as PaymentRequestCreditAmountCurrencyEnum,
        Aed: 'AED' as PaymentRequestCreditAmountCurrencyEnum,
        Cny: 'CNY' as PaymentRequestCreditAmountCurrencyEnum
    };
    export type PaymentMethodEnum = 'BANK_TRANSFER' | 'MPESA';
    export const PaymentMethodEnum = {
        BankTransfer: 'BANK_TRANSFER' as PaymentMethodEnum,
        Mpesa: 'MPESA' as PaymentMethodEnum
    };
    export type PaymentRequestAmountToRepayCurrencyEnum = 'USD' | 'KES' | 'KES_SUPPLIER' | 'KES_OTHER' | 'RWF' | 'AED' | 'CNY';
    export const PaymentRequestAmountToRepayCurrencyEnum = {
        Usd: 'USD' as PaymentRequestAmountToRepayCurrencyEnum,
        Kes: 'KES' as PaymentRequestAmountToRepayCurrencyEnum,
        KesSupplier: 'KES_SUPPLIER' as PaymentRequestAmountToRepayCurrencyEnum,
        KesOther: 'KES_OTHER' as PaymentRequestAmountToRepayCurrencyEnum,
        Rwf: 'RWF' as PaymentRequestAmountToRepayCurrencyEnum,
        Aed: 'AED' as PaymentRequestAmountToRepayCurrencyEnum,
        Cny: 'CNY' as PaymentRequestAmountToRepayCurrencyEnum
    };
}


