import {
  Box,
  Button,
  Heading,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

interface GetStartedProps {
  onContinue: () => void;
}

export const GetStartedDirectPayments: React.FC<any> = ({ onContinue }) => {
  const { t } = useTranslation();
  const textColor = useColorModeValue("navy.700", "white");

  return (
    <Box me="auto">
      <Heading color={textColor} fontSize="36px" mb="10px">
        {t("usd_payments")}
      </Heading>
      <Heading size="md" mb={3}>
        {t("how_it_works_direct")}
      </Heading>
      <Text fontSize="md" mb={3}>
        {t("how_it_works_direct_description")}
      </Text>
      <ul style={{ marginLeft: "20px", marginBottom: "12px" }}>
        <li>{t("step_1_direct")}</li>
        <li>{t("step_2_direct")}</li>
        <li>{t("step_3_direct")}</li>
      </ul>
      <Heading size="md" mb={3}>
        {t("flex_pay_description")}
      </Heading>
      <Text fontSize="md" mb={5}>
        {t("flex_pay_detail")}
      </Text>
      <Text fontSize="md" mb={5}>
        {t("complete_id_verification")}
      </Text>
      <Button colorScheme="blue" variant="solid" onClick={onContinue}>
        {t("get_started_direct")}
      </Button>
    </Box>
  );
};
