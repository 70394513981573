import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  Text,
  Icon,
  Flex,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons"; // Import the CheckCircleIcon
import React from "react";
import { useTranslation } from "react-i18next";

interface MobilePaySuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const MobilePaySuccessModal: React.FC<MobilePaySuccessModalProps> = ({
  onClose,
  isOpen,
}) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody mt={4}>
          <VStack spacing={4} align="center">
            <Icon as={CheckCircleIcon} boxSize={8} color="green.500" />
            <Text textAlign="center">
              Payment request sent to mobile number.
              <br />
              Your order will be processed once the payment is received.
            </Text>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Flex width="100%" justifyContent="center">
            {" "}
            <Button colorScheme="blue" onClick={onClose}>
              {t("close")}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MobilePaySuccessModal;
