import {
  Box,
  HStack,
  Icon,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import Card from "../../../../components/card/Card";
import { formatCurrency } from "../../../../lib/utilities";
import { PaymentRequestDtoWithOtherData } from "../../../../generated-client/model/payment-request-dto-with-other-data";
import { PaymentRequestDto } from "../../../../generated-client/model/payment-request-dto";
import StatusEnum = PaymentRequestDto.StatusEnum;
import { FaCalendarAlt } from "react-icons/fa";
import { FileDto } from "../../../../generated-client/model/file-dto";
import TypeEnum = FileDto.TypeEnum;

const payNowStatuses: StatusEnum[] = [
  "PAYMENT_SUBMITTED",
  "DECLINED",
  "PENDING",
];

interface TableComponentProps {
  paymentRequests: PaymentRequestDtoWithOtherData[];
  handleStatusChange: (
    request: PaymentRequestDtoWithOtherData,
    newStatus: string
  ) => void;
  handleInstalmentStatusChange: (
    paymentRequestId: string,
    id: string,
    newStatus: string
  ) => void;
  handleOpenModal: (request: PaymentRequestDtoWithOtherData) => void;
  handlePurchaseOrderDownload: (
    request: PaymentRequestDtoWithOtherData
  ) => void;
  errorMessages: { [requestId: string]: string };
  downloadFile: (id: string, requestId: string) => void;
  loadingStates: { [key: string]: boolean };
}

export default function TableComponent({
  paymentRequests,
  downloadFile,
  handleInstalmentStatusChange,
  loadingStates,
  handleStatusChange,
  handleOpenModal,
  handlePurchaseOrderDownload,
  errorMessages,
}: TableComponentProps) {
  return (
    <Box pt={{ base: "50px", md: "30px", xl: "30px" }}>
      <Card
        w={{ base: "100%", md: "100%" }}
        mb={{ base: "0px", xl: "20px" }}
        minH="365px"
        pe="20px"
      >
        <Table variant="simple" size="xl" fontSize="0.9em">
          <Thead>
            <Tr style={{ borderBottom: "2px solid #E2E8F0" }}>
              <Th>Company Name</Th>
              <Th>Recipient</Th>
              <Th>Order</Th>
              <Th minW={"550px"}>Payment schedule</Th>
            </Tr>
          </Thead>
          <Tbody>
            {paymentRequests?.map((request) => (
              <Tr
                key={request.id}
                style={{ borderBottom: "2px solid #E2E8F0" }}
              >
                <Td>{request.company?.name}</Td>
                <Td>{request.supplierName}</Td>
                <Td>
                  {request.items.map((item) => (
                    <Text key={item.name}>
                      {item.name}: {formatCurrency(item.quantity)} *{" "}
                      {request.amountCurrency} {formatCurrency(item.unitPrice)}{" "}
                      = {request.amountCurrency} {formatCurrency(item.total)}
                    </Text>
                  ))}
                  {request.directPaymentId && <Text>USD payment</Text>}
                  <Box
                    onClick={() => handleOpenModal(request)} // New box to open the modal with payment details
                    mr={2} // Margin to separate the buttons
                    cursor="pointer" // Change cursor to pointer on hover
                    display="flex" // Use flex to align the text and icon
                    alignItems="center" // Align items to the center vertically
                  >
                    <Text fontSize="sm" color="grey">
                      View Details
                    </Text>
                  </Box>
                  {request.items.length > 0 && (
                    <Box
                      onClick={() => handlePurchaseOrderDownload(request)} // New box to open the modal with payment details
                      mr={2} // Margin to separate the buttons
                      cursor="pointer" // Change cursor to pointer on hover
                      display="flex" // Use flex to align the text and icon
                      alignItems="center" // Align items to the center vertically
                    >
                      <Text fontSize="sm" color="grey">
                        {loadingStates[request.id]
                          ? "Downloading..."
                          : "Download PO"}
                      </Text>
                    </Box>
                  )}
                  {request.files?.find(
                    (f: any) => f.type === TypeEnum.ProofOfUserPayment
                  ) && (
                    <Box
                      onClick={() =>
                        downloadFile(
                          request.files?.find(
                            (f: any) => f.type === TypeEnum.ProofOfUserPayment
                          ).key,
                          request.id
                        )
                      } // New box to open the modal with payment details
                      mr={2} // Margin to separate the buttons
                      cursor="pointer" // Change cursor to pointer on hover
                      display="flex" // Use flex to align the text and icon
                      alignItems="center" // Align items to the center vertically
                    >
                      <Text fontSize="sm" color="grey">
                        {loadingStates[request.id]
                          ? "Downloading..."
                          : "Download proof"}
                      </Text>
                    </Box>
                  )}
                  {/*<Button onClick={() => handleOpenModal(request)}><Text fontSize="sm" color='grey'>View Details</Text></Button>*/}
                  {/*<Button isLoading={loadingStates[request.id]} onClick={() => handlePurchaseOrderDownload(request) }><Text fontSize="sm" color='grey'>Download PO</Text></Button>*/}
                </Td>
                <Td>
                  <VStack spacing="5px">
                    <Table size="sm">
                      <Tbody>
                        <Tr>
                          <Td minW={"200px"}>
                            <Text>
                              <b>
                                {request.amount === request.amountCredit
                                  ? "FLEX PAY"
                                  : request.amountCredit === 0
                                  ? "PAY NOW"
                                  : "PARTIAL FLEX"}{" "}
                                - {request.paymentMethod}
                              </b>
                            </Text>
                            <Text>
                              Amount:{" "}
                              {request.amountCurrency +
                                " " +
                                formatCurrency(request.amount)}
                            </Text>
                            {request.amount - request.amountCredit > 0 && (
                              <Text>
                                Pay now:{" "}
                                {request.amountCurrency +
                                  " " +
                                  formatCurrency(
                                    request.amount - request.amountCredit
                                  )}
                              </Text>
                            )}
                            {request.amountCredit > 0 && (
                              <>
                                <br />
                                <Text>
                                  Flex pay:{" "}
                                  {request.amountCreditCurrency +
                                    " " +
                                    formatCurrency(request.amountCredit)}
                                </Text>
                                <Text>
                                  To repay:{" "}
                                  {request.amountToRepayCurrency +
                                    " " +
                                    formatCurrency(request.amountToRepay)}
                                </Text>
                                <Text>
                                  Fee:{" "}
                                  {request.amountToRepayCurrency +
                                    " " +
                                    formatCurrency(
                                      request.amountToRepay -
                                        request.amountCredit
                                    )}
                                </Text>
                              </>
                            )}
                          </Td>
                          {request.type === "PAY_NOW" ? (
                            <Td minW={"200px"}>
                              Received: <br />{" "}
                              <Icon w="9px" h="14px" as={FaCalendarAlt} />{" "}
                              {new Date(request.createdAt).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                }
                              )}
                            </Td>
                          ) : (
                            <Td minW={"200px"}>
                              Received: <br />{" "}
                              <Icon w="9px" h="14px" as={FaCalendarAlt} />{" "}
                              {new Date(request.createdAt).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                }
                              )}
                              <br />
                              <br />
                              Due date: <br />{" "}
                              <Icon w="9px" h="14px" as={FaCalendarAlt} />{" "}
                              {new Date(request.dueDate).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                }
                              )}
                            </Td>
                          )}
                          <Td>
                            <Select
                              value={request.status || ""}
                              onChange={(e) =>
                                handleStatusChange(request, e.target.value)
                              }
                            >
                              {request.type === "PAY_NOW"
                                ? Object.values(StatusEnum)
                                    .filter((status) =>
                                      payNowStatuses.includes(status)
                                    )
                                    .map((status) => (
                                      <option key={status} value={status}>
                                        {status.replace("_", " ")}
                                      </option>
                                    ))
                                : Object.values(StatusEnum).map((status) => (
                                    <option key={status} value={status}>
                                      {status.replace("_", " ")}
                                    </option>
                                  ))}
                            </Select>
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>
                            {request.instalments?.length > 0 && (
                              <HStack
                                w="full"
                                borderBottom="1px solid"
                                borderColor="gray.200"
                              ></HStack>
                            )}
                          </Td>
                          <Td>
                            {request.instalments?.length > 0 && (
                              <HStack
                                w="full"
                                borderBottom="1px solid"
                                borderColor="gray.200"
                              ></HStack>
                            )}
                          </Td>
                          <Td>
                            {request.instalments?.length > 0 && (
                              <HStack
                                w="full"
                                borderBottom="1px solid"
                                borderColor="gray.200"
                              ></HStack>
                            )}
                          </Td>
                        </Tr>
                        {request.instalments.map((instalment, index) => (
                          <Tr key={index}>
                            <Td>
                              {instalment.amountCurrency}{" "}
                              {formatCurrency(instalment.amount)}
                            </Td>
                            <Td>
                              <Icon w="9px" h="14px" as={FaCalendarAlt} />{" "}
                              {new Date(instalment.dueDate).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                }
                              )}
                            </Td>
                            <Td>
                              <Select
                                value={instalment.status || ""}
                                onChange={(e) =>
                                  handleInstalmentStatusChange(
                                    request.id,
                                    instalment.id,
                                    e.target.value
                                  )
                                }
                              >
                                {Object.values(StatusEnum).map((status) => (
                                  <option key={status} value={status}>
                                    {status.replace("_", " ")}
                                  </option>
                                ))}
                              </Select>
                            </Td>
                          </Tr>
                        ))}
                        <div style={{ minHeight: "20px" }}>
                          {errorMessages[request.id] && (
                            <Text color="red.500">
                              {errorMessages[request.id]}
                            </Text>
                          )}
                        </div>
                      </Tbody>
                    </Table>
                  </VStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Card>
    </Box>
  );
}
