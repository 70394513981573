import { useEffect, useState } from "react";
import {
  Box,
  Center,
  Spinner,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { PaymentRequestDto } from "generated-client/model/payment-request-dto";
import { SupplierDto } from "generated-client/model/supplier-dto";
import { CompanyDto } from "generated-client/model/company-dto";
import TableComponent from "./Table";
import CardLayout from "./CardLayout";
import SuccessModal from "./SuccessModal";
import { useAuth0 } from "@auth0/auth0-react";
import {
  attachProofOfUserPayment,
  downloadPurchaseOrder,
  getPaymentRequests,
} from "services/payment-request";
import { getSuppliers } from "services/supplier";
import { getCompany } from "services/company";
import { downloadFile, uploadFile } from "services/file";
import CountryEnum = CompanyDto.CountryEnum;
import { useNavigate } from "react-router-dom";

export default function PaymentRequests({
  isHomeScreen = false,
}: {
  isHomeScreen?: boolean;
}) {
  const navigate = useNavigate();
  const isTableLayout = useBreakpointValue({ base: false, md: true });
  const [paymentRequests, setPaymentRequests] = useState<PaymentRequestDto[]>(
    []
  );
  const [suppliers, setSuppliers] = useState<SupplierDto[]>([]);
  const [loadingStates, setLoadingStates] = useState<{
    [key: string]: boolean;
  }>({});
  const [userCountry, setUserCountry] = useState<CountryEnum>();
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  async function fetchData() {
    setIsFetching(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const data = await getPaymentRequests(accessToken);
      const suppliers = await getSuppliers(accessToken);
      const company = await getCompany(accessToken);

      setPaymentRequests(data.paymentRequests);
      setSuppliers(suppliers.suppliers);
      setUserCountry(company.company.country);
    } catch (error) {
      console.error("Error fetching payment requests:", error);
    }
    setIsFetching(false);
  }

  const setRowLoading = (id: string, isLoading: boolean) => {
    setLoadingStates((prev) => ({ ...prev, [id]: isLoading }));
  };

  const handlePurchaseOrderDownload = async (request: PaymentRequestDto) => {
    setRowLoading(request.id, true);
    const accessToken = await getAccessTokenSilently();
    await downloadPurchaseOrder(request.id, accessToken);
    setRowLoading(request.id, false);
  };

  const downloadFileForRequest = async (id: string) => {
    const accessToken = await getAccessTokenSilently();
    downloadFile(id, accessToken);
  };

  async function sendFile(file: File, paymentRequestId: string) {
    const accessToken = await getAccessTokenSilently();
    try {
      const fileUploaded = await uploadFile(
        accessToken,
        {
          paymentRequestId: paymentRequestId,
          type: "PROOF_OF_USER_PAYMENT",
        },
        file
      );

      await attachProofOfUserPayment(accessToken, {
        id: paymentRequestId,
        fileId: fileUploaded.id,
      });

      setIsSuccessModalOpen(true);
    } catch (error) {
      console.error("Error fetching payment requests:", error);
    }
  }

  const handleReOrder = (orderData: any) => {
    const dataToSave = {
      amount: orderData.amount,
      amountCredit: orderData.amountCredit,
      amountCurrency: orderData.amountCurrency,
      amountInSecondaryCurrency: orderData.amountInSecondaryCurrency,
      amountInSecondaryCurrencyCurrency:
        orderData.amountInSecondaryCurrencyCurrency,
      type: orderData.type,
      paymentMethod: orderData.paymentMethod,
      durationInDays: orderData.durationInDays,
      termsAccepted: orderData.termsAccepted,
      supplierName: orderData.supplierName,
      supplierId: orderData.supplierId,
      items: orderData.items.map((item: any) => ({
        name: item.name,
        quantity: Number(item.quantity),
        unitPrice: Number(item.unitPrice),
        total: Number(item.total),
      })),
    };

    localStorage.setItem("reorderData", JSON.stringify(dataToSave));
    navigate("/dashboard/paymentRequest");
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (isAuthenticated) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated]);

  return (
    <>
      {isFetching ? (
        <Center h="50vh">
          <Spinner size="xl" colorScheme="blue" />
        </Center>
      ) : paymentRequests.length === 0 ? (
        <Box textAlign="center" mt="20px">
          <Text fontSize="lg">You have no recent orders</Text>
        </Box>
      ) : isTableLayout ? (
        <TableComponent
          paymentRequests={paymentRequests}
          loadingStates={loadingStates}
          suppliers={suppliers}
          sendFile={sendFile}
          downloadFile={downloadFileForRequest}
          setRowLoading={setRowLoading}
          handlePurchaseOrderDownload={handlePurchaseOrderDownload}
          country={userCountry}
          isHomeScreen={isHomeScreen}
          handleReOrder={handleReOrder}
        />
      ) : (
        <CardLayout
          sendFile={sendFile}
          suppliers={suppliers}
          downloadFile={downloadFileForRequest}
          setRowLoading={setRowLoading}
          paymentRequests={paymentRequests}
          loadingStates={loadingStates}
          handlePurchaseOrderDownload={handlePurchaseOrderDownload}
          country={userCountry}
          isHomeScreen={isHomeScreen}
          handleReOrder={handleReOrder}
        />
      )}
      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={() => {
          setIsSuccessModalOpen(false);
          window.location.reload();
        }}
      />
    </>
  );
}
