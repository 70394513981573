import {
	useBreakpointValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import TableComponent from "./components/Table";
import {UpdateCompanyKycStatusRequest} from "../../../generated-client/model/update-company-kyc-status-request";
import {ExchangeRateDto} from "../../../generated-client/model/exchange-rate-dto";
import {getExchangeRates, updateExchangeRateAdmin} from "../../../services/finance";

export default function Finance() {
	const [exchangeRates, setExchangeRates] = useState<ExchangeRateDto[]>([]);
	const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
	const isTableLayout = useBreakpointValue({ base: false, lg: true });
	const [accessToken, setAccessToken] = useState<string>('');
	const [errorMessages, setErrorMessages] = useState<{ [requestId: string]: string }>({});

	async function fetchData() {
		try {
			const accessToken = await getAccessTokenSilently();
			setAccessToken(accessToken);
			const data = await getExchangeRates(accessToken);

			setExchangeRates(data.exchangeRates);
		} catch (error) {
			console.error("Error fetching payment requests:", error);
		}
	}

	async function handleRateChange(rateId: string, newRate: number)  {
		try {
			await updateExchangeRateAdmin(accessToken, {
				id: rateId,
				value: newRate,
			});

			setTimeout(() => { fetchData(); }, 500);
		} catch (error) {
			console.error("Error fetching payment requests:", error);
		}
	}


	useEffect(() => {
		if (isLoading) {
			return;
		}

		if (isAuthenticated) {
			fetchData();
		}
	}, [isLoading, isAuthenticated]);

	return (
		<>
			<TableComponent
				rates={exchangeRates}
				handleRateChange={handleRateChange}
			/>
		</>
	);
}
